function getShipmentStatusCode(code) {
  switch (code) {
    case 'VD':
      return {
        label: 'Vessel department'
      }

    default:
      return undefined
  }
}

function getWeightQualifier(code) {
  switch (code) {
    case 'G':
      return {
        label: 'Gross weight'
      }

    default:
      return undefined
  }
}

function getEquipmentStatus(code) {
  switch (code) {
    case 'L':
      return {
        label: 'Loaded'
      }

    default:
      return undefined
  }
}

function getWeightUom(code) {
  switch (code) {
    case 'K':
      return {
        label: 'Kilograms'
      }

    default:
      return undefined
  }
}

function getVesselCodeQualifier(code) {
  switch (code) {
    case 'L':
      return {
        label: 'Lloyd\'s Register of Shipping'
      }

    default:
      return undefined
  }
}

function getVolumeQualifier(code) {
  switch (code) {
    case 'X':
      return {
        label: 'Cubic meters'
      }

    default:
      return undefined
  }
}

function getMessageStatus(code) {
  switch (code) {
    case 'A':
      return {
        label: 'New'
      }
    case 'C':
      return {
        label: 'Update'
      }
    case 'D':
      return {
        label: 'Delete'
      }

    default:
      return undefined
  }
}

export {
  getShipmentStatusCode,
  getWeightQualifier,
  getEquipmentStatus,
  getWeightUom,
  getVesselCodeQualifier,
  getVolumeQualifier,
  getMessageStatus
}
