function getStatusMetadata(status) {
    switch (status) {
        case 'DR':
            return { label: 'Draft', color: '#add8e6', contrastColor: '#000000' }
        case 'AC':
            return { label: 'Accepted', color: '#008000', contrastColor: '#ffffff' }
        case 'ST':
            return { label: 'Sent', color: '#d3d3d3', contrastColor: '#000000' }
        case 'RJ':
            return { label: 'Rejected', color: '#cd5c5c', contrastColor: '#ffffff' }
        case 'MR':
            return { label: 'Modification Rejected', color: '#cd5c5c', contrastColor: '#ffffff' }
        case 'CL':
            return { label: 'Cancel Accepted', color: '#008000', contrastColor: '#ffffff' }
        case 'CR':
            return { label: 'Cancel Rejected', color: '#cd5c5c', contrastColor: '#ffffff' }
        case 'SN':
            return { label: 'Status Notification', color: '#cdc84d', contrastColor: '#ffffff' }
        default:
            return { label: status, color: '#000000', contrastColor: '#ffffff' }
    }
}

export { getStatusMetadata }