import React from 'react'
import { constants, useTranslation } from 'cng-web-lib'
import { Box, Chip, Typography } from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import SummaryQuickGlanceTable from './SummaryQuickGlanceTable'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'

const { filter: { EQUAL } } = constants

function TablePage(props) {
    const { manifestId } = props
    const { translate } = useTranslation(Namespace.ACI_HIGHWAY_CARGO)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let ccn = translate(
            Namespace.ACI_HIGHWAY_CARGO,
            AciHighwayCargoKeys.CCN
        )

        let cargoType = translate(
            Namespace.ACI_HIGHWAY_CARGO,
            AciHighwayCargoKeys.CARGO_TYPE
        )

        let status = translate(
            Namespace.ACI_HIGHWAY_CARGO,
            AciHighwayCargoKeys.STATUS
        )

        let shipperName = translate(
            Namespace.ACI_HIGHWAY_CARGO,
            AciHighwayCargoKeys.SHIPPER_NAME
        )

        let consigneeName = translate(
            Namespace.ACI_HIGHWAY_CARGO,
            AciHighwayCargoKeys.CONSIGNEE_NAME
        )

        let customsBrokerName = translate(
            Namespace.ACI_HIGHWAY_CARGO,
            AciHighwayCargoKeys.CUSTOMS_BROKER_NAME
        )

        let entryNo = translate(
            Namespace.ACI_HIGHWAY_CARGO,
            AciHighwayCargoKeys.ENTRY_NO
        )

        return {
            ccn,
            cargoType,
            status,
            shipperName,
            consigneeName,
            customsBrokerName,
            entryNo
        }
    }

    const columns = [
        {
            field: 'ccn',
            title: `${translatedTextsObject.ccn} / ${translatedTextsObject.cargoType}`,
            render: (data) => {
                return (
                    <Box>
                        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
                            {data.ccn}
                        </Typography>
                        <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
                            {[
                                <Typography key='cargoType' component='span' variant='inherit'>
                                    {data.cargoType}
                                </Typography>,
                                data.acquittalNo && (
                                    <Typography key='acquittalNo' component='span' variant='inherit'>
                                        {data.acquittalNo}
                                    </Typography>
                                )
                            ].reduce((acc, curr, index) => {
                                return acc !== null
                                    ? curr
                                        ? [...acc, <Typography key={index} component='span' variant='inherit' style={{ margin: '0 4px' }} >&middot;</Typography>, curr]
                                        : acc
                                    : [curr]
                            }, null)}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: 'status',
            title: translatedTextsObject.status,
            render: (data) => {
                const status = getStatusMetadata(data.status)

                if (data.status == '1000' && data.cargoType == 'REGULAR' && !data.entryNo) {
                    status.color = "#ffc400"
                    status.contrastColor = "#000000"
                }

                return (
                    <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
                        <Chip
                            label={status.label}
                            size='small'
                            style={{ color: status.contrastColor, backgroundColor: status.color, fontSize: 12 }}
                        />
                        {data.portMismatch && (
                            <Chip key='first' label={"Port Mismatch"} size='small'
                                style={{ color: '#ffffff', backgroundColor: '#DF2901', fontSize: 12 }}
                            />
                        )}
                    </Box>
                )
            }
        },
        {
            field: 'entryNo',
            title: translatedTextsObject.entryNo
        },
        {
            field: 'shipperName',
            title: translatedTextsObject.shipperName
        },
        {
            field: 'consigneeName',
            title: translatedTextsObject.consigneeName
        },
        {
            field: 'customsBrokerName',
            title: translatedTextsObject.customsBrokerName
        }
    ]

    return (
        <SummaryQuickGlanceTable
            columns={columns}
            fetch={{ url: AciHighwayCargoApiUrls.GET_CARGO_QUICK_GLANCE }}
            fetchFilters={[
                { field: 'manifestId', operator: EQUAL, value: manifestId }
            ]}
            sortConfig={{
                type: 'column',
                defaultField: 'entryNo',
                defaultDirection: 'ASC'
            }}
        />
    )
}

export default TablePage
