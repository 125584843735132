import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { constants, components, useServices } from 'cng-web-lib'
import { useFormContext } from 'react-hook-form'
import { Box, Card, Grid } from '@material-ui/core'
import pathMap from '../../../paths/pathMap'
import EhblCloseMsgApiUrls from '../../../apiUrls/EhblCloseMsgApiUrls'
import FormProperties from './FormProperties'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import ErrorMessageDialog from 'src/views/common/ErrorMessageDialog'

const {
    button: { CngButton },
    form: { CngEditForm },
    table: { useFetchCodeMaintenanceLookup }
} = components

const { filter: { EQUAL } } = constants

function EditPage(props) {
    const { history, onSetLoading, showNotification } = props
    const { id } = useParams()
    const { securedSendRequest } = useServices()
    const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

    const [chargeTypes, setChargeTypes] = useState([])
    const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })
    const [errorMessageDialog, setErrorMessageDialog] = useState({ open: false, errorMessages: [] })

    const [isSuperParty, setSuperParty] = useState(false);
    useEffect(() => {
        Promise.all([
            // Charge type
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
                undefined,
                'code'
            ),
            // Super party
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER,
                undefined,
                [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
                undefined, 'code'
            )
        ]).then(
            ([billingChargeTypes, billingSuperParty]) => {
                compileChargeTypes(billingChargeTypes)

                Object.keys(billingSuperParty).forEach(e => {
                    if (e == GetLoggedInUserPartyName()) {
                        setSuperParty(true);
                    }
                })
            }
        )
    }, [])

    function compileChargeTypes(billingChargeTypes) {
        var jsonArray = [];
        if (billingChargeTypes) {
            Object.entries(billingChargeTypes).forEach((e) => {
                var jsonObject = { code: e[0], desc: e[1] }
                jsonArray.push(jsonObject)
            })
        }
        setChargeTypes(jsonArray)
    }

    function preSubmitCloseMessage(data) {
        if (isSuperParty) {
            setSubmitDialog({ open: true, items: data })
        } else {
            data.chargeAs = 'REGULAR'
            handleSubmitCloseMessage(data)
        }
    }

    function handleSubmitCloseMessage(data) {
        onSetLoading(true)

        const fileForUserDetails = FileForUserGetUserDetails()
        if (fileForUserDetails) {
            const { fileForUserId, fileForUserLoginId, fileForUserPartyId } = fileForUserDetails
            data.fileForUserId = fileForUserId
            data.fileForUserLoginId = fileForUserLoginId
            data.fileForUserPartyId = fileForUserPartyId

            data.closemsgRelccn.forEach(element => {
                element.fileForUserId = fileForUserId
                element.fileForUserLoginId = fileForUserLoginId
                element.fileForUserPartyId = fileForUserPartyId
            });
        }

        securedSendRequest.execute('POST', EhblCloseMsgApiUrls.SUBMIT_ALL, [data],
            (response) => {
                const data = response.data
                var errorMessages = []
                var errorFlag = false
                for (var key in data) {
                    var obj = data[key]
                    if (obj.errorMessages != null && obj.errorMessages.length > 0) {
                        errorMessages.push(obj.errorMessages)
                    errorFlag = true
                    }
                }
                if (errorFlag) {
                    setErrorMessageDialog({ open: true, errorMessages })
                } else {
                    showNotification('success', 'Close Message submitted successfully.')
                    history.push(pathMap.EHBL_CLOSE_MSG_LIST_VIEW)
                }
            },
            (error) => {
                console.error(error)
                showNotification('error', 'Close Message submit failed.')
            },
            () => onSetLoading(false)
        )
    }

    return (
        <>
            <Card>
                <Box padding={2}>
                    <CngEditForm
                        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                        showNotification={showNotification}
                        renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
                            <FormProperties.Fields
                                disabled={false}
                                showNotification={showNotification}
                                shouldHideMap={shouldHideMap}
                            />
                        )}
                        formikProps={FormProperties.formikProps}
                        toClientDataFormat={FormProperties.toClientDataFormat}
                        toServerDataFormat={FormProperties.toServerDataFormat}
                        fetch={{ url: EhblCloseMsgApiUrls.GET }}
                        update={{ url: EhblCloseMsgApiUrls.PUT }}
                        id={id}
                        renderButtonSection={(_, loading) => (
                            <ButtonSection
                                history={history}
                                loading={loading}
                                onSetLoading={onSetLoading}
                                onSubmitCloseMessage={preSubmitCloseMessage}
                            />
                        )}
                    />
                </Box>
            </Card>
            <ChargeTypeDialog
                chargeTypes={chargeTypes}
                onClose={() => setSubmitDialog({ open: false, items: [] })}
                onSubmit={handleSubmitCloseMessage}
                open={submitDialog.open}
                items={submitDialog.items}
            />
            <ErrorMessageDialog
                errorMessages={errorMessageDialog.errorMessages}
                open={errorMessageDialog.open}
                onClose={() => setErrorMessageDialog({ open: false })} />
        </>
    )
}

function ButtonSection(props) {
    const { history, loading, onSetLoading, onSubmitCloseMessage } = props
    const [confirmDialog, setConfirmDialog] = useState(false)
    const { getValues } = useFormContext()

    useEffect(() => {
        onSetLoading(loading)
    }, [loading])

    return (
        <>
            <Grid container justify='space-between' spacing={3}>
                <Grid item xs='auto'>
                    <CngButton color='secondary' disabled={loading} onClick={() => setConfirmDialog(true)} size='medium'>Discard</CngButton>
                </Grid>
                <Grid item xs='auto'>
                    <Grid container spacing={2}>
                        <Grid item xs='auto'>
                            <CngButton color='primary' disabled={loading} type='submit' size='medium'>Update</CngButton>
                        </Grid>
                        <Grid item xs='auto'>
                            <CngButton color='primary' disabled={loading} size='medium' onClick={() => onSubmitCloseMessage(getValues())}>Submit</CngButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AlertDialog
                cancelLabel='Continue editing'
                confirmLabel='Yes, discard'
                open={confirmDialog}
                onClose={() => setConfirmDialog(false)}
                onCancel={() => setConfirmDialog(false)}
                onConfirm={() => history.push(pathMap.EHBL_CLOSE_MSG_LIST_VIEW)}
                title='Discard'
            >
                All progress in this session will be lost and cant't be restored. Are
                you sure about this?
            </AlertDialog>
        </>
    )
}

export default withFormState(EditPage)