import React, { useEffect } from 'react'
import { components, Yup, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import FormProperties from '../InvoiceContainerFormProperties'
import makeValidationSchema from '../InvoiceContainerMakeValidationSchema'
import useCustomYupValidationResolver from '../../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'

const {
  button: { CngButton, CngIconButton },
  CngDialog
} = components

const { initialValues } = FormProperties.formikProps

function AddDialog(props) {
  const { onClose, onAdd, open } = props

  const { translate } = useTranslation(Namespace.INVOICE)
  const schema = Yup.object().shape({
    invoiceContainer: Yup.array().of(makeValidationSchema(translate))
  })
  const methods = useForm({
    defaultValues: { invoiceContainer: [initialValues] },
    resolver: useCustomYupValidationResolver(schema)
  })
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'invoiceContainer'
  })
  const { isSubmitting } = methods.formState

  useEffect(() => {
    if (!open) {
      methods.reset()
    }
  }, [open])

  function onSubmit(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        onAdd(data.invoiceContainer)
        resolve()
      }, 500)
    })
  }

  return (
    <CngDialog
      dialogContent={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5' style={{ fontWeight: 600 }}>
              Item information
            </Typography>
            <Typography color='textSecondary' variant='caption'>
              <Typography variant='inherit' color='error'>
                *
              </Typography>
              Mandatory fields
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <Grid container spacing={2}>
                {fields.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <Grid item xs={12}>
                      <Box marginBottom={1}>
                        <FormProperties.Fields key={index} index={index} />
                      </Box>
                      <Grid container justify='flex-end' spacing={2}>
                        <Grid item xs='auto'>
                          <CngIconButton
                            onClick={() => append(initialValues)}
                            icon={['fal', 'plus']}
                            size='small'
                            type='outlined'
                          />
                        </Grid>
                        <Grid item xs='auto'>
                          <CngIconButton
                            onClick={() =>
                              append(
                                methods.getValues(`invoiceContainer.${index}`)
                              )
                            }
                            icon={['fal', 'copy']}
                            size='small'
                            type='outlined'
                          />
                        </Grid>
                        {fields.length > 1 && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              onClick={() => remove(index)}
                              icon={['fal', 'trash']}
                              size='small'
                              type='outlined'
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {fields.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </FormProvider>
          </Grid>
        </Grid>
      }
      dialogTitle='Add item'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
      dialogAction={
        <>
          <CngButton
            color='secondary'
            disabled={isSubmitting}
            onClick={onClose}
          >
            Discard
          </CngButton>
          <CngButton
            color='primary'
            disabled={isSubmitting}
            onClick={methods.handleSubmit(onSubmit)}
            shouldShowProgress={isSubmitting}
          >
            Save
          </CngButton>
        </>
      }
      shouldShowCloseButton
    />
  )
}

export default AddDialog
