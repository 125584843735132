import { Card, CardContent, Grid, Paper } from '@material-ui/core'
import React,{Component,useState} from 'react'; 
//import CardHeader from "components/Card/CardHeader.js"; 
import RegularButton from "src/components/CustomButton.js";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import sweetalert from 'sweetalert'
import { components, useServices } from 'cng-web-lib';
import DocGenPurchaseOrderApiUrls from 'src/apiUrls/DocGenPurchaseOrderApiUrls.js'
import STATUSRESPONSE from 'src/apiUrls/StatusResponseApiUrls.js'
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'

var cardStyle = {
  //display: 'block',
  //width: '81vw',
  //transitionDuration: '0.3s',
 height: '41vw'
}
const{
  card: {
     CngSimpleCardHeader,
  },
   CngGridItem,
}=components



function UploadResponse() { 
  
  
  const {securedSendRequest } = useServices();

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, seFileData] = useState(null);
  const [dropdownvalue, setDropdownvalue] = useState('select');

  const handleChange = (event) => {
    console.log(event.target.value)
    setDropdownvalue(event.target.value);

  };

  // On file select (from the pop up) 
  const onFileChange = event => { 
    console.log(event.target.files[0])

    const file = event.target.files[0];
    // Update the state 
    setSelectedFile(file)
    
  }; 
   
  // On file upload (click the upload button) 
  const onFileUpload = () => { 

    let fileForUserDetails = FileForUserGetUserDetails();

      let file = selectedFile;
      if(file!=null){
        if(file.type != "text/xml"){
          sweetalert("File type must be in xml format","" ,"error");
          return false;
        }
        if(file.size > 6291456){
          sweetalert("File size exceed 6MB","" ,"error");
          return false;
        }
      }else{
        sweetalert("Invalid file","" ,"error");
        return false;
      }

      const formData = new FormData(); 

      if(fileForUserDetails!=null && fileForUserDetails!=undefined){
        formData.append("fileForUserDetails",JSON.stringify(fileForUserDetails))
      }else{
        formData.append("fileForUserDetails","{}")
      }     

      formData.append('file', selectedFile);
      console.log(fileForUserDetails)
     

    // Request made to the backend api 
    // Send formData object 
  
    var url='';
    if(dropdownvalue != null && dropdownvalue != 'select') 
    {
    if(dropdownvalue=='PO'){
      url=DocGenPurchaseOrderApiUrls.UPLOAD; //change to purchase order post
    }
    else if(dropdownvalue=='SM')
    {
      url=STATUSRESPONSE.POST;
    }

    function onSuccess(response) {
      sweetalert("File has been uploaded successfully","" ,"success");
    }
  
    function onError(error) {
      //showNotification("error", errorMsg)
      sweetalert("Error while uploading file","","error")
    }
  
    function onComplete(){
  
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }
  
    securedSendRequest.execute('POST',url, formData, onSuccess, onError, onComplete,config,null);
    }
    else{
      sweetalert("Please select message type!")
    } 
    
  }
   
  // File content to be displayed after 
  // file upload is complete 
  const displayFileData = () => { 

    if (selectedFile) { 
        
      return ( 
        <div> 
          <br />
          <h3>File Details:</h3> 
          <br />
          <p>File Name: {selectedFile.name}</p> 
          <br />
          <p>File Type: {selectedFile.type}</p> 
          <br />
          <p> 
            Last Modified:{" "} 
            {selectedFile.lastModifiedDate.toDateString()} 
          </p> 
        </div> 
      ); 
    } else { 
      return ( 
        <div> 
          <br /> 
          <br />
          <h4>Choose file to upload </h4> 
        </div> 

      ); 
    } 
  }; 
   
    return ( 
      <Card style={cardStyle}>
        <CngSimpleCardHeader title="Doc Gen Upload File"/>
        <CardContent>
        <Grid container spacing={3}>
        <Grid item xs={12}>
              <CngGridItem xs={12} sm={6}>
                 <InputLabel id="messagetype">Message Type</InputLabel>
                 <Select
                      labelId="message type"
                      id="rtype"
                      value={dropdownvalue}
                      onChange={handleChange}
                      style={{width:'50%'}}
                  >
                 <MenuItem value="PO">Purchase Order</MenuItem>
                 <MenuItem value="SM">Status Message</MenuItem>
                </Select>
             </CngGridItem>
             <div>
             <span></span>
             <br></br>
             </div>
             <div>
             <span></span>
             <br></br>
             </div>
             <Grid item xs={12}>
              <Grid container>
                <CngGridItem xs={12} sm={4}>
                <RegularButton>
                  <input type="file"accept=".xml" color="primary" onChange={onFileChange}/>
                </RegularButton>
                </CngGridItem>
                <CngGridItem xs={6} sm={3}>
                <RegularButton  type="submit" color="primary" onClick={onFileUpload}
                  disabled={!selectedFile} >
                  Upload!
                </RegularButton>
                </CngGridItem>
              </Grid> 
              </Grid>
                {displayFileData()} 
        </Grid>
        </Grid>  
        </CardContent>
        </Card>
    ); 
} 

export default UploadResponse; 