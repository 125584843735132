import DBAdminAciAirApiUrls from 'src/apiUrls/DBAdminAciAirApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React,{useState, useRef, useEffect} from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useLocation } from "react-router-dom";
import DBAdminButtonSection from 'src/views/common/DBAdminButtonSection'


const {
  form: { CngAddForm,CngAddFormButtonSection }
} = components

function AddPage({ history,showNotification }) {
  const location = useLocation();
  const pathname=location.pathname;

  let formikPropsData;
  if(location.state !== undefined && location.state!==null) {
  formikPropsData = {
  ...FormProperties.formikProps,
  initialValues: location.state 
  }
 } else {
  formikPropsData = FormProperties.formikProps
 }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm 
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          formikProps={formikPropsData}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
            /> 
          }
          //formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: DBAdminAciAirApiUrls.CHECKSTATUS,
            successRedirect: pathMap.DBADMIN_ACI_AIR,
            successMessage: 'Request Success',
            onPostSubmitSuccess: (data) => {
              history.push(`${pathname}`,data)
              window.location.reload();
            }
          }}
          renderButtonSection={() => {
            return (
              <CngAddFormButtonSection primaryButtonLabel="Check Status">
                  <DBAdminButtonSection buttonLabel="Update Status" url={DBAdminAciAirApiUrls.UPDATESTATUS} isAdd={true} showNotification={showNotification}>
                  </DBAdminButtonSection>
              </CngAddFormButtonSection>
            )
          }}
        />
      </Grid> 
    </Grid>
  )
}

export default AddPage
