import React, { useEffect, useState } from 'react'
import { constants, components, useTranslation } from 'cng-web-lib'
import moment from 'moment-timezone'
import Namespace from '../../../constants/locale/Namespace'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'
import EHBLResponseApiUrls from '../../../apiUrls/EhblResponseApiUrls'
import Table from 'src/components/aciacehighway/Table'
import CngSection from 'src/components/cngcomponents/CngSection'

const { table: { useFetchCodeMaintenanceLookup } } = components
const { filter: { EQUAL } } = constants

function ResponseList(props) {
    const { closeMsgId, showNotification } = props
    const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

    const { translate } = useTranslation(Namespace.EHBL_CLOSE_MSG)
    const translatedTextsObject = makeTranslatedTextsObject()
    function makeTranslatedTextsObject() {
        let title = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.RESPONSE_LIST
        )

        let previousCcn = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.PREVIOUS_CCN
        )

        let primaryCcn = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.PRIMARY_CCN
        )

        let batchId = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.BATCH_ID
        )

        let status = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.STATUS
        )

        let receivedDatetime = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.RECEIVED_DATETIME
        )

        let processDatetime = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.PROCESS_DATETIME
        )

        return {
            title,
            previousCcn,
            primaryCcn,
            batchId,
            status,
            receivedDatetime,
            processDatetime
        }
    }

    const [lookups, setLookups] = useState(null)
    useEffect(() => {
        Promise.all([
            fetchCodeMaintenanceLookup(
                constants.CodeMaintenanceType.CODE_MASTER, undefined,
                [{
                    field: 'codeType',
                    operator: EQUAL,
                    value: 'EHBL_CBSA_PROCESSING_IND'
                }],
                undefined, 'code')
        ]).then(([status]) => {
            setLookups({ status })
        })
    }, [])


    const columns = [
        { field: 'cbsaRefNo', title: translatedTextsObject.previousCcn },
        { field: 'primaryCcn', title: translatedTextsObject.primaryCcn },
        { field: 'secondaryBusinessId', title: translatedTextsObject.batchId },
        {
            field: 'reportStatus',
            title: translatedTextsObject.status,
            render: (data) => (lookups ? lookups['status'][data.reportStatus] : data.reportStatus)
        },
        {
            field: 'receiveDatetime',
            title: translatedTextsObject.receivedDatetime,
            render: (data) => data.receiveDatetime &&
                moment(data.receiveDatetime).tz("Canada/Eastern").format('D MMM YYYY - HH:mm')
        },
        {
            field: 'dateTime',
            title: translatedTextsObject.processDatetime,
            render: (data) => data.dateTime &&
                moment(data.dateTime).tz("Canada/Eastern").format('D MMM YYYY - HH:mm')
        }
    ]

    return (
        <CngSection title={translatedTextsObject.batchId.title}>
            <Table
                columns={columns}
                compact
                fetch={{ url: EHBLResponseApiUrls.GET }}
                fetchFilters={[{ field: 'closemsgId', operator: EQUAL, value: closeMsgId }]}
                showNotification={showNotification}
                variant='outlined'
                sortConfig={{
                    type: 'column',
                    defaultField: 'receiveDatetime',
                    defaultDirection: 'DESC'
                }}
            />
        </CngSection>
    )
}

export default ResponseList