import makeValidationSchema from './SublineAllowanceMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      FormikDropdownField: CngDropdownField,
      CngTextField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _sublineId: 0,
  sublineItemId: 0,
  sublineCorpid: "",
  chargeCode: "",
  chargePercent: "",
  chargeCost: "",
  chargeUom: "",
  chargeQuantity: "",
  chargeMethodDesc: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let sublineAllowance = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.TITLE
    )
    let _sublineId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.SUBLINE
    )
    let sublineItemId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.SUBLINE_ITEM_ID
    )
    let sublineCorpid = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.SUBLINE_CORPID
    )
    let chargeCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_CODE
    )
    let chargePercent = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_PERCENT
    )
    let chargeCost = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_COST
    )
    let chargeUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_UOM
    )
    let chargeQuantity = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_QUANTITY
    )
    let chargeMethodDesc = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_METHOD_DESC
    )

    return {
      sublineAllowance,
      _sublineId,
      sublineItemId,
      sublineCorpid,
      chargeCode,
      chargePercent,
      chargeCost,
      chargeUom,
      chargeQuantity,
      chargeMethodDesc
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.sublineAllowance} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap._sublineId}>
            <CngSelectField
              name="_sublineId"
              label={translatedTextsObject._sublineId}
              items={parentIdDropdownItems}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="sublineItemId"
              type="number"
              label={translatedTextsObject.sublineItemId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="sublineCorpid"
              label={translatedTextsObject.sublineCorpid}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.chargeCode}>
            <CngTextField
              name="chargeCode"
              label={translatedTextsObject.chargeCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.chargePercent}>
            <CngTextField
              name="chargePercent"
              label={translatedTextsObject.chargePercent}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.chargeCost}>
            <CngTextField
              name="chargeCost"
              label={translatedTextsObject.chargeCost}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.chargeUom}>
            <CngTextField
              name="chargeUom"
              label={translatedTextsObject.chargeUom}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.chargeQuantity}>
            <CngTextField
              name="chargeQuantity"
              label={translatedTextsObject.chargeQuantity}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.chargeMethodDesc}>
            <CngTextField
              name="chargeMethodDesc"
              label={translatedTextsObject.chargeMethodDesc}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const SublineAllowanceFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default SublineAllowanceFormProperties
