import React from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import LocalTable from '../../../../components/aciacehighway/LocalTable'
import { Box, Typography } from '@material-ui/core'

function TradePartyTable(props) {
  const { data, getLookupValue } = props

  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let identificationCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.IDENTIFICATION_CODE
    )
    let identifier = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.IDENTIFIER
    )
    let partyType = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.PARTY_TYPE
    )
    let partyName = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.PARTY_NAME
    )
    let address = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.ADDRESS
    )
    let country = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.COUNTRY
    )

    return {
      identificationCode,
      identifier,
      partyType,
      partyName,
      address,
      country
    }
  }

  const columns = [
    {
      title: [
        translatedTextsObject.identificationCode,
        translatedTextsObject.identifier
      ].join(' / '),
      field: 'identifier',
      filterConfig: { type: 'textfield' },
      render: (rowData) => (
        <Box>
          <Typography component='div' variant='inherit'>
            {getLookupValue('partyIdCode', rowData.identificationCode)}
          </Typography>
          <Typography color='textSecondary' variant='caption'>
            {rowData.identifier}
          </Typography>
        </Box>
      )
    },
    {
      title: translatedTextsObject.partyType,
      field: 'partyType',
      filterConfig: {
        type: 'select',
        options: [
          { label: 'Bill To Party', value: 'BT' },
          { label: 'Broker or Sales Office', value: 'BO' },
          { label: 'Ship From', value: 'SF' },
          { label: 'Additional Delivery Address', value: 'AE' },
          { label: 'Manufacturer of Goods', value: 'MF' },
          { label: 'PA Party', value: 'PA' }
        ]
      },
      render: (rowData) => getLookupValue('partyType', rowData.partyType)
    },
    {
      title: translatedTextsObject.partyName,
      field: 'partyName',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.address,
      field: 'address',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.country,
      field: 'country',
      filterConfig: { type: 'textfield' },
      render: (rowData) => getLookupValue('country', rowData.country)
    }
  ]

  return <LocalTable columns={columns} data={data} />
}

export default TradePartyTable
