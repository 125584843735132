import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'

const {
    locale: { key: { CommonValidationMessageKeys, } }
} = constants

function RelatedCcnMakeValidationSchema(translate) {
    let requiredMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.REQUIRED
    )

    const errMsgAlphaNumericWithSpecialChar = translate(
        Namespace.EHBL_CLOSE_MSG,
        EhblCloseMsgKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR
    )

    const errMsgMaxLength = translate(
        Namespace.EHBL_CLOSE_MSG,
        EhblCloseMsgKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
    )
    const regexAlphaNumericWithSpecialChar = '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

    return Yup.object({
        relatedCcn: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar,errMsgAlphaNumericWithSpecialChar).max(25, errMsgMaxLength + " 25"),
    })
}

export default RelatedCcnMakeValidationSchema