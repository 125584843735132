import React, {useEffect} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import InvoiceKeys from 'src/constants/locale/key/Invoice'
import { Grid, Typography } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import InvoiceContainerFormProperties from './InvoiceContainerFormProperties'
import { NaDocPortAutocompleteField } from 'src/components/na'
import { useFormContext,useWatch } from "react-hook-form";
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'
import CngSection from 'src/components/cngcomponents/CngSection'
import InvoiceContainerTable from './invoiceContainerTable'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  corpid: "",
  invoiceNumber: "",
  invoiceDate: "",
  custRefNum: "",
  noOfPkgs: "",
  carrierCode: "",
  noOfContainers: "",
  cube: "",
  eta: "",
  portOfLoading: "",
  billOfLading: "",
  status: "DR",
  lockedBy: "",
  lockedDate: "",
  responseDatetime: "",
  rcvdPo: "",
  createdBy: "",
  createdDate: "",
  submittedDate: "",
  invoiceContainer: [],
  transaction: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, watch } = useFormContext();

  //const [, , { setValue: setCarrierCodeValue }] = useField('carrierCode')
  //const [, , { setValue: setCustRefNumValue }] = useField('custRefNum')
  //const [, , { setValue: setInvoiceNumberValue }] = useField('invoiceNumber')
  //const [, , { setValue: setPortOfLoadingValue }] = useField('portOfLoading')
  //const [, , { setValue: setBillOfLadingValue }] = useField('billOfLading')
  //const [, , { setValue: setReceivedPoNumberValue }] = useField('rcvdPo')

  const invoiceContainer = watch('invoiceContainer');

  useWatch("fileForUserId");
  useWatch("fileForUserPartyId");
  useWatch("fileForUserLoginId");
  useEffect(() => { 
    let fileForUserDetails = FileForUserGetUserDetails();
      if(fileForUserDetails!=null && fileForUserDetails!=undefined){
        setValue("fileForUserId",fileForUserDetails.fileForUserId)
        setValue("fileForUserPartyId",fileForUserDetails.fileForUserPartyId)
        setValue("fileForUserLoginId",fileForUserDetails.fileForUserLoginId)
      }
   }, []);

  function makeTranslatedTextsObject() {
    let invoice = translate(
      Namespace.INVOICE,
      InvoiceKeys.TITLE
    )
    let id = translate(
      Namespace.INVOICE,
      InvoiceKeys.ID
    )
    let corpid = translate(
      Namespace.INVOICE,
      InvoiceKeys.CORPID
    )
    let invoiceNumber = translate(
      Namespace.INVOICE,
      InvoiceKeys.INVOICE_NUMBER
    )
    let invoiceDate = translate(
      Namespace.INVOICE,
      InvoiceKeys.INVOICE_DATE
    )
    let custRefNum = translate(
      Namespace.INVOICE,
      InvoiceKeys.CUST_REF_NUM
    )
    let noOfPkgs = translate(
      Namespace.INVOICE,
      InvoiceKeys.NO_OF_PKGS
    )
    let carrierCode = translate(
      Namespace.INVOICE,
      InvoiceKeys.CARRIER_CODE
    )
    let noOfContainers = translate(
      Namespace.INVOICE,
      InvoiceKeys.NO_OF_CONTAINERS
    )
    let cube = translate(
      Namespace.INVOICE,
      InvoiceKeys.CUBE
    )
    let eta = translate(
      Namespace.INVOICE,
      InvoiceKeys.ETA
    )
    let portOfLoading = translate(
      Namespace.INVOICE,
      InvoiceKeys.PORT_OF_LOADING
    )
    let billOfLading = translate(
      Namespace.INVOICE,
      InvoiceKeys.BILL_OF_LADING
    )
    let status = translate(
      Namespace.INVOICE,
      InvoiceKeys.STATUS
    )
    let lockedBy = translate(
      Namespace.INVOICE,
      InvoiceKeys.LOCKED_BY
    )
    let lockedDate = translate(
      Namespace.INVOICE,
      InvoiceKeys.LOCKED_DATE
    )
    let responseDatetime = translate(
      Namespace.INVOICE,
      InvoiceKeys.RESPONSE_DATETIME
    )
    let rcvdPo = translate(
      Namespace.INVOICE,
      InvoiceKeys.RCVD_PO
    )
    let createdBy = translate(
      Namespace.INVOICE,
      InvoiceKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.INVOICE,
      InvoiceKeys.CREATED_DATE
    )
    let submittedDate = translate(
      Namespace.INVOICE,
      InvoiceKeys.SUBMITTED_DATE
    )
    let invoiceContainer = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.TITLE
    )
    let invId = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.INV_ID
    )
    let poNumber = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.PO_NUMBER
    )
    let wallMartItem = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.WALL_MART_ITEM
    )
    let containerNo = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.CONTAINER_NO
    )
    let sizeAndType = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.SIZE_AND_TYPE
    )
    let quantity = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.QUANTITY
    )
    let uom = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.UOM
    )
    let volume = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.VOLUME
    )
    let unitPrice = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.UNIT_PRICE
    )
    let entValue = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.ENT_VALUE
    )
    let transaction = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.TITLE
    )
    let userid = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.USERID
    )
    let transTime = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.TRANS_TIME
    )
    let transType = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.TRANS_TYPE
    )
    let additionalinfo = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.ADDITIONALINFO
    )
    let additionalinfo1 = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.ADDITIONALINFO1
    )
    let subEdiPath = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.SUB_EDI_PATH
    )

    return {
      invoice,
      id,
      corpid,
      invoiceNumber,
      invoiceDate,
      custRefNum,
      noOfPkgs,
      carrierCode,
      noOfContainers,
      cube,
      eta,
      portOfLoading,
      billOfLading,
      status,
      lockedBy,
      lockedDate,
      responseDatetime,
      rcvdPo,
      createdBy,
      createdDate,
      submittedDate,
      invoiceContainer,
      invId,
      poNumber,
      wallMartItem,
      containerNo,
      sizeAndType,
      quantity,
      uom,
      volume,
      unitPrice,
      entValue,
      transaction,
      userid,
      transTime,
      transType,
      additionalinfo,
      additionalinfo1,
      subEdiPath
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection
          title={translatedTextsObject.invoice}
          subheader={
            <Typography color='textSecondary' variant='caption'>
              <Typography variant='inherit' color='error'>*</Typography>
              Mandatory fields
            </Typography>
          }
        >
          <Grid container spacing={1}>
            <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.custRefNum}>
              <CngTextField
                required
                name="custRefNum"
                label={translatedTextsObject.custRefNum}
                disabled={disabled}
                onChange={(e) => {
                  //setCustRefNumValue(e.target.value.toUpperCase())
                  setValue("custRefNum",e.target.value.toUpperCase(),{ shouldValidate: true }) 
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.invoiceNumber}>
              <CngTextField
                required
                name="invoiceNumber"
                label={translatedTextsObject.invoiceNumber}
                disabled={disabled}
                onChange={(e) => {
                  //setInvoiceNumberValue(e.target.value.toUpperCase())
                  setValue("invoiceNumber",e.target.value.toUpperCase(),{ shouldValidate: true }) 
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.invoiceDate}>
              <CngDateField
                name="invoiceDate"
                label={translatedTextsObject.invoiceDate}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.billOfLading}>
              <CngTextField
                required
                name="billOfLading"
                label={translatedTextsObject.billOfLading}
                disabled={disabled}
                onChange={(e) => {
                  //setBillOfLadingValue(e.target.value.toUpperCase())
                  setValue("billOfLading",e.target.value.toUpperCase(),{ shouldValidate: true }) 
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.portOfLoading}>
              <NaDocPortAutocompleteField
                name="portOfLoading"
                label={translatedTextsObject.portOfLoading}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.carrierCode}>
              <CngTextField
                required
                name="carrierCode"
                label={translatedTextsObject.carrierCode}
                disabled={disabled}
                onChange={(e) => {
                  setValue("carrierCode",e.target.value.toUpperCase(),{ shouldValidate: true }) 
                  //setCarrierCodeValue(e.target.value.toUpperCase())
                }}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.eta}>
              <CngDateField
                name="eta"
                label={translatedTextsObject.eta}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} md={6} lg={3} shouldHide={shouldHideMap.rcvdPo}>
              <CngTextField
                name="rcvdPo"
                label={translatedTextsObject.rcvdPo}
                disabled={disabled}
                onChange={(e) => {
                  //setReceivedPoNumberValue(e.target.value.toUpperCase())
                  setValue("rcvdPo",e.target.value.toUpperCase(),{ shouldValidate: true }) 
                }}
                size='small'
              />
            </CngGridItem>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <InvoiceContainerTable
          invoiceContainer={invoiceContainer}
          translatedTextsObject={translatedTextsObject}
        />
      </Grid>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.invoiceDate = DateTimeFormatter.toClientDate(
    localData.invoiceDate
  );
  localData.eta = DateTimeFormatter.toClientDate(
    localData.eta
  );
  localData.lockedDate = DateTimeFormatter.toClientDate(
    localData.lockedDate
  );
  localData.responseDatetime = DateTimeFormatter.toClientDate(
    localData.responseDatetime
  );
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.submittedDate = DateTimeFormatter.toClientDate(
    localData.submittedDate
  );
  localData.transaction.forEach((datum) => {
    datum.transTime = DateTimeFormatter.toClientDate(
      datum.transTime
    );
  });
  return localData;
}

function toServerDataFormat(localData) {
  console.log('localData',localData)
  localData.invoiceDate = DateTimeFormatter.toServerDate(
    localData.invoiceDate
  );
  localData.eta = DateTimeFormatter.toServerDate(
    localData.eta
  );
  localData.lockedDate = DateTimeFormatter.toServerDate(
    localData.lockedDate
  );
  localData.responseDatetime = DateTimeFormatter.toServerDate(
    localData.responseDatetime
  );
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  );
  localData.transaction.forEach((datum) => {
    datum.transTime = DateTimeFormatter.toServerDate(
      datum.transTime
    );
  });
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
