import makeValidationSchema from './LineItemMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  poId: 0,
  corpid: "",
  poLineNumber: "",
  quoteSheetNumber: "",
  buyerItemNumber: "",
  upcConsumerPackageCode: "",
  europeanArticleNumber: "",
  vendorItemNumber: "",
  preClassed: "",
  caseId: "",
  walmartInternalShipCode: "",
  quantity: "",
  uom: "",
  unitPrice: "",
  unitPrice1: "",
  itemDescription: "",
  packCount: "",
  grossWeight: "",
  grossWeightUom: "",
  volume: "",
  volumeUom: "",
  innerPackCount: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let lineItem = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.TITLE
    )
    let poId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.PO_ID
    )
    let corpid = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.CORPID
    )
    let poLineNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.PO_LINE_NUMBER
    )
    let quoteSheetNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.QUOTE_SHEET_NUMBER
    )
    let buyerItemNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.BUYER_ITEM_NUMBER
    )
    let upcConsumerPackageCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.UPC_CONSUMER_PACKAGE_CODE
    )
    let europeanArticleNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.EUROPEAN_ARTICLE_NUMBER
    )
    let vendorItemNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.VENDOR_ITEM_NUMBER
    )
    let preClassed = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.PRE_CLASSED
    )
    let caseId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.CASE_ID
    )
    let walmartInternalShipCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.WALMART_INTERNAL_SHIP_CODE
    )
    let quantity = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.QUANTITY
    )
    let uom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.UOM
    )
    let unitPrice = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.UNIT_PRICE
    )
    let unitPrice1 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.UNIT_PRICE1
    )
    let itemDescription = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.ITEM_DESCRIPTION
    )
    let packCount = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.PACK_COUNT
    )
    let grossWeight = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.GROSS_WEIGHT
    )
    let grossWeightUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.GROSS_WEIGHT_UOM
    )
    let volume = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.VOLUME
    )
    let volumeUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.VOLUME_UOM
    )
    let innerPackCount = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.INNER_PACK_COUNT
    )

    return {
      lineItem,
      poId,
      corpid,
      poLineNumber,
      quoteSheetNumber,
      buyerItemNumber,
      upcConsumerPackageCode,
      europeanArticleNumber,
      vendorItemNumber,
      preClassed,
      caseId,
      walmartInternalShipCode,
      quantity,
      uom,
      unitPrice,
      unitPrice1,
      itemDescription,
      packCount,
      grossWeight,
      grossWeightUom,
      volume,
      volumeUom,
      innerPackCount
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.lineItem} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="poId"
              type="number"
              label={translatedTextsObject.poId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="corpid"
              label={translatedTextsObject.corpid}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.poLineNumber}>
            <CngTextField
              name="poLineNumber"
              label={translatedTextsObject.poLineNumber}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.quoteSheetNumber}>
            <CngTextField
              name="quoteSheetNumber"
              label={translatedTextsObject.quoteSheetNumber}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.buyerItemNumber}>
            <CngTextField
              name="buyerItemNumber"
              label={translatedTextsObject.buyerItemNumber}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.upcConsumerPackageCode}>
            <CngTextField
              name="upcConsumerPackageCode"
              label={translatedTextsObject.upcConsumerPackageCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.europeanArticleNumber}>
            <CngTextField
              name="europeanArticleNumber"
              label={translatedTextsObject.europeanArticleNumber}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vendorItemNumber}>
            <CngTextField
              name="vendorItemNumber"
              label={translatedTextsObject.vendorItemNumber}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.preClassed}>
            <CngTextField
              name="preClassed"
              label={translatedTextsObject.preClassed}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.caseId}>
            <CngTextField
              name="caseId"
              label={translatedTextsObject.caseId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.walmartInternalShipCode}>
            <CngTextField
              name="walmartInternalShipCode"
              label={translatedTextsObject.walmartInternalShipCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.quantity}>
            <CngTextField
              name="quantity"
              label={translatedTextsObject.quantity}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.uom}>
            <CngTextField
              name="uom"
              label={translatedTextsObject.uom}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.unitPrice}>
            <CngTextField
              name="unitPrice"
              label={translatedTextsObject.unitPrice}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.unitPrice1}>
            <CngTextField
              name="unitPrice1"
              label={translatedTextsObject.unitPrice1}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemDescription}>
            <CngTextField
              name="itemDescription"
              label={translatedTextsObject.itemDescription}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.packCount}>
            <CngTextField
              name="packCount"
              label={translatedTextsObject.packCount}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grossWeight}>
            <CngTextField
              name="grossWeight"
              label={translatedTextsObject.grossWeight}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grossWeightUom}>
            <CngTextField
              name="grossWeightUom"
              label={translatedTextsObject.grossWeightUom}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.volume}>
            <CngTextField
              name="volume"
              label={translatedTextsObject.volume}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.volumeUom}>
            <CngTextField
              name="volumeUom"
              label={translatedTextsObject.volumeUom}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.innerPackCount}>
            <CngTextField
              name="innerPackCount"
              label={translatedTextsObject.innerPackCount}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const LineItemFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default LineItemFormProperties
