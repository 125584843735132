import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { components, useServices } from 'cng-web-lib'

import {
    Box,
    Card,
    Grid,
    CircularProgress,
    Table as MuiTable,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Popover,
    Typography,
    useTheme,
    makeStyles,
    withStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PerfectScrollbar from 'react-perfect-scrollbar'
import HelperText from 'src/views/common/HelperText'
import _ from 'lodash'
import clsx from 'clsx'

const DEFAULT_POPOVER_PROPS = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right'
    }
}

const {
    button: { CngIconButton },
    form: { field: { CngCheckboxField } }
} = components

const useStyles = makeStyles((theme) => ({
    root: { position: 'relative' },
    table: {
        marginTop: 0,
        minWidth: 900,
        '& .MuiTableHead-root': {
            backgroundColor: theme.palette.background.sectionOddBg,
            '& .MuiTableRow-head:not(.table-filter-row)': {
                '& .MuiTableCell-head': {
                    fontSize: 12,
                    fontWeight: 700,
                    lineHeight: 1.2,
                    padding: '12px 16px',
                    textTransform: 'uppercase',
                    '& .MuiTypography-root': {
                        cursor: 'default'
                    }
                }
            }
        },
        '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {
                '&.Mui-selected': {
                    backgroundColor: theme.palette.action.selected
                },
                '& .MuiTableCell-root': {
                    borderBottom:
                        theme.palette.mode === 'dark'
                            ? '1px solid rgba(255, 255, 255, 0.2)'
                            : '1px solid rgba(224, 224, 224, 1)',
                    padding: (props) => (props.compact ? '8px 16px' : '12px 16px'),
                    fontSize: theme.typography.body2.fontSize
                }
            }
        }
    },
    loader: {
        alignItems: 'center',
        backgroundColor: `rgba(255, 255, 255, 0.8)`,
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%'
    },
    headerSortSelect: {
        '& .MuiInputBase-input': {
            fontSize: 14,
            fontWeight: 700,
            padding: '4px 24px 4px 0'
        }
    }
}))

function transformColumnKey(str) {
    return str
        .replace(/[^a-zA-Z ]/g, '')
        .replace(/\s/g, '')
        .toLowerCase()
}

function SummaryQuickGlanceTable(props) {
    const {
        compact,
        columns = [],
        fetch,
        fetchFilters = [],
        sortConfig = { type: 'column', defaultDirection: 'ASC' },
        tableRef,
        helperText,
        variant = 'elevation'
    } = props

    const { fetchPageableRecords } = useServices()
    const theme = useTheme()
    const classes = useStyles({ compact })

    const [data, setData] = useState({
        content: [],
        totalElements: 0,
        totalPages: 0
    })
    const itemsPerPage = 10
    const page = 0
    const tableFilters = []
    const [isFetching, setIsFetching] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [tableSorts, setTableSorts] = useState([
        {
            field: sortConfig?.defaultField || '',
            direction: sortConfig?.defaultDirection || 'ASC'
        }
    ])
    const [tableColsPopoverAnchorEl, setTableColsPopoverAnchorEl] = useState(null)
    const [tableColumns, setTableColumns] = useState(columns.map((column) => transformColumnKey(column.title)))
    const [refreshCount, setRefreshCount] = useState(0)

    useEffect(() => {
        setIsFetching(true)

        fetchPageableRecords.execute(
            fetch.url,
            {
                page: page,
                pageSize: itemsPerPage,
                filters: [...fetchFilters, ...tableFilters],
                sorts: tableSorts,
                ...(fetch.customData && { customData: fetch.customData })
            },
            (result) => {
                const { content, totalElements, totalPages } = result

                let data = [...content]
                setData({ content: data, totalElements, totalPages })
            },
            (error) => console.log(error),
            () => {
                setIsFetching(false)
            }
        )
    }, [refreshCount, tableSorts])

    function handleColumnSortChange(sortKey) {
        setTableSorts((prev) => [
            {
                field: sortKey,
                direction: _.isEmpty(prev)
                    ? 'ASC'
                    : prev[0].direction === 'ASC'
                        ? 'DESC'
                        : 'ASC'
            }
        ])
    }

    const isColumnShown = useCallback(
        (columnKey) => {
            return tableColumns.includes(transformColumnKey(columnKey))
        }, [tableColumns])


    function performRefresh() {
        setRefreshCount((prev) => prev + 1)
        setSelectedRows([])
    }

    /**
     * Updates table data by pointing with an indicator
     * @param {Object|Object[]} payload Value used to update table data. Data type must be array or object.
     * @param {Object} config
     */
    function updateData(payload, config = { indicator: 'id' }) {
        const { indicator } = config
        const clonedData = [...data.content]
        const clonedSelectedRows = [...selectedRows]

        function replaceData(value) {
            const dataIndex = _.findIndex(clonedData, { [indicator]: value[indicator] })
            const selectedRowIndex = _.findIndex(clonedSelectedRows, { [indicator]: value[indicator] })

            clonedData.splice(dataIndex, 1, value)

            if (selectedRowIndex !== -1) {
                clonedSelectedRows.splice(selectedRowIndex, 1, value)
            }
        }

        if (Array.isArray(payload)) {
            payload.forEach((value) => replaceData(value))
        } else {
            replaceData(payload)
        }

        setData((prev) => ({ ...prev, content: clonedData }))
        setSelectedRows(clonedSelectedRows)
    }

    if (tableRef) {
        tableRef.current = {
            performRefresh,
            selectedRows,
            updateData,
            setLoading: (value) => setIsFetching(value)
        }
    }

    return (
        <>
            <Card className={clsx(classes.root, 'new-ui-table-component')} variant={variant}>
                <Grid container>
                    {helperText &&
                        <Box paddingLeft={2} paddingTop={2}>
                            <HelperText helperText={helperText} />
                        </Box>
                    }
                    <Grid item xs={12}>
                        <Box padding={2}>
                            <Grid alignItems='center' container spacing={2}>
                                <Grid item xs={12} sm='auto'>
                                    {data.totalElements > 0 && (
                                        <Typography variant='caption'>
                                            {page * itemsPerPage + 1}-
                                            {Math.min(
                                                page * itemsPerPage + itemsPerPage,
                                                data.totalElements
                                            )}
                                            &nbsp;of&nbsp;{data.totalElements}
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12} sm>
                                    <Grid container alignItems='center' justify='flex-end' spacing={2}>
                                        <Grid item xs='auto'>
                                            <CngIconButton size='small' type='outlined' icon={['fal', 'columns']}
                                                onClick={(event) => setTableColsPopoverAnchorEl(event.currentTarget)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

                <PerfectScrollbar>
                    <MuiTable className={clsx(classes.table, 'new-ui-table-component__table')}>
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => {
                                    const isColumnSorted =
                                        !_.isEmpty(tableSorts) &&
                                        sortConfig.type === 'column' &&
                                        column.sortKey === tableSorts[0].field

                                    return (
                                        isColumnShown(column.title) && (
                                            <TableCell
                                                key={index}
                                                {...(column.sortKey && sortConfig.type === 'column' && {
                                                    onClick: () => handleColumnSortChange(column.sortKey)
                                                })}
                                                {...column?.tableHeadCellProps}
                                            >
                                                <Box alignItems='center' display='flex' flexDirection='row' style={{ gap: 8 }}>
                                                    {isColumnSorted && (
                                                        <FontAwesomeIcon
                                                            color={theme.palette.primary.main}
                                                            icon={['fal', tableSorts[0].direction === 'ASC' ? 'caret-up' : 'caret-down']}
                                                        />
                                                    )}
                                                    <Typography color={isColumnSorted ? 'primary' : 'initial'} variant='inherit'>
                                                        {column.title}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        )
                                    )
                                })}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.content.length > 0 ? (
                                data.content.map((datum, rowIndex) => {
                                    return (
                                        <TableRow key={datum.id || rowIndex}>
                                            {columns.map(
                                                (column, colIndex) =>
                                                    isColumnShown(column.title) && (
                                                        <TableCell key={colIndex}{...column?.tableBodyCellProps}>
                                                            {column.render ? column.render(datum) : _.get(datum, column.field)}
                                                        </TableCell>
                                                    )
                                            )}
                                        </TableRow>
                                    )
                                })
                            ) : (
                                <TableRow>
                                    <TableCell align='center' colSpan={columns.length}>
                                        No records to display
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </MuiTable>
                </PerfectScrollbar>
                {isFetching && (
                    <Box className={classes.loader}>
                        <CircularProgress />
                    </Box>
                )}
            </Card>

            <TableColumnsConfigPopover
                anchorEl={tableColsPopoverAnchorEl}
                columns={columns.map((column) => ({
                    key: transformColumnKey(column.title),
                    title: column.title
                }))}
                onChangeTableCols={(columns) => setTableColumns(columns)}
                open={tableColsPopoverAnchorEl ? true : false}
                onClose={() => setTableColsPopoverAnchorEl(null)}
            />
        </>
    )
}

export default SummaryQuickGlanceTable

const StyledPopoverWrapper = withStyles((theme) => ({
    root: {
        maxWidth: '100%',
        padding: 4,
        width: (props) => props.width || theme.breakpoints.values.sm
    }
}))(Paper)

const StyledPopoverHeader = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.sectionOddBg,
        padding: '8px 16px',
        '&::before': {
            display: 'none'
        },
        '& .MuiTypography-root': {
            fontSize: 14,
            fontWeight: 700,
            '&.MuiTypography-root.count': {
                alignItems: 'center',
                backgroundColor: `${theme.palette.primary.main}33`,
                borderRadius: '50%',
                color: theme.palette.primary.main,
                display: 'inline-flex',
                height: 32,
                justifyContent: 'center',
                width: 32
            }
        }
    }
}))(Box)

function TableColumnsConfigPopover(props) {
    const { anchorEl, columns, onChangeTableCols, onClose, open } = props

    const classes = useStyles()

    const methods = useForm({
        defaultValues: columns.reduce(
            (acc, curr) => ({ ...acc, [curr.key]: true }),
            {}
        )
    })

    function onSubmit(data) {
        const result = Object.keys(data).filter((column) => data[column])

        onChangeTableCols(result)
    }

    return (
        <Popover
            anchorEl={anchorEl}
            onClose={onClose}
            open={open}
            {...DEFAULT_POPOVER_PROPS}
        >
            <StyledPopoverWrapper width={300}>
                <StyledPopoverHeader>
                    <Typography variant='body2'>View columns</Typography>
                </StyledPopoverHeader>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Box marginTop={1} maxHeight='50vh' overflow='hidden auto'>
                            <Grid container>
                                {columns.map((column) => (
                                    <Grid
                                        key={column.key}
                                        className={classes.checkbox}
                                        item
                                        xs={12}
                                    >
                                        <CngCheckboxField
                                            name={column.key}
                                            label={column.title}
                                            onChange={(event) => {
                                                methods.setValue(column.title, event.target.checked)
                                                methods.handleSubmit(onSubmit)()
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </form>
                </FormProvider>
            </StyledPopoverWrapper>
        </Popover>
    )
}