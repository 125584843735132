import React from 'react'
import { useFormContext } from "react-hook-form";
import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'
import Grid from '@material-ui/core/Grid'
import makeValidationSchema from './RelatedCcnMakeValidationSchema'

const { form: { field: { CngTextField } }, CngGridItem, } = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    relatedCcn: "",
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

function Fields(props) {
    const { translate } = useTranslation(Namespace.EHBL_CLOSE_MSG)
    const translatedTextsObject = makeTranslatedTextsObject()
    const { setValue } = useFormContext();

    function makeTranslatedTextsObject() {
        let relatedCcn = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.RELATED_CCN
        )

        return { relatedCcn }
    }

    return (
        <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} md={3} >
                <CngTextField
                    name='relatedCcn'
                    label={translatedTextsObject.relatedCcn}
                    onChange={(e) => { setValue('relatedCcn', e.target.value.toUpperCase(), { shouldValidate: true }) }}
                    size='small'
                    required
                />
            </CngGridItem>
        </Grid>
    )
}

const RelatedCcnFormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields
})

export default RelatedCcnFormProperties
