import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, useServices } from 'cng-web-lib'
import { Card, CardActions, CardContent, CircularProgress, Divider } from '@material-ui/core'
import pathMap from '../../../paths/pathMap'
import EhblCloseMsgApiUrls from '../../../apiUrls/EhblCloseMsgApiUrls'
import ViewContent from './ViewContent.js'

const { button: { CngButton } } = components

function ViewPage(props) {
    const { history, showNotification } = props
    const { id } = useParams()

    const [data, setData] = useState(null)
    const { fetchRecord } = useServices()

    useEffect(() => {
        fetchRecord.execute(EhblCloseMsgApiUrls.GET, id, (res) => {
            const data = res.content[0]

            if (data === undefined) {
                showNotification('error', 'Data not found.')
                history.push(pathMap.EHBL_CLOSE_MSG_LIST_VIEW)
            } else {
                setData(data)
            }
        })
    }, [])

    if (!data) {
        return <CircularProgress />
    }

    return (
        <Card>
            <CardContent>
                <ViewContent data={data} />
            </CardContent>
            <Divider />
            <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
                <CngButton color='secondary' onClick={() => history.push(pathMap.EHBL_CLOSE_MSG_LIST_VIEW)}>
                    Back to invoice list
                </CngButton>
            </CardActions>
        </Card>
    )
}

export default ViewPage
