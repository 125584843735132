import InvoiceApiUrls from 'src/apiUrls/InvoiceApiUrls'
import FormProperties from './FormProperties'
import { Box, CircularProgress, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import InvoiceKeys from 'src/constants/locale/key/Invoice'
import pathMap from 'src/paths/pathMap'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {
  const { location } = history
  let formikPropsData
  if (location.state !== undefined) {
    formikPropsData = {
      ...FormProperties.formikProps,
      initialValues: location.state
    }
  } else {
    formikPropsData = FormProperties.formikProps
  }
  return (
    <Paper variant='outlined'>
      <Box padding={2}>
        <CngAddForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={formikPropsData}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: InvoiceApiUrls.POST,
            successRedirect: pathMap.INVOICE_LIST_VIEW
          }}
          renderButtonSection={(loading) => (
            <CustomButtonSection
              history={history}
              loading={loading}
              showNotification={showNotification}
            />
          )}
        />
      </Box>
    </Paper>
  )
}

export default AddPage

function CustomButtonSection(props) {
  const { history, loading, showNotification } = props
  const [alertDialog, setAlertDialog] = useState(false)
  const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false)
  const {
    formState: { isSubmitting },
    getValues,
    handleSubmit
  } = useFormContext()
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let submitSuccessMsg = translate(
      Namespace.INVOICE,
      InvoiceKeys.SUBMIT_SUCCESS_MSG
    )
    let submitButton = translate(
      Namespace.INVOICE,
      InvoiceKeys.SUBMIT_BUTTON
    )

    return {
      submitSuccessMsg,
      submitButton
    }
  }

  async function handleSubmitInvoice() {
    setIsSubmittingInvoice(true)

    return new Promise((resolve) => {
      setTimeout(() => {
        securedSendRequest.execute(
          'POST',
          InvoiceApiUrls.SUBMIT,
          { ...getValues() },
          (response) => {
            var data = response.data
            var errorMessages = data.errorMessages
            if (
              response.status == 200 &&
              data != null &&
              errorMessages == null
            ) {
              showNotification(
                'success',
                translatedTextsObject.submitSuccessMsg
              )
              history.push(pathMap.INVOICE_LIST_VIEW)
            } else {
              showNotification('error', errorMessages)
            }
          },
          (error) => console.error(error),
          () => resolve()
        )
      }, 500)
    }).finally(() => setIsSubmittingInvoice(false))
  }

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting}
            onClick={() => setAlertDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                disabled={isSubmitting}
                onClick={handleSubmit(handleSubmitInvoice)}
                size='medium'
                startIcon={isSubmittingInvoice ? (
                  <CircularProgress size={16} />
                ) : (
                  <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />
                )}
              >
                {translatedTextsObject.submitButton}
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
        onCancel={() => setAlertDialog(false)}
        onConfirm={() => {
          if (history.location.state) {
            history.goBack()
          } else {
            history.push(`${pathMap.INVOICE_LIST_VIEW}`)
          }
        }}
        title='Discard'
      >
        All progress in this session will be lost and can't be restored. Are you
        sure about this?
      </AlertDialog>
    </>
  )
}
