import React, { useRef, useEffect } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { components, useTranslation } from 'cng-web-lib'
import { Grid, Typography, Divider, Tooltip } from '@material-ui/core'
import _ from 'lodash'
import Namespace from '../../../constants/locale/Namespace'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'
import CngSection from '../../../components/cngcomponents/CngSection'
import RelatedCcnFormProperties from './RelatedCcnFormProperties'
import useCustomYupValidationResolver from '../../../components/aciacehighway/hooks/useCustomYupValidationResolver'

const { button: { CngButton, CngIconButton } } = components
const { initialValues } = RelatedCcnFormProperties.formikProps

function RelatedCcnSection(props) {
    const { closemsgRelccn } = props
    const { getValues, setValue } = useFormContext()

    const { translate } = useTranslation(Namespace.EHBL_CLOSE_MSG)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let relatedCcnList = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.RELATED_CCN_LIST
        )

        return { relatedCcnList }
    }

    const generatedIndex = useRef(0)

    function handleAddRelCcn(data) {
        const closemsgRelccn = [...getValues('closemsgRelccn'), data]
        setValue('closemsgRelccn', closemsgRelccn, { shouldDirty: true })
    }

    function handleEditRelCcn(index, data) {
        const closemsgRelccn = [...getValues('closemsgRelccn')]
        closemsgRelccn[index] = data
        setValue('closemsgRelccn', closemsgRelccn)
    }

    function handleDeleteRelCcn(index) {
        const closemsgRelccn = [...getValues('closemsgRelccn')]
        closemsgRelccn.splice(index, 1)
        setValue('closemsgRelccn', closemsgRelccn, { shouldDirty: true })
    }


    return (
        <Grid item xs={12}>
            <CngSection title={translatedTextsObject.relatedCcnList}
                subheader={<Typography color='error' variant='caption'>
                    (Note: Related CCN/House Bill CCN should be Accepted by CBSA)
                </Typography>}>
                <Grid container spacing={1}>
                    {_.isEmpty(closemsgRelccn) ? (handleAddRelCcn(initialValues)) : (
                        closemsgRelccn.map((relCcn, index) => (
                            <React.Fragment key={++generatedIndex.current}>
                                <Grid item xs={12}>
                                    <RelatedCcnButtons
                                        relCcn={relCcn}
                                        onAddRelCcn={handleAddRelCcn}
                                        onDeleteRelCcn={() => handleDeleteRelCcn(index)}
                                        onEditRelCcn={(data) => handleEditRelCcn(index, data)}
                                        lastItem={closemsgRelccn.length === 1}
                                    />
                                </Grid>
                                {index + 1 !== closemsgRelccn.length && (
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                )}
                            </React.Fragment>
                        )))}
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default RelatedCcnSection

function RelatedCcnButtons(props) {
    const { initialValues, makeValidationSchema } = RelatedCcnFormProperties.formikProps
    const { translate } = useTranslation(Namespace.EHBL_CLOSE_MSG)
    const { onAddRelCcn, onEditRelCcn, onDeleteRelCcn, relCcn, lastItem } = props
    const methods = useForm(
        { defaultValues: relCcn, 
          resolver: useCustomYupValidationResolver(makeValidationSchema(translate))  
        })
    const { formState: { isDirty } } = methods

    function handleEditRelCcn(data) {
        methods.reset(data);
        onEditRelCcn(data);
    }

    return (
        <FormProvider {...methods}>
            <Grid alignItems='center' container spacing={1}>
                <Grid item xs={12} sm>
                    <RelatedCcnFormProperties.Fields />
                </Grid>

                <Grid item xs={12} sm='auto'>
                    {isDirty ? (
                        <Grid container spacing={1}>
                            <Grid item xs='auto'>
                                <Grid container spacing={2}>
                                    <Grid item xs='auto'>
                                        <CngButton color='secondary' onClick={() => methods.reset()} size='medium'>Reset</CngButton>
                                    </Grid>
                                    <Grid item xs='auto'>
                                        <CngButton color='primary' onClick={methods.handleSubmit(handleEditRelCcn)} size='medium'>Save changes</CngButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={1}>
                            <Grid item xs='auto'>
                                <Tooltip placement='bottom' title='Add'>
                                    <span>
                                        <CngIconButton
                                            icon={['fal', 'plus']}
                                            onClick={() => onAddRelCcn(initialValues)}
                                            size='small'
                                            type='outlined'
                                        />
                                    </span>
                                </Tooltip>
                            </Grid>
                            {!lastItem &&
                                <Grid item xs='auto'>
                                    <Tooltip placement='bottom' title='Delete'>
                                        <span>
                                            <CngIconButton
                                                icon={['fal', 'trash']}
                                                onClick={() => onDeleteRelCcn(relCcn)}
                                                size='small'
                                                type='outlined'
                                            />
                                        </span>
                                    </Tooltip>
                                </Grid>
                            }
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </FormProvider>
    )
}