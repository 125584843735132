import React from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import LocalTable from '../../../../components/aciacehighway/LocalTable'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

function ScheduleTable(props) {
  const { data } = props

  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let _lineItemId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.LINE_ITEM
    )
    let scheduleQuantity = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_QUANTITY
    )
    let scheduleQuantityUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_QUANTITY_UOM
    )
    let distributionPoNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.DISTRIBUTION_PO_NUMBER
    )
    let distributionCenter = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.DISTRIBUTION_CENTER
    )
    let scheduleDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_DATE
    )

    return {
      _lineItemId,
      scheduleQuantity,
      scheduleQuantityUom,
      distributionPoNumber,
      distributionCenter,
      scheduleDate
    }
  }

  const columns = [
    {
      title: translatedTextsObject._lineItemId,
      field: '_lineItemId',
      filterConfig: { type: 'textfield' }
    },
    {
      title: [
        translatedTextsObject.scheduleQuantity,
        translatedTextsObject.scheduleQuantityUom
      ].join(' & '),
      field: 'scheduleQuantity',
      filterConfig: { type: 'textfield' },
      render: (rowData) => (
        <Typography component='span' variant='inherit'>
          {rowData.scheduleQuantity}
          <TinyChip label={rowData.scheduleQuantityUom} variant='outlined' />
        </Typography>
      )
    },
    {
      title: translatedTextsObject.distributionPoNumber,
      field: 'distributionPoNumber',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.distributionCenter,
      field: 'distributionCenter',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.scheduleDate,
      field: 'scheduleDate',
      render: (rowData) =>
        rowData.scheduleDate &&
        moment(rowData.scheduleDate).format('D MMM YYYY, HH:mm')
    }
  ]

  return <LocalTable columns={columns} data={data} />
}

export default ScheduleTable
