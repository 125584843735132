import makeValidationSchema from './TradePartyMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  poId: 0,
  corpid: "",
  partyName: "",
  partyType: "",
  identificationCode: "",
  identifier: "",
  address: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  state: "",
  country: "",
  postalCode: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let tradeParty = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.TITLE
    )
    let poId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.PO_ID
    )
    let corpid = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.CORPID
    )
    let partyName = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.PARTY_NAME
    )
    let partyType = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.PARTY_TYPE
    )
    let identificationCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.IDENTIFICATION_CODE
    )
    let identifier = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.IDENTIFIER
    )
    let address = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.ADDRESS
    )
    let address1 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.ADDRESS1
    )
    let address2 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.ADDRESS2
    )
    let address3 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.ADDRESS3
    )
    let city = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.CITY
    )
    let state = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.STATE
    )
    let country = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.COUNTRY
    )
    let postalCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.POSTAL_CODE
    )

    return {
      tradeParty,
      poId,
      corpid,
      partyName,
      partyType,
      identificationCode,
      identifier,
      address,
      address1,
      address2,
      address3,
      city,
      state,
      country,
      postalCode
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tradeParty} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="poId"
              type="number"
              label={translatedTextsObject.poId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="corpid"
              label={translatedTextsObject.corpid}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyName}>
            <CngTextField
              name="partyName"
              label={translatedTextsObject.partyName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyType}>
            <CngCodeMasterAutocompleteField 
                  name="partyType" 
                  label={translatedTextsObject.partyType} 
                  disabled={disabled}
                  codeType='DOC_GEN_PARTY_TYPE'  
              />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.identificationCode}>
            <CngCodeMasterAutocompleteField 
                  name="identificationCode" 
                  label={translatedTextsObject.identificationCode} 
                  disabled={disabled}
                  codeType='DOC_GEN_PARTY_ID_CODE'  
              />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.identifier}>
            <CngTextField
              name="identifier"
              label={translatedTextsObject.identifier}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.address}>
            <CngTextField
              name="address"
              label={translatedTextsObject.address}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.address1}>
            <CngTextField
              name="address1"
              label={translatedTextsObject.address1}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.address2}>
            <CngTextField
              name="address2"
              label={translatedTextsObject.address2}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.address3}>
            <CngTextField
              name="address3"
              label={translatedTextsObject.address3}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.city}>
            <CngTextField
              name="city"
              label={translatedTextsObject.city}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.state}>
            <CngTextField
              name="state"
              label={translatedTextsObject.state}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.country}>
            <CngCountryAutocompleteField
              name="country"
              label={translatedTextsObject.country}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.postalCode}>
            <CngTextField
              name="postalCode"
              label={translatedTextsObject.postalCode}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const TradePartyFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TradePartyFormProperties
