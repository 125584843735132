import { Box, Link, Tooltip } from '@material-ui/core'
import { constants, useTranslation, useServices } from 'cng-web-lib'

import StatusMessageApiUrls from 'src/apiUrls/StatusMessageApiUrls'
import React, {useState, useEffect } from 'react'
import StatusMessageKeys from 'src/constants/locale/key/StatusMessage'
import Namespace from 'src/constants/locale/Namespace'
import sweetalert from 'sweetalert'
import {FileForUserGetPartyId} from 'src/common/FileForUserCommon'
import Table from '../../../components/aciacehighway/Table'
import moment from 'moment'

const {
  filter: { BETWEEN, EQUAL, LIKE }
} = constants

function TablePage(props) {
  const {
    history,
    location: { pathname },
    showNotification
  } = props

  const { translate } = useTranslation(Namespace.STATUS_MESSAGE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const {securedSendRequest } = useServices();

function onDownloadFile(fname){

  function onSuccess(response) {

    let blob = new Blob([response.data]),
      downloadUrl = window.URL.createObjectURL(blob),
      filename = fname,
      disposition = response.headers["content-disposition"];

    if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
        }
    }

    let a = document.createElement("a");
    if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
    } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
    }
  }

  function onError(error) {
    sweetalert("File not found.","","error")
  }

  function onComplete(){

  }
  const config = {}

  var url = StatusMessageApiUrls.DOWNLOAD+"?filename="+fname;
  var data = {
    responseType: "blob"
  }
  securedSendRequest.execute('POST',url, data, onSuccess, onError, onComplete,config,null);

};

  function makeTranslatedTextsObject() {
    let poNumber = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.PO_NUMBER
    )
    let vesselCode = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VESSEL_CODE
    )
    let vesselName = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VESSEL_NAME
    )
    let departureDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.DEPARTURE_DATE
    )
    let statusDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.STATUS_DATE
    )
    let subFileName = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.SUB_FILE_NAME
    )
    let createdDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.CREATED_DATE
    )

    return {
      poNumber,
      vesselCode,
      vesselName,
      departureDate,
      subFileName,
      createdDate,
      statusDate
    }
  }

  const columns = [
    {
      field: 'poNumber',
      title: translatedTextsObject.poNumber
    },
    {
      field: 'departureDate',
      title: translatedTextsObject.statusDate,
      render: (rowData) =>
        rowData.departureDate &&
        moment(rowData.departureDate).format('D MMM YYYY')
    },
    {
      field: 'vesselName',
      title: translatedTextsObject.vesselName
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate && moment(rowData.createdDate).format('D MMM YYYY')
    },
    {
      field: 'subFileName',
      title: translatedTextsObject.subFileName,
      render: (rowData) => (
        <Tooltip title={rowData.subFileName}>
          <Box maxWidth={150}>
            <Link
              component='div'
              noWrap
              onClick={() => onDownloadFile(rowData.subFileName)}
              style={{ cursor: 'pointer', width: '100%' }}
            >
              {rowData.subFileName}
            </Link>
          </Box>
        </Tooltip>
      )
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.poNumber,
      type: 'textfield',
      name: 'poNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.statusDate,
      type: 'daterange',
      name: 'departureDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.vesselName,
      type: 'textfield',
      name: 'vesselName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.subFileName,
      type: 'textfield',
      name: 'subFileName',
      operatorType: LIKE
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        compact
        customRowActions={[
          {
            icon: ['fal', 'file-export'],
            onClick: (rowData) => onDownloadFile(rowData.subFileName),
            tooltip: () => 'Export'
          }
        ]}
        exportData={{ url: StatusMessageApiUrls.EXPORT }}
        fetch={{ url: StatusMessageApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => history.push(`${pathname}/view/${rowData.id}`)}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
      />
    </>
  )
}

export default TablePage
