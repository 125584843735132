import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    id: Yup.string(),
    corpid: Yup.string(),
    poNumber: Yup.string().max(22, "Max length allowed is 22.").required(requiredMessage),
    poPurpose: Yup.string().nullable(),
    poType: Yup.string().required(requiredMessage),
    poDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).required(requiredMessage),
    deptNumber: Yup.string().max(30, "Max length allowed is 30.").nullable(),
    orderType: Yup.string().max(30, "Max length allowed is 30.").nullable(),
    eventCode: Yup.string().max(30, "Max length allowed is 30.").nullable(),
    zbNumber: Yup.string().max(30, "Max length allowed is 30.").nullable(),
    ydNumber: Yup.string().max(30, "Max length allowed is 30.").nullable(),
    acdNumber: Yup.string().max(30, "Max length allowed is 30.").nullable(),
    fobNumber: Yup.string().max(30, "Max length allowed is 30.").nullable(),
    buyerName: Yup.string().max(60, "Max length allowed is 60.").nullable(),
    requestedShipDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    cancelAfterDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    shipNotBeforeDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    shipNoLaterDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    deliveryRequestedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    zzzDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    totWeight: Yup.number().required(requiredMessage),
    totWeightUom: Yup.string().max(2, "Max length allowed is 2.").required(requiredMessage),
    totVolume: Yup.number().required(requiredMessage),
    totVolumeUom: Yup.string().max(2, "Max length allowed is 2.").required(requiredMessage),
    matchInd: Yup.string().nullable(),
    invInd: Yup.string().nullable(),
    status: Yup.string().nullable(),
    lockedBy: Yup.string().nullable(),
    lockedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    responseDatetime: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    subFileName: Yup.string().nullable(),
    routingInfo: Yup.array(),
    tradeParty: Yup.array(),
    lineItem: Yup.array(),
    subline: Yup.array(),
    sublineAllowance: Yup.array(),
  })
}

export default makeValidationSchema
