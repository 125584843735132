import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let regexAlphaNumeric= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"
  let errMsgAlphaNumeric= "Allowed special characters are  ,+-$#@%&!'`^_:;.?[]{}/=\"|\\"

  return Yup.object({
    invId: Yup.string().nullable(),
    corpid: Yup.string().nullable(),
    poNumber: Yup.string().max(22).matches(regexAlphaNumeric,errMsgAlphaNumeric).nullable().required(requiredMessage),
    wallMartItem: Yup.string().max(50).matches(regexAlphaNumeric,errMsgAlphaNumeric).nullable().required(requiredMessage),
    containerNo: Yup.string().max(50).matches(regexAlphaNumeric,errMsgAlphaNumeric).nullable().required(requiredMessage),
    sizeAndType: Yup.string().max(30).matches(regexAlphaNumeric,errMsgAlphaNumeric).nullable(),
    quantity: Yup.string().max(30).nullable().matches("^[0-9]*$", "Qauntity must be in digits."),
    uom: Yup.string().nullable().required(requiredMessage),
    volume: Yup.string().max(30).nullable().required(requiredMessage).matches("^[0-9\.]*$", "Volume must be in digits."),
    unitPrice: Yup.string().max(30).nullable().matches("^[0-9\.]*$", "Unit Price must be in digits."),
    entValue: Yup.string().max(50).nullable().matches("^[0-9\.]*$", "Estimated Value must be in digits."),
  })
}

export default makeValidationSchema
