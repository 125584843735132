import React from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import LocalTable from '../../../../components/aciacehighway/LocalTable'

function SublineTable(props) {
  const { data, getLookupValue } = props

  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let _lineItemId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.LINE_ITEM
    )
    let tariffNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.TARIFF_NUMBER
    )
    let countryOrigin = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.COUNTRY_ORIGIN
    )
    let commodityGroupingCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.COMMODITY_GROUPING_CODE
    )
    let usCustomsBindingRule = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.US_CUSTOMS_BINDING_RULE
    )
    let quotaCategory = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY
    )
    let message = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.MESSAGE
    )

    return {
      _lineItemId,
      tariffNumber,
      countryOrigin,
      commodityGroupingCode,
      usCustomsBindingRule,
      quotaCategory,
      message
    }
  }

  const columns = [
    {
      title: translatedTextsObject._lineItemId,
      field: '_lineItemId',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.tariffNumber,
      field: 'tariffNumber',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.countryOrigin,
      field: 'countryOrigin',
      filterConfig: { type: 'textfield' },
      render: (rowData) => getLookupValue('country', rowData.countryOrigin)
    },
    {
      title: translatedTextsObject.commodityGroupingCode,
      field: 'commodityGroupingCode',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.usCustomsBindingRule,
      field: 'usCustomsBindingRule',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.quotaCategory,
      field: 'quotaCategory',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.message,
      field: 'message',
      filterConfig: { type: 'textfield' }
    }
  ]

  return <LocalTable columns={columns} data={data} />
}

export default SublineTable
