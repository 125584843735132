
import React, { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { components, DataFlattener, useTranslation } from 'cng-web-lib'
import { Grid, Typography } from '@material-ui/core'
import makeValidationSchema from './MakeValidationSchema'
import Namespace from '../../../constants/locale/Namespace'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'
import { FileForUserGetUserDetails, FileForUserGetCurrentPartyName } from 'src/common/FileForUserCommon'
import CngSection from '../../../components/cngcomponents/CngSection'
import RelatedCcnSection from './RelatedCcnSection'

const {
    form: {
        field: { CngTextField, CngCodeMasterAutocompleteField }
    },
    CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    id: "",
    manifestId: null,
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    submittedBy: "",
    submittedDate: "",
    version: "",
    partyId: "",
    prevCcn: "",
    primaryCcn: "",
    carrierCode: "",
    closemsgNumber: "",
    msgFuncCode: "",
    amendmentCode: "",
    businessName: "",
    closemsgRelccn: []
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

function Fields(props) {
    const { disabled, shouldHideMap, autoPopulateBusinessName } = props

    const { getValues, setValue, watch } = useFormContext()
    const closemsgRelccn = watch("closemsgRelccn");

    const { translate } = useTranslation(Namespace.EHBL_CLOSE_MSG)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let title = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.TITLE
        )

        let previousCcn = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.PREVIOUS_CCN
        )

        let primaryCcn = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.PRIMARY_CCN
        )

        let carrierCode = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.CARRIER_CODE
        )

        let closeMessageNumber = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.CLOSE_MESSAGE_NUMBER
        )

        let messageFunctionCode = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.MESSAGE_FUNCTION_CODE
        )

        let amendmentCode = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.AMENDMENT_CODE
        )

        let businessName = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.BUSINESS_NAME
        )

        return {
            title,
            previousCcn,
            primaryCcn,
            carrierCode,
            closeMessageNumber,
            messageFunctionCode,
            amendmentCode,
            businessName
        }
    }

    useWatch("fileForUserId");
    useWatch("fileForUserLoginId")
    useWatch("fileForUserPartyId");
    useEffect(() => {
        let fileForUserDetails = FileForUserGetUserDetails();
        if (fileForUserDetails != null && fileForUserDetails != undefined) {
            setValue("fileForUserId", fileForUserDetails.fileForUserId)
            setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
            setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
        }
    }, []);

    // Only auto populate business name on add page
    useEffect(() => {
        if (autoPopulateBusinessName) {
            setValue('businessName', FileForUserGetCurrentPartyName())
        }
    }, [])

    useEffect(() => {
        let fileForUserDetails = FileForUserGetUserDetails();
        if (fileForUserDetails != null && fileForUserDetails != undefined) {
            setValue("fileForUserId", fileForUserDetails.fileForUserId)
            setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
            setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)

            closemsgRelccn.forEach(data => {
                data.fileForUserId = fileForUserDetails.fileForUserId;
                data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
                data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
            });
        }
    }, [closemsgRelccn])

    function onPrevCcnChange(e) {
        setValue("prevCcn", e.target.value.toUpperCase(), { shouldValidate: true })
        let scac = getValues('carrierCode') ? getValues('carrierCode') : ''
        setValue('closemsgNumber', scac + e.target.value, { shouldValidate: true })
    }

    function onScacChange(e) {
        setValue("carrierCode", e.target.value.toUpperCase(), { shouldValidate: true })
        let prevCcn = getValues('prevCcn') ? getValues('prevCcn') : ''
        setValue('closemsgNumber', e.target.value + prevCcn, { shouldValidate: true })
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CngSection title={translatedTextsObject.title}
                        subheader={
                            <Typography color='textSecondary' variant='caption'>
                                <Typography variant='inherit' color='error'>*</Typography>Mandatory fields</Typography>
                        }>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.prevCcn}>
                                        <CngTextField
                                            name='prevCcn'
                                            disabled={disabled}
                                            label={translatedTextsObject.previousCcn}
                                            size='small'
                                            required
                                            onChange={(e) => { onPrevCcnChange(e) }}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.primaryCcn}>
                                        <CngTextField
                                            name='primaryCcn'
                                            disabled={disabled}
                                            label={translatedTextsObject.primaryCcn}
                                            size='small'
                                            onChange={(e) => {
                                                setValue("primaryCcn", e.target.value.toUpperCase(), { shouldValidate: true })
                                              }}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.carrierCode}>
                                        <CngTextField
                                            name='carrierCode'
                                            disabled={disabled}
                                            label={translatedTextsObject.carrierCode}
                                            size='small'
                                            required
                                            onChange={(e) => { onScacChange(e) }}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.closemsgNumber}>
                                        <CngTextField
                                            name='closemsgNumber'
                                            disabled={true}
                                            label={translatedTextsObject.closeMessageNumber}
                                            size='small'
                                            InputLabelProps={{ shrink: getValues('closemsgNumber') }}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.msgFuncCode}>
                                        <CngCodeMasterAutocompleteField
                                            name="msgFuncCode"
                                            label={translatedTextsObject.messageFunctionCode}
                                            codeType='EHBL_CLOSE_MSG_MSG_FUNC'
                                            required
                                            size='small'
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.amendmentCode}>
                                        <CngCodeMasterAutocompleteField
                                            name="amendmentCode"
                                            label={translatedTextsObject.amendmentCode}
                                            codeType='EHBL_CLOSE_MSG_AMENDMENT_CODE'
                                            size='small'
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.businessName}>
                                        <CngTextField
                                            name='businessName'
                                            disabled={disabled}
                                            label={translatedTextsObject.businessName}
                                            size='small'
                                            inputProps={{ maxLength: 30 }}
                                            onChange={(e) => {
                                                setValue("businessName", e.target.value.toUpperCase(), { shouldValidate: true })
                                              }}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CngSection>
                </Grid>
                <RelatedCcnSection closemsgRelccn={closemsgRelccn} />
            </Grid>
        </>
    )
}

function toClientDataFormat(serverData) {
    return serverData;
}

function toServerDataFormat(localData) {
    return localData;
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
