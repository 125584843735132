import {
  Box,
  Chip,
  Grid,
  Link,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import H2hSubFailsApiUrls from 'src/apiUrls/H2hSubFailsApiUrls'
import React,{ useEffect, useState } from 'react'
import H2hSubFailsKeys from 'src/constants/locale/key/H2hSubFails'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderApiUrls from 'src/apiUrls/DocGenPurchaseOrderApiUrls'
import StatusMessageApiUrls from 'src/apiUrls/StatusMessageApiUrls'
import StatusResponseApiUrls from 'src/apiUrls/StatusResponseApiUrls'
import {
  FileForUserGetPartyId,
  FileForUserGetUserDetails
} from 'src/common/FileForUserCommon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Table from '../../../components/aciacehighway/Table'
import _ from 'lodash'
import moment from 'moment'
import { FormProvider, useForm } from 'react-hook-form'

const {
  button: { CngButton },
  dropzone: { CngFileUpload },
  form: {
    field: { CngSelectField }
  }
} = components

const {
  filter: { BETWEEN, EQUAL, LIKE }
} = constants

function TablePage(props) {
  const { showNotification } = props

  const { translate } = useTranslation(Namespace.H2H_SUB_FAILS)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { securedSendRequest } = useServices()

  const theme = useTheme()

  function onDownloadFile(fname, source) {
    let url = DocGenPurchaseOrderApiUrls.DOWNLOAD
    if (source == 'statusMsg') {
      url = StatusMessageApiUrls.DOWNLOAD
    } else if (source == 'ack') {
      url = H2hSubFailsApiUrls.DOWNLOAD_ACK
    }

    function onSuccess(response) {
      let blob = new Blob([response.data]),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = fname,
        disposition = response.headers['content-disposition']

      if (disposition && disposition.indexOf('attachment') !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition)

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      let a = document.createElement('a')
      if (typeof a.download === 'undefined') {
        window.location.href = downloadUrl
      } else {
        a.href = downloadUrl
        a.download = filename
        document.body.appendChild(a)
        a.click()
      }
    }

    function onError(error) {
      showNotification('error', 'File not found.')
    }

    function onComplete() {}
    const config = {}
    var data = {
      responseType: 'blob'
    }

    securedSendRequest.execute(
      'POST',
      url + '?filename=' + fname,
      data,
      onSuccess,
      onError,
      onComplete,
      config,
      null
    )
  }

  function makeTranslatedTextsObject() {
    let h2hSubFails = translate(Namespace.H2H_SUB_FAILS, H2hSubFailsKeys.TITLE)
    let id = translate(Namespace.H2H_SUB_FAILS, H2hSubFailsKeys.ID)
    let h2hId = translate(Namespace.H2H_SUB_FAILS, H2hSubFailsKeys.H2H_ID)
    let poNumber = translate(Namespace.H2H_SUB_FAILS, H2hSubFailsKeys.PO_NUMBER)
    let wallmartItemNo = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.WALLMART_ITEM_NO
    )
    let fileType = translate(Namespace.H2H_SUB_FAILS, H2hSubFailsKeys.FILE_TYPE)
    let processStatus = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.PROCESS_STATUS
    )
    let receivedDate = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.RECEIVED_DATE
    )
    let receivedFileName = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.RECEIVED_FILE_NAME
    )
    let acknowledgedFileName = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.ACKNOWLEDGED_FILE_NAME
    )
    let rejErrMsg = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.REJ_ERR_MSG
    )

    return {
      h2hSubFails,
      id,
      h2hId,
      poNumber,
      wallmartItemNo,
      fileType,
      processStatus,
      receivedDate,
      receivedFileName,
      acknowledgedFileName,
      rejErrMsg
    }
  }

  const columns = [
    {
      field: 'id',
      sortKey: 'id',
      title: translatedTextsObject.id
    },
    {
      field: 'fileType',
      sortKey: 'fileType',
      title: translatedTextsObject.fileType,
      render: (rowData) =>
        rowData.fileType && (
          <Typography variant='inherit'>
            {rowData.fileType === 'statusMsg'
              ? 'Status message'
              : rowData.fileType === 'po' && 'Purchase order'}
          </Typography>
        )
    },
    {
      field: 'processStatus',
      sortKey: 'processStatus',
      title: translatedTextsObject.processStatus,
      render: (rowData) => {
        if (!rowData.processStatus) return

        const processStatus = rowData.processStatus.toLowerCase()

        return processStatus === 'failed' ? (
          <Chip
            label='Failed'
            size='small'
            style={{
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.contrastText
            }}
          />
        ) : (
          processStatus === 'success' && (
            <Chip
              label='Success'
              size='small'
              style={{
                backgroundColor: theme.palette.success.main,
                color: theme.palette.success.contrastText
              }}
            />
          )
        )
      }
    },
    {
      field: 'receivedFileName',
      title: translatedTextsObject.receivedFileName,
      render: (rowData) => (
        <Tooltip title={rowData.receivedFileName}>
          <Box maxWidth={150}>
            <Link
              component='div'
              noWrap
              onClick={() =>
                onDownloadFile(rowData.receivedFileName, rowData.fileType)
              }
              style={{ cursor: 'pointer', width: '100%' }}
            >
              {rowData.receivedFileName}
            </Link>
          </Box>
        </Tooltip>
      )
    },
    {
      field: 'acknowledgedFileName',
      title: translatedTextsObject.acknowledgedFileName,
      render: (rowData) => (
        <Tooltip title={rowData.receivedFileName}>
          <Box maxWidth={150}>
            <Link
              component='div'
              noWrap
              onClick={() =>
                onDownloadFile(rowData.acknowledgedFileName, 'ack')
              }
              style={{ cursor: 'pointer', width: '100%' }}
            >
              {rowData.receivedFileName}
            </Link>
          </Box>
        </Tooltip>
      )
    },
    {
      field: 'receivedDate',
      title: translatedTextsObject.receivedDate,
      sortKey: 'receivedDate',
      render: (rowData) =>
        rowData.receivedDate &&
        moment(rowData.receivedDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'rejErrMsg',
      title: translatedTextsObject.rejErrMsg,
      render: (rowData) => <Box minWidth={300}>{rowData.rejErrMsg}</Box>
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.id,
      type: 'textfield',
      name: 'id',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.fileType,
      type: 'select',
      name: 'fileType',
      operatorType: LIKE,
      options: [
        {
          label: 'Purchase order',
          value: 'po',
          filterValue: {
            value: 'po'
          }
        },
        {
          label: 'Status message',
          value: 'statusMsg',
          filterValue: {
            value: 'statusMsg'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.processStatus,
      type: 'select',
      name: 'processStatus',
      operatorType: LIKE,
      options: [
        {
          label: 'Success',
          value: 'success',
          filterValue: {
            value: 'success'
          }
        },
        {
          label: 'Failed',
          value: 'failed',
          filterValue: {
            value: 'failed'
          }
        }
      ]
    },
    {
      label: 'Received Date',
      type: 'daterange',
      name: 'receivedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.rejErrMsg,
      type: 'textfield',
      name: 'rejErrMsg',
      operatorType: LIKE
    }
  ]

  function onFileUpload(data) {
    const { fileType } = data
    const { file } = data.file!=null && data.file.length>0 ? data.file[0] : null
    let fileForUserDetails = FileForUserGetUserDetails()
    let url = ''

    if (file != null) {
      if (file.type != 'text/xml') {
        showNotification('error', 'File type must be in xml format')
        return false
      }
    } else {
      showNotification('error', 'Invalid file')
      return false
    }

    const formData = new FormData()

    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      formData.append('fileForUserDetails', JSON.stringify(fileForUserDetails))
    } else {
      formData.append('fileForUserDetails', '{}')
    }

    formData.append('file', file)

    if (fileType == 'PO') {
      url = DocGenPurchaseOrderApiUrls.UPLOAD
    } else if (fileType == 'SM') {
      url = StatusResponseApiUrls.POST
    }

    function onSuccess(response) {
      showNotification('success', 'File has been uploaded successfully')
    }

    function onError(error) {
      showNotification('error', 'Error while uploading file')
    }

    function onComplete() {}

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    securedSendRequest.execute(
      'POST',
      url,
      formData,
      onSuccess,
      onError,
      onComplete,
      config,
      null
    )
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FTPTransactionTransferUpload
            onUpload={onFileUpload}
            showNotification={showNotification}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            columns={columns}
            compact
            exportData={{ url: H2hSubFailsApiUrls.EXPORT }}
            fetch={{ url: H2hSubFailsApiUrls.GET }}
            fetchFilters={[
              { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
            ]}
            filters={filters}
            rowActions={[
              {
                icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
                label: 'Export received file',
                onClick: (rowData) =>
                  onDownloadFile(rowData.receivedFileName, rowData.fileType)
              },
              {
                icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
                label: 'Export acknowledged file',
                onClick: (rowData) =>
                  onDownloadFile(rowData.acknowledgedFileName, 'ack')
              }
            ]}
            showNotification={showNotification}
            sortConfig={{
              type: 'column',
              defaultField: 'id',
              defaultDirection: 'DESC'
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default TablePage

function FTPTransactionTransferUpload(props) {
  const { onUpload, showNotification } = props

  const methods = useForm({
    defaultValues: { fileType: 'PO', file: [] }
  })
  const file = methods.watch('file')

  function onSubmit(data) {
    if (_.isEmpty(file)) return

    onUpload(data)
  }

  return (
    <Paper>
      <Box padding={2}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <CngSelectField
                      name='fileType'
                      label='File type'
                      items={[
                        { text: 'Purchase Order', value: 'PO' },
                        { text: 'Status Message', value: 'SM' }
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CngFileUpload
                  accept={['.xml']}
                  maxFiles={1}
                  maxSize={6291456}
                  files={methods.getValues('file')}
                  onFileSelect={(file) => methods.setValue('file', file)}
                  showFormFields={false}
                  onDropRejected={(message) =>
                    showNotification('error', message)
                  }
                  moreActions={[
                    {
                      action: 'remove',
                      name: 'Remove',
                      icon: ['fal', 'trash']
                    }
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify='flex-end'>
                  <Grid item xs='auto'>
                    <CngButton type='submit'>Upload</CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Paper>
  )
}
