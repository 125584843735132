import React from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import LocalTable from '../../../../components/aciacehighway/LocalTable'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import Typography from '@material-ui/core/Typography'

function SublineAllowanceTable(props) {
  const { data } = props

  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let _sublineId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.SUBLINE
    )
    let chargeCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_CODE
    )
    let chargePercent = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_PERCENT
    )
    let chargeCost = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_COST
    )
    let chargeUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_UOM
    )
    let chargeQuantity = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_QUANTITY
    )
    let chargeMethodDesc = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_METHOD_DESC
    )

    return {
      _sublineId,
      chargeCode,
      chargePercent,
      chargeCost,
      chargeUom,
      chargeQuantity,
      chargeMethodDesc
    }
  }

  const columns = [
    {
      title: translatedTextsObject._sublineId,
      field: '_sublineId',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.chargeCode,
      field: 'chargeCode',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.chargePercent,
      field: 'chargePercent',
      filterConfig: { type: 'textfield' }
    },
    {
      title: [
        translatedTextsObject.chargeCost,
        translatedTextsObject.chargeUom
      ].join(' & '),
      field: 'chargeCost',
      filterConfig: { type: 'textfield' },
      render: (rowData) => (
        <Typography component='span' variant='inherit'>
          {rowData.chargeCost}
          <TinyChip label={rowData.chargeUom} variant='outlined' />
        </Typography>
      )
    },
    {
      title: translatedTextsObject.chargeQuantity,
      field: 'chargeQuantity',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.chargeMethodDesc,
      field: 'chargeMethodDesc',
      filterConfig: { type: 'textfield' }
    }
  ]

  return <LocalTable columns={columns} data={data} />
}

export default SublineAllowanceTable
