import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import H2hSubFailsKeys from 'src/constants/locale/key/H2hSubFails'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  h2hId: "",
  poNumber: "",
  wallmartItemNo: "",
  fileType: "",
  processStatus: "",
  receivedDate: "",
  receivedFileName: "",
  acknowledgedFileName: "",
  rejErrMsg: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.H2H_SUB_FAILS)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let h2hSubFails = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.TITLE
    )
    let id = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.ID
    )
    let h2hId = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.H2H_ID
    )
    let poNumber = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.PO_NUMBER
    )
    let wallmartItemNo = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.WALLMART_ITEM_NO
    )
    let fileType = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.FILE_TYPE
    )
    let processStatus = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.PROCESS_STATUS
    )
    let receivedDate = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.RECEIVED_DATE
    )
    let receivedFileName = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.RECEIVED_FILE_NAME
    )
    let acknowledgedFileName = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.ACKNOWLEDGED_FILE_NAME
    )
    let rejErrMsg = translate(
      Namespace.H2H_SUB_FAILS,
      H2hSubFailsKeys.REJ_ERR_MSG
    )

    return {
      h2hSubFails,
      id,
      h2hId,
      poNumber,
      wallmartItemNo,
      fileType,
      processStatus,
      receivedDate,
      receivedFileName,
      acknowledgedFileName,
      rejErrMsg
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.h2hSubFails} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.id}>
            <CngTextField
              name="id"
              label={translatedTextsObject.id}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.h2hId}>
            <CngTextField
              name="h2hId"
              label={translatedTextsObject.h2hId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.poNumber}>
            <CngTextField
              name="poNumber"
              label={translatedTextsObject.poNumber}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.wallmartItemNo}>
            <CngTextField
              name="wallmartItemNo"
              label={translatedTextsObject.wallmartItemNo}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fileType}>
            <CngTextField
              name="fileType"
              label={translatedTextsObject.fileType}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processStatus}>
            <CngTextField
              name="processStatus"
              label={translatedTextsObject.processStatus}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.receivedDate}>
            <CngDateField
              name="receivedDate"
              label={translatedTextsObject.receivedDate}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.receivedFileName}>
            <CngTextField
              name="receivedFileName"
              label={translatedTextsObject.receivedFileName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.acknowledgedFileName}>
            <CngTextField
              name="acknowledgedFileName"
              label={translatedTextsObject.acknowledgedFileName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.rejErrMsg}>
            <CngTextField
              name="rejErrMsg"
              label={translatedTextsObject.rejErrMsg}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.receivedDate = DateTimeFormatter.toClientDate(
    localData.receivedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.receivedDate = DateTimeFormatter.toServerDate(
    localData.receivedDate
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
