import InvoiceApiUrls from 'src/apiUrls/InvoiceApiUrls'
import FormProperties from './FormProperties'
import { Box, Grid, Paper } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from 'src/paths/pathMap'
import InvoiceKeys from 'src/constants/locale/key/Invoice'
import Namespace from 'src/constants/locale/Namespace'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { useFormContext } from 'react-hook-form'
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage({ history, showNotification }) {
  const { id } = useParams()
  const serverDataRef = useRef()
  const { translate } = useTranslation(Namespace.INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let submitUPSConfirmationMsg = translate(
      Namespace.INVOICE,
      InvoiceKeys.SUBMIT_UPS_CONFIRMATION_MSG
    )
    let submitSuccessMsg = translate(
      Namespace.INVOICE,
      InvoiceKeys.SUBMIT_SUCCESS_MSG
    )
    let validationErrorMsg = translate(
      Namespace.INVOICE,
      InvoiceKeys.VALIDATION_ERROR_MSG
    )

    let submitButton = translate(Namespace.INVOICE, InvoiceKeys.SUBMIT_BUTTON)

    return {
      submitUPSConfirmationMsg,
      submitSuccessMsg,
      validationErrorMsg,
      submitButton
    }
  }

  return (
    <Paper variant='outlined'>
      <Box padding={2}>
        <CngEditForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: InvoiceApiUrls.GET,
            onPreSuccess: (serverData) => {
              serverDataRef.current = serverData
            },
            onPostSubmitSuccess: (e) => {
              let fileForUserDetails = FileForUserGetUserDetails();
              if (fileForUserDetails != null && fileForUserDetails != undefined) {
                e.fileForUserId = fileForUserDetails.fileForUserId;
                e.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
                e.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
              }
            }
          }}
          update={{ url: InvoiceApiUrls.PUT }}
          id={id}
          renderButtonSection={(_, loading) => (
            <CustomButtonSection
              data={serverDataRef.current}
              history={history}
              loading={loading}
              showNotification={showNotification}
              translatedTextsObject={translatedTextsObject}
            />
          )}
        />
      </Box>
    </Paper>
  )
}

export default EditPage

function CustomButtonSection(props) {
  const { data, history, loading, showNotification, translatedTextsObject } =
    props
  const [alertDialog, setAlertDialog] = useState(false)
  const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false)
  const {
    formState: { isSubmitting },
    handleSubmit
  } = useFormContext()
  const { securedSendRequest } = useServices()

  async function handleSubmitInvoice(formData) {
    setIsSubmittingInvoice(true)

    return new Promise((resolve) => {
      setTimeout(() => {
        securedSendRequest.execute(
          'POST',
          InvoiceApiUrls.SUBMIT,
          { ...data, ...formData },
          (response) => {
            var data = response.data
            var errorMessages = data.errorMessages
            if (
              response.status == 200 &&
              data != null &&
              errorMessages == null
            ) {
              showNotification(
                'success',
                translatedTextsObject.submitSuccessMsg
              )
              history.push(pathMap.INVOICE_LIST_VIEW)
            } else {
              showNotification('error', errorMessages)
            }
          },
          (error) => console.error(error)
        )
        resolve()
      }, 500)
    }).finally(() => setIsSubmittingInvoice(false))
  }

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading || isSubmitting}
            onClick={() => setAlertDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading || isSubmitting}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                disabled={loading || isSubmitting}
                onClick={handleSubmit(handleSubmitInvoice)}
                size='medium'
                shouldShowProgress={isSubmittingInvoice}
              >
                {translatedTextsObject.submitButton}
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
        onCancel={() => setAlertDialog(false)}
        onConfirm={() => history.push(`${pathMap.INVOICE_LIST_VIEW}`)}
        title='Discard'
      >
        All progress in this session will be lost and can't be restored. Are you
        sure about this?
      </AlertDialog>
    </>
  )
}
