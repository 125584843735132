import React from 'react'
import { constants, useTranslation } from 'cng-web-lib'
import { Box, Chip, Typography } from '@material-ui/core'
import { getStatusMetadata } from 'src/common/NACommon'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import SummaryQuickGlanceTable from './SummaryQuickGlanceTable'
import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls'

const { filter: { EQUAL } } = constants

function TablePage(props) {
    const { manifestId, manifestPort } = props
    const { translate } = useTranslation(Namespace.ACE_HIGHWAY_SHIPMENT)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let scn = translate(
            Namespace.ACE_HIGHWAY_SHIPMENT,
            AceHighwayShipmentKeys.SCN
        )
        let shipmentType = translate(
            Namespace.ACE_HIGHWAY_SHIPMENT,
            AceHighwayShipmentKeys.SHIPMENT_TYPE
        )
        let acquittalNo = translate(
            Namespace.ACE_HIGHWAY_SHIPMENT,
            AceHighwayShipmentKeys.ACQUITTAL_NO
        )
        let shipperName = translate(
            Namespace.ACE_HIGHWAY_SHIPMENT,
            AceHighwayShipmentKeys.SHIPPER_NAME
        )
        let consigneeName = translate(
            Namespace.ACE_HIGHWAY_SHIPMENT,
            AceHighwayShipmentKeys.CONSIGNEE_NAME
        )
        let status = translate(
            Namespace.ACE_HIGHWAY_SHIPMENT,
            AceHighwayShipmentKeys.STATUS
        )
        let customsBrokerName = translate(
            Namespace.ACE_HIGHWAY_SHIPMENT,
            AceHighwayShipmentKeys.CUSTOMS_BROKER_NAME
        )
        let brokerEntryNo = translate(
            Namespace.ACE_HIGHWAY_SHIPMENT,
            AceHighwayShipmentKeys.BROKER_ENTRY_NO
        )

        return {
            scn,
            acquittalNo,
            shipmentType,
            status,
            brokerEntryNo,
            shipperName,
            consigneeName,
            customsBrokerName,
        }
    }

    const columns = [
        {
            field: 'scn',
            title: `${translatedTextsObject.scn} / ${translatedTextsObject.shipmentType} / ${translatedTextsObject.acquittalNo}`,
            render: (data) => {
                return (
                    <Box>
                        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
                            {data.scn}
                        </Typography>
                        <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}>
                            {[
                                <Typography key='shipmentType' component='span' variant='inherit'>
                                    {data.shipmentType}
                                </Typography>,
                                data.acquittalNo && (
                                    <Typography key='acquittalNo' component='span' variant='inherit'>
                                        {data.acquittalNo}
                                    </Typography>
                                )
                            ].reduce((acc, curr, index) => {
                                return acc !== null
                                    ? curr
                                        ? [...acc, <Typography key={index} component='span' variant='inherit' style={{ margin: '0 4px' }} >&middot;</Typography>, curr]
                                        : acc
                                    : [curr]
                            }, null)}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: 'status',
            title: translatedTextsObject.status,
            render: (data) => {
                const status = getStatusMetadata(data.status)

                if (data.status == '1000' && data.shipmentType == 'REGULAR' && !data.brokerEntryNo) {
                    status.color = "#ffc400"
                    status.contrastColor = "#000000"
                }

                return (
                    <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
                        <Chip
                            label={status.label}
                            size='small'
                            style={{ color: status.contrastColor, backgroundColor: status.color, fontSize: 12 }}
                        />

                        {(data.eventLocation && data.eventLocation != manifestPort) &&
                            <Chip key='first' label={"Port Mismatch"} size='small'
                                style={{ color: '#ffffff', backgroundColor: '#DF2901', fontSize: 12 }}
                            />
                        }
                    </Box>
                )
            }
        },
        {
            field: 'brokerEntryNo',
            title: translatedTextsObject.brokerEntryNo
        },
        {
            field: 'shipperName',
            title: translatedTextsObject.shipperName
        },
        {
            field: 'consigneeName',
            title: translatedTextsObject.consigneeName
        },

        {
            field: 'customsBrokerName',
            title: translatedTextsObject.customsBrokerName
        },
    ]

    return (
        <SummaryQuickGlanceTable
            columns={columns}
            fetch={{ url: AceHighwayShipmentApiUrls.GET_SHIPMENT_QUICK_GLANCE }}
            fetchFilters={[
                { field: 'headerId', operator: EQUAL, value: manifestId }
            ]}
            sortConfig={{
                type: 'column',
                defaultField: 'brokerEntryNo',
                defaultDirection: 'ASC'
            }}
        />
    )
}

export default TablePage
