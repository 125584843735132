import { Box, Button, Grid } from "@material-ui/core";
import React from "react";
import Namespace from 'src/constants/locale/Namespace'
import DbAdminStatusConfigKeys from 'src/constants/locale/key/DbAdminStatusConfig'
import { useTranslation } from 'cng-web-lib'


function StatusConfigButtonComponent({
  onSearch,
  onUpdate,
  onClear,
  formDetailState
}) {

  const { translate } = useTranslation(Namespace.DB_ADMIN_STATUS_CONFIG)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let search = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.SEARCH_BUTTON
    )
    let update = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.UPDATE_BUTTON
    )
    let clear = translate(
      Namespace.DB_ADMIN_STATUS_CONFIG,
      DbAdminStatusConfigKeys.CLEAR_BUTTON
    )
    return {
      search,
      update,
      clear
    }
  }

  return (
    <Box pl={5} pr={5} pt={4} pb={4}>
      <Grid container xs={12} sm={12}>
        <Grid container xs={12} sm={12} justify="center">
          {formDetailState &&
            <Box pr={2}>
              <Button
                variant="contained"
                className="button-blue originalText"
                onClick={() => {
                  onSearch();
                }}
              >
                {translatedTextsObject.search}
              </Button>
            </Box>
          }
          {formDetailState &&
            <Box pr={2}>
              <Button
                variant="contained"
                className="button-blue originalText"
                onClick={() => {
                  onUpdate();
                }}
              >
                {translatedTextsObject.update}
              </Button>
            </Box>
          }
          <Box pr={2}>
            <Button
              variant="contained"
              className="button-blue originalText"
              onClick={() => {
                onClear();
              }}
            >
              {translatedTextsObject.clear}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default StatusConfigButtonComponent;
