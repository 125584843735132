import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    id: Yup.string().nullable(),
    corpid: Yup.string().nullable(),
    shipStatusCode: Yup.string().required(requiredMessage),
    shipDepDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    shipEtaDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    equipPrefix: Yup.string().max(4).required(requiredMessage),
    equipNumber: Yup.string().max(10).required(requiredMessage),
    equipStatus: Yup.string().required(requiredMessage),
    carrierCode: Yup.string().max(30).required(requiredMessage),
    locationQuali: Yup.string().nullable(),
    locationNumber: Yup.string().max(50).nullable(),
    walmartItemNum: Yup.string().max(50).nullable(),
    bol: Yup.string().max(50).nullable(),
    poNumber: Yup.string().max(50).nullable(),
    deptNumber: Yup.string().max(50).nullable(),
    merchandiseType: Yup.string().max(50).nullable(),
    distributionCenter: Yup.string().max(50).nullable(),
    messageStatus: Yup.string().max(50).required(requiredMessage),
    voyageId: Yup.string().max(10).required(requiredMessage),
    vesselCode: Yup.string().max(8).required(requiredMessage),
    vesselCodeQuali: Yup.string().required(requiredMessage),
    vesselName: Yup.string().max(28).required(requiredMessage),
    departureDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).required(requiredMessage),
    quantity: Yup.string().max(30).required(requiredMessage),
    weight: Yup.string().max(30).required(requiredMessage),
    weightQuali: Yup.string().required(requiredMessage),
    weightUom: Yup.string().required(requiredMessage),
    volume: Yup.string().max(30).required(requiredMessage),
    volumeQuali: Yup.string().required(requiredMessage),
    volumeUom: Yup.string().nullable(),
    portOfDischarge: Yup.string().max(24).required(requiredMessage),
    podCountry: Yup.string().max(3).required(requiredMessage),
    portOfLoading: Yup.string().max(24).required(requiredMessage),
    polCountry: Yup.string().max(3).required(requiredMessage),
    status: Yup.string().nullable(),
    lockedBy: Yup.string().nullable(),
    lockedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    responseDatetime: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    portN: Yup.string().nullable(),
    pnCountry: Yup.string().nullable(),
    subFileName: Yup.string().nullable(),
  })
}

export default makeValidationSchema
