import React, { useCallback, useEffect, useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme
} from '@material-ui/core'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import LocalTable from 'src/components/aciacehighway/LocalTable'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

const {
  button: { CngButton },
  CngDialog
} = components

function OverviewDetail(props) {
  const { label, value } = props

  return (
    <Box alignItems='center' display='flex' style={{ gap: 8 }}>
      <Typography component='div' variant='body2' style={{ fontWeight: 700 }}>
        {label}
      </Typography>
      <Typography
        color='primary'
        component='div'
        variant='body2'
        style={{ fontWeight: 700 }}
      >
        {value}
      </Typography>
    </Box>
  )
}

function InvoiceContainerTable(props) {
  const { invoiceContainer, translatedTextsObject } = props

  const tableRef = useRef(null)
  const lastIndex = useRef(invoiceContainer.length)
  const [addDialog, setAddDialog] = useState(false)
  const [editDialog, setEditDialog] = useState({ open: false, data: [] })
  const [deleteDialog, setDeleteDialog] = useState({ open: false, data: [] })
  const { setValue } = useFormContext()
  const theme = useTheme()

  useEffect(() => {
    const hasLocalId = invoiceContainer.some((item) =>
      Object.keys(item).includes('_id')
    )

    if (!hasLocalId) {
      const newInvoiceContainer = invoiceContainer.map((item, index) => ({
        ...item,
        _id: index + 1
      }))

      setValue('invoiceContainer', newInvoiceContainer)
    }
  }, [])

  const getTotalPackageQuantity = useCallback(() => {
    return invoiceContainer.reduce((acc, invoice) => {
      const quantity = parseInt(invoice.quantity)

      if (!quantity) return acc

      return acc + quantity
    }, 0)
  }, [invoiceContainer])

  const getTotalVolume = useCallback(() => {
    const totalVolume = invoiceContainer.reduce((acc, invoice) => {
      const volume = parseFloat(invoice.volume)

      if (!volume) return acc

      return acc + volume
    }, 0)

    return totalVolume.toFixed(2)
  }, [invoiceContainer])

  const columns = [
    {
      title: translatedTextsObject.poNumber,
      sortKey: 'poNumber',
      field: 'poNumber',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.wallMartItem,
      sortKey: 'wallMartItem',
      field: 'wallMartItem',
      filterConfig: { type: 'textfield' }
    },
    {
      title: [
        translatedTextsObject.containerNo,
        translatedTextsObject.sizeAndType
      ].join(' / '),
      sortKey: 'containerNo',
      field: 'containerNo',
      filterConfig: { type: 'textfield' },
      render: (rowData) => (
        <Box>
          <Typography component='div' variant='inherit'>
            {rowData.containerNo || '-'}
          </Typography>
          <Typography color='textSecondary' variant='caption'>
            {rowData.sizeAndType}
          </Typography>
        </Box>
      )
    },
    {
      title: [translatedTextsObject.quantity, translatedTextsObject.uom].join(
        ' & '
      ),
      field: 'quantity',
      filterConfig: { type: 'textfield' },
      render: (rowData) => (
        <Typography component='span' variant='inherit'>
          {rowData.quantity || '-'}
          <TinyChip label={rowData.uom} variant='outlined' />
        </Typography>
      )
    },
    {
      title: translatedTextsObject.volume,
      sortKey: 'volume',
      field: 'volume',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.unitPrice,
      sortKey: 'unitPrice',
      field: 'unitPrice',
      filterConfig: { type: 'textfield' },
      render: (rowData) => {
        if (!rowData.unitPrice) return

        const unitPrice = parseFloat(rowData.unitPrice)
        return unitPrice.toFixed(2)
      }
    },
    {
      title: translatedTextsObject.entValue,
      sortKey: 'entValue',
      field: 'entValue',
      filterConfig: { type: 'textfield' }
    }
  ]

  function handleAddItems(data) {
    const newItems = data.map((item) => ({ ...item, _id: ++lastIndex.current }))

    setValue('invoiceContainer', [...invoiceContainer, ...newItems])
    setAddDialog(false)
  }

  function handleEditItems(data) {
    const clonedInvoiceContainer = [...invoiceContainer]

    data.forEach((item) => {
      const index = clonedInvoiceContainer.findIndex(
        (datum) => datum._id === item._id
      )

      if (index !== -1) {
        clonedInvoiceContainer[index] = item
      }
    })

    setValue('invoiceContainer', clonedInvoiceContainer)
    setEditDialog({ open: false, data: [] })

    if (tableRef.current) {
      if (!_.isEmpty(tableRef.current.selectedRows)) {
        tableRef.current.setSelectedRows([])
      }
    }
  }

  function handleDeleteItems() {
    if (_.isEmpty(deleteDialog.data)) return

    const deletedLocalIds = deleteDialog.data.map((datum) => datum._id)
    const items = [...invoiceContainer].filter(
      (item) => !deletedLocalIds.includes(item._id)
    )
    setValue('invoiceContainer', items)
    setDeleteDialog({ open: false, data: [] })

    if (tableRef.current) {
      tableRef.current.setSelectedRows([])
    }
  }

  return (
    <>
      <Paper variant='outlined'>
        <LocalTable
          actions={[
            {
              color: 'primary',
              buttonProps: { color: 'secondary' },
              label: 'Add item',
              icon: ['fal', 'plus-circle'],
              onClick: () => setAddDialog(true)
            }
          ]}
          columns={columns}
          data={invoiceContainer}
          header={
            <Grid alignItems='stretch' container spacing={2}>
              <Grid item xs='auto'>
                <OverviewDetail
                  label='Total Line Item'
                  value={invoiceContainer.length}
                />
              </Grid>
              <Grid item xs='auto'>
                <Divider orientation='vertical' />
              </Grid>
              <Grid item xs='auto'>
                <OverviewDetail
                  label='Total Package Quantity'
                  value={getTotalPackageQuantity()}
                />
              </Grid>
              <Grid item xs='auto'>
                <Divider orientation='vertical' />
              </Grid>
              <Grid item xs='auto'>
                <OverviewDetail label='Total Volume' value={getTotalVolume()} />
              </Grid>
            </Grid>
          }
          ref={tableRef}
          rowActions={[
            {
              icon: ['fal', 'pen'],
              onClick: (rowData) =>
                setEditDialog({ open: true, data: [rowData] }),
              tooltip: 'Edit'
            },
            {
              icon: ['fal', 'trash'],
              onClick: (rowData) =>
                setDeleteDialog({ open: true, data: [rowData] }),
              tooltip: 'Delete'
            }
          ]}
          select
          selectActions={[
            {
              icon: ['fal', 'copy'],
              tooltip: `Delete`,
              label: 'Delete',
              onClick: (selectedRows) =>
                setDeleteDialog({ open: true, data: selectedRows }),
              buttonProps: {
                style: {
                  borderColor: theme.palette.error.main,
                  color: theme.palette.error.main
                }
              }
            },
            {
              icon: ['fal', 'pen'],
              tooltip: `Edit`,
              label: 'Edit',
              onClick: (selectedRows) =>
                setEditDialog({ open: true, data: selectedRows }),
              buttonProps: {
                style: {
                  color: theme.palette.primary.main
                }
              }
            }
          ]}
        />
      </Paper>
      <AddDialog
        onClose={() => setAddDialog(false)}
        onAdd={handleAddItems}
        open={addDialog}
      />
      <EditDialog
        data={editDialog.data}
        onClose={() => setEditDialog({ open: false, data: [] })}
        onEdit={handleEditItems}
        open={editDialog.open}
      />
      <CngDialog
        dialogContent={
          <Typography variant='body2'>
            Items that you delete can't be restored. Are you sure about this?
          </Typography>
        }
        dialogTitle='Delete'
        fullWidth
        maxWidth='sm'
        onClose={() => setDeleteDialog({ open: false, data: [] })}
        open={deleteDialog.open}
        dialogAction={
          <>
            <CngButton
              onClick={() => setDeleteDialog({ open: false, data: [] })}
              color='secondary'
            >
              No, take me back
            </CngButton>
            <CngButton color='primary' onClick={handleDeleteItems}>
              Yes, delete
            </CngButton>
          </>
        }
        shouldShowCloseButton
      />
    </>
  )
}

export default InvoiceContainerTable
