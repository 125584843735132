import React from 'react'
import { constants, useTranslation } from 'cng-web-lib'
import { Typography } from '@material-ui/core'
import moment from 'moment-timezone'
import Namespace from '../../../constants/locale/Namespace'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'
import EhblCloseMsgApiUrls from 'src/apiUrls/EhblCloseMsgApiUrls'
import Table from 'src/components/aciacehighway/Table'
import CngSection from 'src/components/cngcomponents/CngSection'

const { filter: { IN } } = constants

function ActivityLogs(props) {
    const { closeMsgId, showNotification } = props

    const { translate } = useTranslation(Namespace.EHBL_CLOSE_MSG)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        const activityLogs = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.ACTIVITY_LOGS_INFO.TITLE
        )
        const functionCode = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.ACTIVITY_LOGS_INFO.FUNCTION_CODE
        )
        const auditDescription = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.ACTIVITY_LOGS_INFO.AUDIT_DESC
        )
        const username = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.ACTIVITY_LOGS_INFO.USERNAME
        )
        const createdDate = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.ACTIVITY_LOGS_INFO.DATE_TIME
        )

        return {
            activityLogs,
            functionCode,
            auditDescription,
            username,
            createdDate
        }
    }

    const columns = [
        { field: 'functionCode', title: translatedTextsObject.functionCode },
        { field: 'auditDescription', title: translatedTextsObject.auditDescription },
        {
            field: 'userName',
            title: translatedTextsObject.username,
            render: (data) => (<Typography variant='body2' color='textSecondary'>{data.userName}</Typography>)
        },
        {
            field: 'createdDate',
            title: translatedTextsObject.createdDate,
            render: (data) => data.createdDate &&
                moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
        }
    ]

    return (
        <CngSection title={translatedTextsObject.activityLogs}>
            <Table columns={columns} compact
                fetch={{ url: EhblCloseMsgApiUrls.GET_AUDIT_LOGS }}
                fetchFilters={[
                    {
                        field: 'keyReferenceNo',
                        operator: IN,
                        value: `EHBL_CLOSE_MSG_${closeMsgId}`
                    }
                ]}
                showNotification={showNotification}
                variant='outlined'
                sortConfig={{
                    type: 'column',
                    defaultField: 'createdDate',
                    defaultDirection: 'DESC'
                }}
            />
        </CngSection>
    )
}

export default ActivityLogs