import StatusMessageApiUrls from 'src/apiUrls/StatusMessageApiUrls'
import FormProperties from './FormProperties'
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import StatusMessageKeys from '../../../constants/locale/key/StatusMessage'
import { useParams } from 'react-router-dom'
import pathMap from '../../../paths/pathMap'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import CountryFlagIcon from '../../../components/aciacehighway/CountryFlagIcon'
import {
  getEquipmentStatus,
  getMessageStatus,
  getShipmentStatusCode,
  getVesselCodeQualifier,
  getVolumeQualifier,
  getWeightQualifier,
  getWeightUom
} from './common'
import moment from 'moment'

const {
  button: { CngButton }
} = components

function ViewPage({ history, showNotification }) {
  const { id } = useParams()
  const { fetchRecord } = useServices()
  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)
  const { translate } = useTranslation(Namespace.STATUS_MESSAGE)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(StatusMessageApiUrls.GET, id, (res) => res.content[0]),
    ]).then(([data]) => {
      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.STATUS_MESSAGE_LIST_VIEW)
      }

      var clientData = FormProperties.toClientDataFormat(data)
      setData(clientData)
      // setLookups({ portOfLoading })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let statusMessage = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.TITLE
    )
    let id = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.ID
    )
    let messageStatus = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.MESSAGE_STATUS
    )
    let statusDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.STATUS_DATE
    )  
    let departureDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.DEPARTURE_DATE
    )
    let quantity = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.QUANTITY
    )
    let weight = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.WEIGHT
    )
    let weightUom = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.WEIGHT_UOM
    )
    let weightQuali = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.WEIGHT_QUALI
    )
    let shipStatusCode = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.SHIP_STATUS_CODE
    )
    let vesselCode = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VESSEL_CODE
    )
    let vesselName = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VESSEL_NAME
    )
    let vesselCodeQuali = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VESSEL_CODE_QUALI
    )
    let carrierCode = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.CARRIER_CODE
    )
    let voyageId = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VOYAGE_ID
    )
    let volume = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VOLUME
    )
    let volumeQuali = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VOLUME_QUALI
    )
    let equipPrefix = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.EQUIP_PREFIX
    )
    let equipNumber = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.EQUIP_NUMBER
    )
    let equipStatus = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.EQUIP_STATUS
    )
    let titleRefId = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.TITLE_REF_ID
    )
    let locationNumber = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.LOCATION_NUMBER
    )
    let walmartItemNum = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.WALMART_ITEM_NUM
    )
    let deptNumber = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.DEPT_NUMBER
    )
    let merchandiseType = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.MERCHANDISE_TYPE
    )
    let poNumber = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.PO_NUMBER
    )
    let bol = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.BOL
    )
    let titleRoutingInfo = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.TITLE_ROUTING_INFO
    )
    let portOfDischarge = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.PORT_OF_DISCHARGE
    )
    let podCountry = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.POD_COUNTRY
    )
    let portOfLoading = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.PORT_OF_LOADING
    )
    let polCountry = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.POL_COUNTRY
    )

    return {
      statusMessage,
      id,
      messageStatus,
      statusDate,
      departureDate,
      quantity,
      weight,
      weightUom,
      weightQuali,
      shipStatusCode,
      vesselCode,
      vesselName,
      vesselCodeQuali,
      carrierCode,
      voyageId,
      volume,
      volumeQuali,
      equipPrefix,
      equipNumber,
      equipStatus,
      titleRefId,
      locationNumber,
      walmartItemNum,
      deptNumber,
      merchandiseType,
      poNumber,
      bol,
      titleRoutingInfo,
      portOfDischarge,
      podCountry,
      portOfLoading,
      polCountry,
    }
  }

  if (!data) {
    return <CircularProgress />
  }

  return (
    <Paper>
      <Box padding={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  {translatedTextsObject.statusMessage}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.id}>
                      {data.id}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.messageStatus}>
                      {getMessageStatus(data.messageStatus)?.label}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.statusDate}>
                      {data.shipDepDate && moment(data.shipDepDate).format('D MMM YYYY')}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.departureDate}>
                      {data.departureDate && moment(data.departureDate).format('D MMM YYYY')}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.quantity}>
                      {data.quantity}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.weight}>
                      {data.weight && parseFloat(data.weight).toFixed(2)}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.weightUom}>
                      <Typography component='span' variant='inherit'>
                        {getWeightUom(data.weightUom)?.label}
                        <TinyChip label={data.weightUom} variant='outlined' />
                      </Typography>
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.weightQuali}>
                      <Typography component='span' variant='inherit'>
                        {getWeightQualifier(data.weightQuali)?.label}
                        <TinyChip label={data.weightQuali} variant='outlined' />
                      </Typography>
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.shipStatusCode}>
                      {getShipmentStatusCode(data.shipStatusCode)?.label}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.vesselCode}>
                      {data.vesselCode}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.vesselName}>
                      {data.vesselName}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.vesselCodeQuali}>
                      {getVesselCodeQualifier(data.vesselCodeQuali)?.label}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.carrierCode}>
                      {data.carrierCode}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.voyageId}>
                      {data.voyageId}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.volume}>
                      {data.volume && parseFloat(data.volume).toFixed(2)}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.volumeQuali}>
                      <Typography component='span' variant='inherit'>
                        {getVolumeQualifier(data.volumeQuali)?.label}
                        <TinyChip label={data.volumeQuali} variant='outlined' />
                      </Typography>
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.equipPrefix}>
                      {data.equipPrefix}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.equipNumber}>
                      {data.equipNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.equipStatus}>
                      {getEquipmentStatus(data.equipStatus)?.label}
                    </CngField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  {translatedTextsObject.titleRefId}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.locationNumber}>
                      {data.locationNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.walmartItemNum}>
                      {data.walmartItemNum}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.deptNumber}>
                      {data.deptNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.poNumber}>
                      {data.poNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.merchandiseType}>
                      {data.merchandiseType}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.bol}>
                      {data.bol}
                    </CngField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  {translatedTextsObject.titleRoutingInfo}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.portOfDischarge}>
                      {data.portOfDischarge}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.podCountry}>
                      <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                        <CountryFlagIcon countryCode={data.podCountry} size='small' />
                        <Typography component='div' variant='inherit'>
                          {data.podCountry}
                        </Typography>
                      </Box>
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.portOfLoading}>
                      {data.portOfLoading}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.polCountry}>
                      {data.polCountry}
                    </CngField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box padding={2}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs='auto'>
            <CngButton
              color='secondary'
              onClick={() => history.push(pathMap.STATUS_MESSAGE_LIST_VIEW)}
              size='medium'
            >
              Back to status message report list
            </CngButton>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default ViewPage
