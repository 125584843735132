import React,{useEffect} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import DBAdminAciAir from 'src/constants/locale/key/DBAdminAciAir'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import { useField } from "formik";
import DBAdminAciAirApiUrls from 'src/apiUrls/DBAdminAciAirApiUrls'


const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngRadioGroupField,
      CngCodeMasterAutocompleteField,
      CngStatusAutocompleteField
    }, 
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  aciairFromType: "",
  userId: "",
  ccn: "",
  newStatus: "",
  currentStatus: "",
  parn: "",
  module:"",
  arrivalFromType:"",
  checkurl:""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {

  const { translate } = useTranslation(Namespace.DBADMIN_ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()

  const [aciairFromType] = useField("aciairFromType");
  const [prevStatus] = useField("currentStatus");
  const [modulename] = useField("module");
  const [currentStatus,, { setValue: setCurrentState }] = useField("currentStatus")
  const [arrivalFromType,, {setValue: setArrivalFormtype}] = useField("arrivalFromType");
  const [arrivalDoctype,, {setValue: setAciairFormtype}] = useField("aciairFromType");

  const [checkurl,, {setValue: setUrl}] = useField("checkurl");




  let docType=aciairFromType.value;
  let module=modulename.value; 
  let currentSt=prevStatus.value;
 
  let url=""
  console.log("module",module)
  if(module==="ARRIVAL")
  {
    url = DBAdminAciAirApiUrls.CHECKSTATUS
  }
  else if(module==="ACIAIR"){
    url = DBAdminAciAirApiUrls.ARRIVALCHECKSTATUS

  }

  useEffect(() => {
    console.log("refresh")
  }, [])
  
  function setEmptyValues(event)
  {
    console.log("event",event)
    if(event!="" && event!=null)
    {
        if(event==="ACIAIR")
        {
          setArrivalFormtype("")
        }
        if(event==="ARRIVAL")
        {
          setAciairFormtype("")
        }
        setUrl(url)
        console.log(checkurl)
    }
  }

  function makeTranslatedTextsObject() {
    let dbAdminAciAir = translate(
      Namespace.DBADMIN_ACI_AIR,
      DBAdminAciAir.TITLE
    )
    let aciairFromType = translate(
      Namespace.DBADMIN_ACI_AIR,
      DBAdminAciAir.ACR_RADIO_BUTN
    )
    let userId = translate(
      Namespace.DBADMIN_ACI_AIR,
      DBAdminAciAir.USER_ID
    )
    let ccn = translate(
      Namespace.DBADMIN_ACI_AIR,
      DBAdminAciAir.CCN
    )
    let newStatus = translate(
      Namespace.DBADMIN_ACI_AIR,
      DBAdminAciAir.NEW_STATUS
    )
    let currentStatus = translate(
      Namespace.DBADMIN_ACI_AIR,
      DBAdminAciAir.CURRENT_STATUS
    )
    let parn = translate(
      Namespace.DBADMIN_ACI_AIR,
      DBAdminAciAir.PARN
    )

    let module= translate(
      Namespace.DBADMIN_ACI_AIR,
      DBAdminAciAir.MODULE
    )

    return {
      dbAdminAciAir,
      aciairFromType,
      userId,
      ccn,
      newStatus,
      currentStatus,
      parn,
      module
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.dbAdminAciAir} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.module}>
          <CngCodeMasterAutocompleteField
              name="module"
              label={translatedTextsObject.module}
              disabled={disabled}
              codeType='MODULE_NAMES'
              onChange={setEmptyValues}
            /> 
          </CngGridItem>
          {(module==="ACIAIR") &&(
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.aciairFromType}>
            <CngRadioGroupField
              name="aciairFromType"
              label={translatedTextsObject.aciairFromType}
              disabled={disabled}
              direction="row"
              options={[
                { label: 'ACR', value: '802'},
                { label: 'SSR', value: '786'},
                { label: 'SCR', value: '687'},
                { label: 'Conveynace', value: '187'}
              ]}
            />
          </CngGridItem>
          )}
         {(module==="ARRIVAL") &&(
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.aciairFromType}>
            <CngRadioGroupField
              name="arrivalFromType"
              label={translatedTextsObject.aciairFromType}
              disabled={disabled}
              direction="row"
              options={[
                { label: 'Ocean Arrival', value: 'OCEAN'},
                { label: 'Air Arrival ', value: 'AIR'}
              ]}
            />
          </CngGridItem>
         )}
        <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userId}>
            <CngTextField
              name="userId"
              label={translatedTextsObject.userId + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ccn}>
            <CngTextField
              name="ccn"
              label={translatedTextsObject.ccn + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          {(docType==="786") && (
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.parn}>
            <CngTextField
              name="parn"
              label={translatedTextsObject.parn}
              disabled={disabled}
            />
          </CngGridItem>
          )}
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.newStatus}>
            <CngCodeMasterAutocompleteField
              name="newStatus"
              label={translatedTextsObject.newStatus}
              disabled={disabled}
              codeType='DBADMIN_ACIAIR_STATUS'
            /> 
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.currentStatus}>
            <CngStatusAutocompleteField 
              name="currentStatus"
              label={translatedTextsObject.currentStatus}
              disabled={true}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
