import React from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import LocalTable from '../../../../components/aciacehighway/LocalTable'

function RoutingInfoTable(props) {
  const { data, getLookupValue } = props

  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let transportMethod = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.TRANSPORT_METHOD
    )
    let locQualifier = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.LOC_QUALIFIER
    )
    let locIdentifier = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.LOC_IDENTIFIER
    )
    let serviceLevel = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.SERVICE_LEVEL
    )
    let countryCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.COUNTRY_CODE
    )

    return {
      transportMethod,
      locQualifier,
      locIdentifier,
      serviceLevel,
      countryCode
    }
  }

  const columns = [
    {
      title: translatedTextsObject.transportMethod,
      sortKey: 'transportMethod',
      field: 'transportMethod',
      filterConfig: {
        type: 'checkbox',
        options: [
          { label: 'Air', value: 'A' },
          { label: 'Motor Bulk Carrier', value: 'MB' },
          { label: 'Containerized Ocean', value: 'O' },
          { label: 'Rail', value: 'R' }
        ]
      },
      render: (rowData) =>
        getLookupValue('transportMethod', rowData.transportMethod)
    },
    {
      title: translatedTextsObject.locQualifier,
      sortKey: 'locQualifier',
      field: 'locQualifier',
      filterConfig: {
        type: 'select',
        options: [
          { label: 'Port of Loading', value: 'KL' },
          { label: 'Destination (Shipping)', value: 'DE' },
          { label: 'Country of Origin', value: 'CA' },
          { label: 'Port of Arrival', value: 'PA' },
          { label: 'Port of Discharge', value: 'PB' },
          { label: 'Port of Entry', value: 'PE' }
        ]
      },
      render: (rowData) => getLookupValue('locQualifier', rowData.locQualifier)
    },
    {
      title: translatedTextsObject.locIdentifier,
      sortKey: 'locIdentifier',
      field: 'locIdentifier',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.serviceLevel,
      sortKey: 'serviceLevel',
      field: 'serviceLevel',
      filterConfig: {
        type: 'select',
        options: [{ label: 'Mutually Defined', value: 'ZZ' }]
      }
    },
    {
      title: translatedTextsObject.countryCode,
      sortKey: 'countryCode',
      field: 'countryCode',
      filterConfig: { type: 'textfield' }
    }
  ]

  return <LocalTable columns={columns} data={data} />
}

export default RoutingInfoTable
