import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    poId: Yup.number(),
    corpid: Yup.string().nullable(),
    partyName: Yup.string().max(60, "Max length allowed is 60.").nullable(),
    partyType: Yup.string().nullable(),
    identificationCode: Yup.string().nullable(),
    identifier: Yup.string().max(80, "Max length allowed is 80.").nullable(),
    address: Yup.string().max(110, "Max length allowed is 110.").nullable(),
    address1: Yup.string().max(110, "Max length allowed is 110.").nullable(),
    address2: Yup.string().max(110, "Max length allowed is 110.").nullable(),
    address3: Yup.string().max(110, "Max length allowed is 110.").nullable(),
    city: Yup.string().max(30, "Max length allowed is 30.").nullable(),
    state: Yup.string().max(2, "Max length allowed is 2.").nullable(),
    country: Yup.string().nullable(),
    postalCode: Yup.string().nullable(),
  })
}

export default makeValidationSchema
