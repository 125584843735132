import { Chip, Link, Tooltip, useTheme } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices

} from 'cng-web-lib'
import Box from '@material-ui/core/Box';
import DocGenPurchaseOrderApiUrls from 'src/apiUrls/DocGenPurchaseOrderApiUrls'
import pathMap from 'src/paths/pathMap'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import Namespace from 'src/constants/locale/Namespace'
import sweetalert from 'sweetalert'
import {FileForUserGetPartyId} from 'src/common/FileForUserCommon'
import moment from 'moment'
import Table from 'src/components/aciacehighway/Table'
import { getStatusMetadata } from 'src/common/NACommon';

const {
  table: {
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props
  const { createRecord } = useServices()
  const history = useHistory()
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.DOC_GEN_PURCHASE_ORDER
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const {securedSendRequest } = useServices();

  const [lookups, setLookups] = useState(null)
  const theme = useTheme()

  useEffect(() => {
    Promise.all([
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'DOC_GEN_PO_STATUS' }],
        undefined,
        'code'
      )
    ]).then(([status]) => {
      setLookups({ status })
    })
  }, [])

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

function onDownloadFile(fname){

    function onSuccess(response) {

      let blob = new Blob([response.data]),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = fname,
        disposition = response.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
              matches = filenameRegex.exec(disposition);

          if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
          }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
      } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
      }
    }

    function onError(error) {
      sweetalert("File not found.","","error")
    }

    function onComplete(){

    }
    const config = {}

    var url = DocGenPurchaseOrderApiUrls.DOWNLOAD+"?filename="+fname;
    var data = {
      responseType: "blob"
    }
    securedSendRequest.execute('POST',url, data, onSuccess, onError, onComplete,config,null);
};

  function makeTranslatedTextsObject() {
    let docGenPurchaseOrder = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: docGenPurchaseOrder
      }
    )

    let id = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ID
    )

    let corpid = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.CORPID
    )
    let poNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.PO_NUMBER
    )
    let poPurpose = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.PO_PURPOSE
    )
    let poType = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.PO_TYPE
    )
    let poDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.PO_DATE
    )
    let deptNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.DEPT_NUMBER
    )
    let orderType = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ORDER_TYPE
    )
    let eventCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.EVENT_CODE
    )
    let zbNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ZB_NUMBER
    )
    let ydNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.YD_NUMBER
    )
    let acdNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ACD_NUMBER
    )
    let fobNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.FOB_NUMBER
    )
    let buyerName = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.BUYER_NAME
    )
    let requestedShipDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.REQUESTED_SHIP_DATE
    )
    let cancelAfterDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.CANCEL_AFTER_DATE
    )
    let shipNotBeforeDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SHIP_NOT_BEFORE_DATE
    )
    let shipNoLaterDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SHIP_NO_LATER_DATE
    )
    let deliveryRequestedDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.DELIVERY_REQUESTED_DATE
    )
    let zzzDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ZZZ_DATE
    )
    let totWeight = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_WEIGHT
    )
    let totWeightUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_WEIGHT_UOM
    )
    let totVolume = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_VOLUME
    )
    let totVolumeUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_VOLUME_UOM
    )
    let matchInd = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.MATCH_IND
    )
    let invInd = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.INV_IND
    )
    let status = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.STATUS
    )
    let lockedBy = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LOCKED_BY
    )
    let lockedDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LOCKED_DATE
    )
    let responseDatetime = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RESPONSE_DATETIME
    )
    let subFileName = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SUB_FILE_NAME
    )

    let createdBy = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.CREATED_BY
    )

    let createdDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.CREATED_DATE
    )
    let createButton = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.CREATE_BUTTON
    )
    let createMultipleInvoices = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.CREATE_MULTIPLE_INVOICES
    )

    return {
      docGenPurchaseOrder,
      tableTitle,
      id,
      corpid,
      poNumber,
      poPurpose,
      poType,
      poDate,
      deptNumber,
      orderType,
      eventCode,
      zbNumber,
      ydNumber,
      acdNumber,
      fobNumber,
      buyerName,
      requestedShipDate,
      cancelAfterDate,
      shipNotBeforeDate,
      shipNoLaterDate,
      deliveryRequestedDate,
      zzzDate,
      totWeight,
      totWeightUom,
      totVolume,
      totVolumeUom,
      matchInd,
      invInd,
      status,
      lockedBy,
      lockedDate,
      responseDatetime,
      subFileName,
      createdBy,
      createdDate,
      createButton,
      createMultipleInvoices
    }
  }

  const columns = [
    {
      field: "poNumber",
      sortKey: 'poNumber',
      title: translatedTextsObject.poNumber
    },
    {
      field: 'poDate',
      title: translatedTextsObject.poDate,
      sortKey: 'poDate',
      render: (rowData) =>
        rowData.poDate &&
        moment(rowData.poDate).format('D MMM YYYY')
    },
    {
      field: 'subFileName',
      title: translatedTextsObject.subFileName,
      render: (rowData) => (
        <Tooltip title={rowData.subFileName}>
          <Box maxWidth={150}>
            <Link
              component='button'
              noWrap
              onClick={(event) => {
                event.stopPropagation()
                onDownloadFile(rowData.subFileName)
              }}
              style={{ cursor: 'pointer', width: '100%' }}
            >
              {rowData.subFileName}
            </Link>
          </Box>
        </Tooltip>
      )
    },
    {
      field: "status",
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={getLookupValue('status', data.status)}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor
            }}
          />
        )
      }
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      sortKey: 'createdDate',
      render: (rowData) =>
        rowData.createdDate &&
        moment(rowData.createdDate).format('D MMM YYYY')
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.poNumber,
      type: 'textfield',
      name: 'poNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.poDate,
      type: 'daterange',
      name: 'poDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.status,
      type: 'select',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: 'Matched',
          value: 'AC',
          filterValue: {
            value: 'AC'
          }
        },
        {
          label: 'Draft',
          value: 'DR',
          filterValue: {
            value: 'DR'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    }
  ]

  function handleCreateInvoice(data) {
    createRecord.execute(
      DocGenPurchaseOrderApiUrls.CREATE_INVOICE,
      data,
      (res) => history.push(pathMap.INVOICE_ADD_VIEW, res),
      (error) => {
        console.log(error)
      }
    )
  }

  return (
    <>
      <Table
        columns={columns}
        compact
        customRowActions={[
          {
            iconButtonProps: {
              icon: ['fal', 'file-invoice-dollar'],
              style: { color: theme.palette.primary.main }
            },
            onClick: (rowData) => handleCreateInvoice(rowData),
            tooltip: () => 'Create invoice'
          },
          {
            iconButtonProps: {
              icon: ['fal', 'arrow-to-bottom']
            },
            onClick: (rowData) => onDownloadFile(rowData.subFileName),
            tooltip: () => 'Export'
          }
        ]}
        exportData={{ url: DocGenPurchaseOrderApiUrls.EXPORT }}
        fetch={{ url: DocGenPurchaseOrderApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => history.push(`${pathname}/view/${rowData.id}`)}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
      />
    </>
  )
}

export default TablePage
