import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import StatusMessageKeys from 'src/constants/locale/key/StatusMessage'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSelectField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  corpid: "",
  id: "",
  shipStatusCode: "",
  shipDepDate: "",
  shipEtaDate: "",
  equipPrefix: "",
  equipNumber: "",
  equipStatus: "",
  carrierCode: "",
  locationQuali: "",
  locationNumber: "",
  walmartItemNum: "",
  bol: "",
  poNumber: "",
  deptNumber: "",
  merchandiseType: "",
  distributionCenter: "",
  messageStatus: "",
  voyageId: "",
  vesselCode: "",
  vesselCodeQuali: "",
  vesselName: "",
  departureDate: "",
  quantity: "",
  weight: "",
  weightQuali: "",
  weightUom: "",
  volume: "",
  volumeQuali: "",
  volumeUom: "",
  portOfDischarge: "",
  podCountry: "",
  portOfLoading: "",
  polCountry: "",
  status: "",
  lockedBy: "",
  lockedDate: "",
  responseDatetime: "",
  portN: "",
  pnCountry: "",
  subFileName: "",
  createdBy: 0,
  createdDate: "",
  updatedBy: 0,
  updatedDate: "",
  submittedBy: 0,
  submittedDate: "",
  version: 0,
  statusDate: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.STATUS_MESSAGE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let statusMessage = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.TITLE
    )
    let id = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.ID
    )
    let corpid = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.CORPID
    )
    let shipStatusCode = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.SHIP_STATUS_CODE
    )
    let shipDepDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.SHIP_DEP_DATE
    )
    let statusDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.STATUS_DATE
    )   

    let shipEtaDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.SHIP_ETA_DATE
    )
    let equipPrefix = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.EQUIP_PREFIX
    )
    let equipNumber = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.EQUIP_NUMBER
    )
    let equipStatus = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.EQUIP_STATUS
    )
    let carrierCode = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.CARRIER_CODE
    )
    let locationQuali = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.LOCATION_QUALI
    )
    let locationNumber = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.LOCATION_NUMBER
    )
    let walmartItemNum = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.WALMART_ITEM_NUM
    )
    let bol = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.BOL
    )
    let poNumber = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.PO_NUMBER
    )
    let deptNumber = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.DEPT_NUMBER
    )
    let merchandiseType = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.MERCHANDISE_TYPE
    )
    let distributionCenter = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.DISTRIBUTION_CENTER
    )
    let messageStatus = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.MESSAGE_STATUS
    )
    let voyageId = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VOYAGE_ID
    )
    let vesselCode = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VESSEL_CODE
    )
    let vesselCodeQuali = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VESSEL_CODE_QUALI
    )
    let vesselName = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VESSEL_NAME
    )
    let departureDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.DEPARTURE_DATE
    )
    let quantity = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.QUANTITY
    )
    let weight = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.WEIGHT
    )
    let weightQuali = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.WEIGHT_QUALI
    )
    let weightUom = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.WEIGHT_UOM
    )
    let volume = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VOLUME
    )
    let volumeQuali = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VOLUME_QUALI
    )
    let volumeUom = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VOLUME_UOM
    )
    let portOfDischarge = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.PORT_OF_DISCHARGE
    )
    let podCountry = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.POD_COUNTRY
    )
    let portOfLoading = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.PORT_OF_LOADING
    )
    let polCountry = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.POL_COUNTRY
    )
    let status = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.STATUS
    )
    let lockedBy = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.LOCKED_BY
    )
    let lockedDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.LOCKED_DATE
    )
    let responseDatetime = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.RESPONSE_DATETIME
    )
    let portN = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.PORT_N
    )
    let pnCountry = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.PN_COUNTRY
    )
    let subFileName = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.SUB_FILE_NAME
    )
    let createdBy = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.UPDATED_DATE
    )
    let submittedBy = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.SUBMITTED_DATE
    )
    let version = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.VERSION
    )
    let titleRoutingInfo = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.TITLE_ROUTING_INFO
    )
    let titleRefId = translate(
      Namespace.STATUS_MESSAGE,
      StatusMessageKeys.TITLE_REF_ID
    )

    return {
      statusMessage,
      id,
      corpid,
      shipStatusCode,
      shipDepDate,
      shipEtaDate,
      equipPrefix,
      equipNumber,
      equipStatus,
      carrierCode,
      locationQuali,
      locationNumber,
      walmartItemNum,
      bol,
      poNumber,
      deptNumber,
      merchandiseType,
      distributionCenter,
      messageStatus,
      voyageId,
      vesselCode,
      vesselCodeQuali,
      vesselName,
      departureDate,
      quantity,
      weight,
      weightQuali,
      weightUom,
      volume,
      volumeQuali,
      volumeUom,
      portOfDischarge,
      podCountry,
      portOfLoading,
      polCountry,
      status,
      lockedBy,
      lockedDate,
      responseDatetime,
      portN,
      pnCountry,
      subFileName,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      submittedBy,
      submittedDate,
      version,
      statusDate,
      titleRoutingInfo,
      titleRefId
    }
  }

	return (
    <Grid container spacing={3}>
      <Card>
        <CngSimpleCardHeader title={translatedTextsObject.statusMessage + " Information"} />
        <CardContent>
          <Grid container spacing={3}>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="corpid"
                label={translatedTextsObject.corpid}
                disabled={disabled}
              />
            </CngGridItem>

            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.id}>
              <CngTextField
                name="id"
                label={translatedTextsObject.id}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselCode}>
              <CngTextField
                name="vesselCode"
                inputProps={
                    {maxLength: 8}
                  }
                label={translatedTextsObject.vesselCode}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipStatusCode}>
              <CngSelectField
                    name="shipStatusCode"
                    label={translatedTextsObject.shipStatusCode} 
                    disabled={disabled}
                    items={[{text: "Vessel Departure", value: "VD"}, 
                  ]}>
              </CngSelectField>
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.departureDate}>
              <CngDateField
                name="departureDate"
                label={translatedTextsObject.departureDate}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipDepDate}>
              <CngDateField
                name="shipDepDate"
                label={translatedTextsObject.statusDate}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.quantity}>
              <CngTextField
                name="quantity"
                label={translatedTextsObject.quantity}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.equipPrefix}>
              <CngTextField
                name="equipPrefix"
                inputProps={
                    {maxLength: 4}
                  }
                label={translatedTextsObject.equipPrefix}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.weight}>
              <CngTextField
                name="weight"
                label={translatedTextsObject.weight}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.equipNumber}>
              <CngTextField
                name="equipNumber"
                inputProps={
                    {maxLength: 10}
                  }
                label={translatedTextsObject.equipNumber}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.weightQuali}>
              <CngSelectField
                    name="weightQuali"
                    label={translatedTextsObject.weightQuali} 
                    disabled={disabled}
                    items={[{text: "Gross Weight", value: "G"}, 
                  ]}>
              </CngSelectField>
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.equipStatus}>
              <CngSelectField
                    name="equipStatus"
                    label={translatedTextsObject.equipStatus} 
                    disabled={disabled}
                    items={[{text: "Loaded", value: "L"}, 
                  ]}>
              </CngSelectField>
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.weightUom}>
              <CngSelectField
                    name="weightUom"
                    label={translatedTextsObject.weightUom} 
                    disabled={disabled}
                    items={[{text: "Kilograms", value: "K"}, 
                  ]}>
              </CngSelectField>
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierCode}>
              <CngTextField
                name="carrierCode"
                inputProps={
                    {maxLength: 30}
                  }
                label={translatedTextsObject.carrierCode}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageId}>
              <CngTextField
                name="voyageId"
                inputProps={
                    {maxLength: 10}
                  }
                label={translatedTextsObject.voyageId}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngDateField
                name="status"
                label={translatedTextsObject.status}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.messageStatus}>
              <CngSelectField
                name="messageStatus"
                label={translatedTextsObject.messageStatus}
                disabled={disabled}
                items={[
                  {text: "New", value: "A"}, 
                  {text: "Update", value: "C"}, 
                  {text: "Delete", value: "D"}, 
                ]}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.volume}>
              <CngTextField
                name="volume"
                label={translatedTextsObject.volume}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselCodeQuali}>
              <CngSelectField
                    name="vesselCodeQuali"
                    label={translatedTextsObject.vesselCodeQuali} 
                    disabled={disabled}
                    items={[{text: "Lloyd's Register of Shipping", value: "L"}, 
                  ]}>
              </CngSelectField>
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.volumeQuali}>
            <CngSelectField
                    name="volumeQuali"
                    label={translatedTextsObject.volumeQuali} 
                    disabled={disabled}
                    items={[{text: "Cubic Meters", value: "X"}, 
                  ]}>
              </CngSelectField>
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselName}>
              <CngTextField
                name="vesselName"
                inputProps={
                    {maxLength: 28}
                  }
                label={translatedTextsObject.vesselName}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="volumeUom"
                label={translatedTextsObject.volumeUom}
                disabled={disabled}
              />
            </CngGridItem>
          </Grid>
        </CardContent>
        
        <CngSimpleCardHeader title={translatedTextsObject.titleRefId} />
        <CardContent>
          <Grid container spacing={3}>    
           <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="locationQuali"
                label={translatedTextsObject.locationQuali}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.locationNumber}>
              <CngTextField
                name="locationNumber"
                label={translatedTextsObject.locationNumber}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.walmartItemNum}>
              <CngTextField
                name="walmartItemNum"
                label={translatedTextsObject.walmartItemNum}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.deptNumber}>
              <CngTextField
                name="deptNumber"
                label={translatedTextsObject.deptNumber}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.poNumber}>
              <CngTextField
                name="poNumber"
                label={translatedTextsObject.poNumber}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.merchandiseType}>
              <CngTextField
                name="merchandiseType"
                label={translatedTextsObject.merchandiseType}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.bol}>
              <CngTextField
                name="bol"
                label={translatedTextsObject.bol}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={true}>
              <CngTextField
                name="distributionCenter"
                label={translatedTextsObject.distributionCenter}
                disabled={disabled}
              />
            </CngGridItem>
            
          </Grid>
        </CardContent>

        <CngSimpleCardHeader title={translatedTextsObject.titleRoutingInfo} />
        <CardContent>
          <Grid container spacing={3}>  
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfDischarge}>
              <CngTextField
                name="portOfDischarge"
                inputProps={
                    {maxLength: 24}
                  }
                label={translatedTextsObject.portOfDischarge}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.podCountry}>
              <CngTextField
                name="podCountry"
                inputProps={
                    {maxLength: 3}
                  }
                label={translatedTextsObject.podCountry}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoading}>
              <CngTextField
                name="portOfLoading"
                inputProps={
                    {maxLength: 24}
                  }
                label={translatedTextsObject.portOfLoading}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.polCountry}>
              <CngTextField
                name="polCountry"
                inputProps={
                    {maxLength: 3}
                  }
                label={translatedTextsObject.polCountry}
                disabled={disabled}
              />
            </CngGridItem>
          </Grid>
        </CardContent>            
        
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngTextField
            name="lockedBy"
            label={translatedTextsObject.lockedBy}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngDateField
            name="lockedDate"
            label={translatedTextsObject.lockedDate}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngDateField
            name="responseDatetime"
            label={translatedTextsObject.responseDatetime}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngTextField
            name="portN"
            label={translatedTextsObject.portN}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngTextField
            name="pnCountry"
            label={translatedTextsObject.pnCountry}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngTextField
            name="subFileName"
            label={translatedTextsObject.subFileName}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngTextField
            name="createdBy"
            type="number"
            label={translatedTextsObject.createdBy}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngDateField
            name="createdDate"
            label={translatedTextsObject.createdDate}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngTextField
            name="updatedBy"
            type="number"
            label={translatedTextsObject.updatedBy}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngDateField
            name="updatedDate"
            label={translatedTextsObject.updatedDate}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngTextField
            name="submittedBy"
            type="number"
            label={translatedTextsObject.submittedBy}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngDateField
            name="submittedDate"
            label={translatedTextsObject.submittedDate}
            disabled={disabled}
          />
        </CngGridItem>
        <CngGridItem xs={12} sm={6} shouldHide={true}>
          <CngTextField
            name="version"
            type="number"
            label={translatedTextsObject.version}
            disabled={disabled}
          />
        </CngGridItem>
      </Card>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.shipDepDate = DateTimeFormatter.toClientDate(
    localData.shipDepDate
  );
  localData.shipEtaDate = DateTimeFormatter.toClientDate(
    localData.shipEtaDate
  );
  localData.departureDate = DateTimeFormatter.toClientDate(
    localData.departureDate
  );
  localData.lockedDate = DateTimeFormatter.toClientDate(
    localData.lockedDate
  );
  localData.responseDatetime = DateTimeFormatter.toClientDate(
    localData.responseDatetime
  );
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  localData.submittedDate = DateTimeFormatter.toClientDate(
    localData.submittedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.shipDepDate = DateTimeFormatter.toServerDate(
    localData.shipDepDate
  );
  localData.shipEtaDate = DateTimeFormatter.toServerDate(
    localData.shipEtaDate
  );
  localData.departureDate = DateTimeFormatter.toServerDate(
    localData.departureDate
  );
  localData.lockedDate = DateTimeFormatter.toServerDate(
    localData.lockedDate
  );
  localData.responseDatetime = DateTimeFormatter.toServerDate(
    localData.responseDatetime
  );
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
