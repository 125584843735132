import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    poId: Yup.number(),
    corpid: Yup.string(),
    poLineNumber: Yup.number().max(20, "Max length allowed is 20.").required(requiredMessage),
    quoteSheetNumber: Yup.string().max(30, "Max length allowed is 30.").nullable(),
    buyerItemNumber: Yup.string().max(48, "Max length allowed is 48.").nullable(),
    upcConsumerPackageCode: Yup.string().max(48, "Max length allowed is 48.").nullable(),
    europeanArticleNumber: Yup.string().max(48, "Max length allowed is 48.").nullable(),
    vendorItemNumber: Yup.string().max(48, "Max length allowed is 48.").nullable(),
    preClassed: Yup.string().max(48, "Max length allowed is 48.").nullable(),
    caseId: Yup.string().max(48, "Max length allowed is 48.").nullable(),
    walmartInternalShipCode: Yup.string().max(48, "Max length allowed is 48.").nullable(),
    quantity: Yup.number().max(30, "Max length allowed is 30."),
    uom: Yup.string().max(2, "Max length allowed is 2.").nullable(),
    unitPrice: Yup.number().max(30, "Max length allowed is 30."),
    unitPrice1: Yup.number().max(30, "Max length allowed is 30."),
    itemDescription: Yup.string().max(80, "Max length allowed is 80.").nullable(),
    packCount: Yup.number().max(30, "Max length allowed is 30."),
    grossWeight: Yup.number().max(30, "Max length allowed is 30."),
    grossWeightUom: Yup.string().max(2, "Max length allowed is 2.").nullable(),
    volume: Yup.number().max(30, "Max length allowed is 30."),
    volumeUom: Yup.string().max(2, "Max length allowed is 2.").nullable(),
    innerPackCount: Yup.string().max(30, "Max length allowed is 30.").nullable(),
  })
}

export default makeValidationSchema
