import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let regexAlphaNumeric= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"
  let errMsgAlphaNumeric= "Allowed special characters are  ,+-$#@%&!'`^_:;.?[]{}/=\"|\\"

  return Yup.object({
    //id: Yup.string(),
    //corpid: Yup.string(),
    invoiceNumber: Yup.string().matches(regexAlphaNumeric,errMsgAlphaNumeric).max(20).required(requiredMessage),
    invoiceDate: Yup.lazy((value) => value === '' || value === null ? Yup.string().nullable() :  Yup.date().validFormat().typeError(dateTypeErrorMessage)),
    custRefNum: Yup.string().matches(regexAlphaNumeric,errMsgAlphaNumeric).max(30).required(requiredMessage),
    noOfPkgs: Yup.string().max(10).nullable().matches("^[0-9]*$", "Number of Packages must be in digits."),
    carrierCode: Yup.string().matches(regexAlphaNumeric,errMsgAlphaNumeric).min(4, "Should be a valid 4 character Carrier Code").max(4, "Should be a valid 4 character Carrier Code").required(requiredMessage).matches("^[A-Z0-9\-]{4}$", "Carrier Code must be 4 characters long and cannot contain special characters."),
    noOfContainers: Yup.string().max(10).nullable().matches("^[0-9]*$", "Number of Containers must be in digits."),
    cube: Yup.string().max(30).nullable().matches("^[0-9\.]*$", "Cube must be in digits."),
    eta: Yup.lazy((value) => value === '' || value === null ? Yup.string().nullable() :  Yup.date().validFormat().typeError(dateTypeErrorMessage)),
    portOfLoading: Yup.string().max(30).nullable(),
    billOfLading: Yup.string().matches(regexAlphaNumeric,errMsgAlphaNumeric).max(50).required(requiredMessage),
    status: Yup.string(),
    //lockedBy: Yup.string(),
    //lockedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    //responseDatetime: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    rcvdPo: Yup.string().nullable().matches(regexAlphaNumeric,errMsgAlphaNumeric).max(50),
    //createdBy: Yup.string(),
    //createdDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    //submittedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    invoiceContainer: Yup.array(),
    transaction: Yup.array().nullable(),
  })
}

export default makeValidationSchema
