import H2hSubFailsApiUrls from 'src/apiUrls/H2hSubFailsApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'

const {
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: H2hSubFailsApiUrls.POST,
            successRedirect: pathMap.H2H_SUB_FAILS_LIST_VIEW
          }}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage
