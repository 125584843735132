import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { useHistory } from 'react-router'
import moment from 'moment-timezone'
import pathMap from '../../paths/pathMap'
import Table from '../../components/dashboardWidget/Table'
import DoughnutChart from '../../components/dashboardWidget/DoughNutChart'
import { getStatusMetadata } from '../../common/NACommon'
import { Chip, Grid, Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SummaryCargoTable from './QuickGlanceTable/SummaryCargoTable'
import SummaryShipmentTable from './QuickGlanceTable/SummaryShipmentTable'

const {
  button: { CngButton },
  CngDialog,
} = components

function SummaryPage(props) {
  const history = useHistory()
  const { location: { pathname } } = props

  const aciManifestTitle = 'ACI Manifests'
  const aciManifestSortedTitle = 'ACI Manifests (Sorted by submitted date)'
  const aceManifestTitle = 'ACE Manifests'
  const aceManifestSortedTitle = 'ACE Manifests (Sorted by submitted date)'

  const [clickedChartStatus, setClickedChartStatus] = useState({})
  const [quickGlanceDialog, setQuickGlanceDialog] = useState({ open: false, data: null, isAce: null })

  function onChartElementClicked(element, title) {
    if (element && element.length) {
      const status = element[0]._view.label;

      if (title === aciManifestTitle) {
        setClickedChartStatus({ module: aciManifestSortedTitle, status: status })
      } else if (title === aceManifestTitle) {
        setClickedChartStatus({ module: aceManifestSortedTitle, status: status })
      }
    }
  }

  const aceManifestsTableCols = [
    {
      field: 'tripNum',
      filterType: 'string',
      title: 'Trip No.',
      render: (data) => {
        var status = data.status
        var recordId = data.manifestID
        var viewUrl = `/ace-highway/manifest/view/`+ recordId
        var editUrl = `/ace-highway/manifest/edit/`+ recordId

        return (
          <div style={{ 'text-decoration': 'underline', cursor: 'pointer' }}
            onClick={() => { status === "ST" ? history.push(viewUrl) : history.push(editUrl) }}>
            {data.tripNum}
            {data.reminderNote &&
              <Tooltip key='more' title={data.reminderNote} placement='top'>
                <Chip
                  icon={<FontAwesomeIcon fixedWidth icon={['fas', 'flag']} style={{ color: 'red' }} />}
                  size='small'
                  style={{ fontSize: 12, backgroundColor: 'transparent' }}
                />
              </Tooltip>
            }
          </div>
        )
      }
    },
    {
      field: 'status',
      filterType: 'checkbox',
      filterOptions: [
        { label: 'Accepted', value: 'AC' },
        { label: 'Rejected', value: 'RJ' },
        { label: 'Cancel Accepted', value: 'CL' },
        { label: 'Cancel Rejected', value: 'CR' },
        { label: 'Draft', value: 'DR' },
        { label: 'Status Notification', value: 'SN' },
        { label: 'Modification Rejected', value: 'MR' },
        { label: 'Sent', value: 'ST' },
        { label: 'Preliminary', value: 'PL' }
      ],
      title: 'Status',
      render: (data) => {
        const statusIndicator = data.statusIndicator ? data.statusIndicator : data.status
        const status = getStatusMetadata(statusIndicator)

        return (
          <Tooltip key='more' title='Quick glance at Shipment(s)' placement='top'>
            <Chip
              label={status.label}
              size='small'
              style={{ backgroundColor: status.color, color: status.contrastColor }}
              onClick={() => setQuickGlanceDialog({ open: true, data: data, isAce: true })}
            />
          </Tooltip>
        )
      }
    },
    {
      field: 'eta',
      title: 'ETA',
      render: (data) => data.eta && moment(data.eta).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'driverName',
      filterType: 'string',
      title: 'Driver'
    },
    {
      field: 'truck',
      filterType: 'string',
      title: 'Truck'
    },
    {
      field: 'trailer',
      filterType: 'string',
      title: 'Trailer'
    },
    {
      field: 'port',
      filterType: 'string',
      title: 'Port'
    }
  ]

  const aciManifestsTableCols = [
    {
      field: 'tripNum',
      filterType: 'string',
      title: 'Trip No.',
      render: (data) => {
        var status = data.status
        var recordId = data.manifestID
        var viewUrl = `/aci-highway/manifest/view/`+ recordId
        var editUrl = `/aci-highway/manifest/edit/`+ recordId

        return (
          <div style={{ 'text-decoration': 'underline', cursor: 'pointer' }}
            onClick={() => { status === "ST" ? history.push(viewUrl) : history.push(editUrl) }}>
            {data.tripNum}
            {data.reminderNote &&
              <Tooltip key='more' title={data.reminderNote} placement='top'>
                <Chip
                  icon={<FontAwesomeIcon fixedWidth icon={['fas', 'flag']} style={{ color: 'red' }} />}
                  size='small'
                  style={{ fontSize: 12, backgroundColor: 'transparent' }}
                />
              </Tooltip>
            }
          </div>
        )
      }
    },
    {
      field: 'status',
      filterType: 'checkbox',
      filterOptions: [
        { label: 'Accepted', value: 'AC' },
        { label: 'Rejected', value: 'RJ' },
        { label: 'Cancel Accepted', value: 'CL' },
        { label: 'Cancel Rejected', value: 'CR' },
        { label: 'Draft', value: 'DR' },
        { label: 'SN', value: 'SN' },
        { label: 'SN-Truck Arrived', value: 'SN-TA' },
        { label: 'SN-Goods Released', value: 'SN-GR' },
        { label: 'Modification Rejected', value: 'MR' },
        { label: 'Sent', value: 'ST' },
        { label: 'Arrived', value: 'AR' }
      ],
      title: 'Status',
      render: (data) => {
        const statusIndicator = data.statusIndicator ? data.statusIndicator : data.status
        const status = getStatusMetadata(statusIndicator)

        return (
          <Tooltip key='more' title='Quick glance at Cargo(s)' placement='top'>
            <Chip
              label={status.label}
              size='small'
              style={{ backgroundColor: status.color, color: status.contrastColor }}
              onClick={() => setQuickGlanceDialog({ open: true, data: data, isAce: false })}
            />
          </Tooltip>
        )
      }
    },
    {
      field: 'eta',
      title: 'ETA',
      render: (data) => data.eta && moment(data.eta).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'driverName',
      filterType: 'string',
      title: 'Driver'
    },
    {
      field: 'truck',
      filterType: 'string',
      title: 'Truck'
    },
    {
      field: 'trailer',
      filterType: 'string',
      title: 'Trailer'
    },
    {
      field: 'port',
      filterType: 'string',
      title: 'Port'
    }
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <DoughnutChart
              title={aceManifestTitle}
              endpoint={`${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}` + '/ace-highway/dashboard/all-transaction-status'}
              onChartElementClicked={onChartElementClicked}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Table
              columns={aceManifestsTableCols}
              title={aceManifestSortedTitle}
              onClickActionButton={() =>
                history.push(pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW, { fetchAll: true })
              }
              endpoint={`${process.env.REACT_APP_USER_ACE_HIGHWAY_URL}` + '/ace-highway/dashboard/manifest-by-submitted-date'}
              clickedChartStatus={clickedChartStatus}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <DoughnutChart
              title={aciManifestTitle}
              endpoint={`${process.env.REACT_APP_USER_ACIHW_URL}` + '/aci-highway/dashboard/all-transaction-status'}
              onChartElementClicked={onChartElementClicked}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Table
              columns={aciManifestsTableCols}
              title={aciManifestSortedTitle}
              onClickActionButton={() =>
                history.push(pathMap.ACIHWY_MANIFEST_LIST_VIEW, { fetchAll: true })
              }
              endpoint={`${process.env.REACT_APP_USER_ACIHW_URL}` + '/aci-highway/dashboard/manifest-by-submitted-date'}
              clickedChartStatus={clickedChartStatus}
            />
          </Grid>
        </Grid>
      </Grid>

      <QuickGlanceDialog
        data={quickGlanceDialog.data}
        onClose={() => setQuickGlanceDialog({ open: false, data: null, isAce: quickGlanceDialog.isAce })}
        open={quickGlanceDialog.open}
        isAce={quickGlanceDialog.isAce}
        history={history}
      />
    </Grid>
  )
}

export default SummaryPage


function QuickGlanceDialog(props) {
  const { data, open, onClose, isAce, history } = props
  const [redirectUrl, setRedirectUrl] = useState()

  const title = isAce ? 'Quick Glance at Shipment(s)' : 'Quick Glance at Cargo(s)'
  const redirectData = { manifestData: { ...data }, step: "2" }

  useEffect(() => {
    if (!data) return

    let endpoint = data.status == 'ST' ? '/manifest/view/' : '/manifest/edit/'
    if (isAce) {
      endpoint = '/ace-highway' + endpoint + data.manifestID

    } else {
      endpoint = '/aci-highway' + endpoint + data.manifestID
    }
    setRedirectUrl(endpoint)
  }, [data])

  function getDialogAction() {
    let buttonLabel = isAce ? 'View All Attached Shipments' : 'View All Attached Cargos'

    return data && data.status != 'ST' ?
      <CngButton onClick={() => history.push(redirectUrl, redirectData)} color='secondary'>
        {buttonLabel}
      </CngButton>
      : null
  }

  let content = data ? (
    <Grid>
      {isAce &&
        <SummaryShipmentTable manifestId={data.manifestID} manifestPort={data.port} />
      }

      {!isAce &&
        <SummaryCargoTable manifestId={data.manifestID} />
      }
    </Grid>
  ) : null

  return (
    <CngDialog
      dialogTitle={title}
      dialogContent={content}
      dialogAction={getDialogAction(data)}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}