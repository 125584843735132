import {  Grid } from '@material-ui/core'
import React, { useState , useEffect} from 'react'
import { components, useTranslation, constants } from 'cng-web-lib'
import DBAddPage from './DBAddPage'
import Namespace from 'src/constants/locale/Namespace'
import SSRKeys from 'src/constants/locale/key/DBAdminAciAir'
import { useLocation } from "react-router-dom";


const { CngTabs } = components

function AddPage({ history, showNotification}) {
 
    const location = useLocation();
    const pathname=location.pathname;

  const { translate } = useTranslation([
    Namespace.DBADMIN_ACI_AIR,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
   
    return {
      
    }
  }

  function showDBAdminForm() { 
      return (
        <DBAddPage
          history={history}
          showNotification={showNotification}
        />
      )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: "DB Admin",
              tabContent: showDBAdminForm()
            },
          ]}
        />
      </Grid>
    </Grid>
  )

}

export default AddPage
