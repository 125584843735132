import React, { useEffect, useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import { Grid, Typography } from '@material-ui/core'
import _ from 'lodash'
import Namespace from '../../../constants/locale/Namespace'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CngField from '../../../components/cngcomponents/CngField'
import ResponseList from './ResponseList'
import ActivityLogs from './ActivityLogs'

const { table: { useFetchCodeMaintenanceLookup } } = components
const {
    filter: { EQUAL },
    CodeMaintenanceType
} = constants

function ViewContent(props) {
    const { data } = props

    const { translate } = useTranslation(Namespace.EHBL_CLOSE_MSG)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let title = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.TITLE
        )

        let previousCcn = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.PREVIOUS_CCN
        )

        let primaryCcn = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.PRIMARY_CCN
        )

        let carrierCode = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.CARRIER_CODE
        )

        let closeMessageNumber = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.CLOSE_MESSAGE_NUMBER
        )

        let messageFunctionCode = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.MESSAGE_FUNCTION_CODE
        )

        let amendmentCode = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.AMENDMENT_CODE
        )

        let businessName = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.BUSINESS_NAME
        )

        let relatedCcnList = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.RELATED_CCN_LIST
        )

        let relatedCcn = translate(
            Namespace.EHBL_CLOSE_MSG,
            EhblCloseMsgKeys.RELATED_CCN
        )

        return {
            title,
            previousCcn,
            primaryCcn,
            carrierCode,
            closeMessageNumber,
            messageFunctionCode,
            amendmentCode,
            businessName,
            relatedCcnList,
            relatedCcn
        }
    }

    const [lookups, setLookups] = useState(null)
    const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

    useEffect(() => {
        Promise.all([
            fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
                { field: 'codeType', operator: EQUAL, value: 'EHBL_CLOSE_MSG_MSG_FUNC' }
            ]), fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
                { field: 'codeType', operator: EQUAL, value: 'EHBL_CLOSE_MSG_AMENDMENT_CODE' }
            ])
        ]).then(([msgFuncCode, amendmentCode]) => {
            setLookups({ msgFuncCode, amendmentCode })
        })
    }, [])

    function getLookupValue(name, value) {
        if (!lookups) return value
        return lookups[name] && lookups[name][value] ? lookups[name][value] : value
    }

    function getLookupSection(value, lookupValue) {
        if (value) {
            return (
                <Typography component='div' variant='inherit'>
                    {getLookupValue(lookupValue, value)}
                    <TinyChip label={value} variant='outlined' />
                </Typography>
            )
        }
        return <Typography component='div' variant='inherit'>-</Typography>
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <StatusBar status={data.status} />
            </Grid>
            <Grid item xs={12}>
                <CngSection title={translatedTextsObject.title}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.previousCcn}>{data.prevCcn}</CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.primaryCcn}>{data.primaryCcn}</CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.carrierCode}>{data.carrierCode}</CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.closeMessageNumber}>{data.closemsgNumber}</CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.messageFunctionCode}>
                                {getLookupSection(data.msgFuncCode, 'msgFuncCode')}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.amendmentCode}>
                                {getLookupSection(data.amendmentCode, 'amendmentCode')}
                            </CngField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <CngField label={translatedTextsObject.businessName}>{data.businessName}</CngField>
                        </Grid>
                    </Grid>
                </CngSection>
            </Grid>
            {!_.isEmpty(data.closemsgRelccn) && (
                <Grid item xs={12}>
                    <CngSubSection title={translatedTextsObject.relatedCcnList}>
                        <Grid container spacing={2}>
                            {data.closemsgRelccn.map((relCcn, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={12}>
                                        <CngField label={translatedTextsObject.relatedCcn}>{relCcn.relatedCcn}</CngField>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </CngSubSection>
                </Grid>
            )}
            <Grid item xs={12}>
                <ResponseList closeMsgId={data.id} />
            </Grid>
            <Grid item xs={12}>
                <ActivityLogs closeMsgId={data.id} />
            </Grid>

        </Grid>
    )
}

export default ViewContent