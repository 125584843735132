import makeValidationSchema from './InvoiceContainerMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import InvoiceKeys from 'src/constants/locale/key/Invoice'
import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngTextField, CngSelectField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  invId: '',
  corpid: '',
  poNumber: '',
  wallMartItem: '',
  containerNo: '',
  sizeAndType: '40',
  quantity: '',
  uom: '',
  volume: '',
  unitPrice: '',
  entValue: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  index,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let invoiceContainer = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.TITLE
    )
    let invId = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.INV_ID
    )
    let corpid = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.CORPID
    )
    let poNumber = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.PO_NUMBER
    )
    let wallMartItem = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.WALL_MART_ITEM
    )
    let containerNo = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.CONTAINER_NO
    )
    let sizeAndType = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.SIZE_AND_TYPE
    )
    let quantity = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.QUANTITY
    )
    let uom = translate(Namespace.INVOICE, InvoiceKeys.InvoiceContainer.UOM)
    let volume = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.VOLUME
    )
    let unitPrice = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.UNIT_PRICE
    )
    let entValue = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.ENT_VALUE
    )

    return {
      invoiceContainer,
      invId,
      corpid,
      poNumber,
      wallMartItem,
      containerNo,
      sizeAndType,
      quantity,
      uom,
      volume,
      unitPrice,
      entValue
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.poNumber}>
        <CngTextField
          required
          name={typeof index === 'number' ? `invoiceContainer.${index}.poNumber` : 'poNumber'}
          label={translatedTextsObject.poNumber}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.wallMartItem}>
        <CngTextField
          required
          name={typeof index === 'number' ? `invoiceContainer.${index}.wallMartItem` : 'wallMartItem'}
          label={translatedTextsObject.wallMartItem}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.containerNo}>
        <CngTextField
          required
          name={typeof index === 'number' ? `invoiceContainer.${index}.containerNo` : 'containerNo'}
          label={translatedTextsObject.containerNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.sizeAndType}>
        <CngTextField
          name={typeof index === 'number' ? `invoiceContainer.${index}.sizeAndType` : 'sizeAndType'}
          label={translatedTextsObject.sizeAndType}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.quantity}>
        <CngTextField
          name={typeof index === 'number' ? `invoiceContainer.${index}.quantity` : 'quantity'}
          label={translatedTextsObject.quantity}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.uom}>
        <CngSelectField
          required
          name={typeof index === 'number' ? `invoiceContainer.${index}.uom` : 'uom'}
          label={translatedTextsObject.uom}
          disabled={disabled}
          items={[
            { text: 'Case', value: 'CA' },
            { text: 'Kilogram', value: 'KG' },
            { text: 'Cubic Meter', value: 'CR' },
            { text: 'Each', value: 'EA' },
            { text: 'Cubic Feet', value: 'CF' },
            { text: 'Cartons', value: 'CT' },
            { text: 'Pallet', value: 'PL' }
          ]}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.volume}>
        <CngTextField
          required
          name={typeof index === 'number' ? `invoiceContainer.${index}.volume` : 'volume'}
          label={translatedTextsObject.volume}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.unitPrice}>
        <CngTextField
          name={typeof index === 'number' ? `invoiceContainer.${index}.unitPrice` : 'unitPrice'}
          label={translatedTextsObject.unitPrice}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} md={6} lg={4} shouldHide={shouldHideMap?.entValue}>
        <CngTextField
          name={typeof index === 'number' ? `invoiceContainer.${index}.entValue` : 'entValue'}
          label={translatedTextsObject.entValue}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
    </Grid>
  )
}

const InvoiceContainerFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default InvoiceContainerFormProperties
