import AddPage from './AddPage.js'
import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import useBreadcrumbNameMap from 'src/useBreadcrumbNameMap'



function WrappedAddPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <AddPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}



export {
  WrappedAddPage as AddPage
}
