import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import { Grid, Card, CardContent } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
  constants,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import RoutingInfoFormProperties from './RoutingInfoFormProperties'
import TradePartyFormProperties from './TradePartyFormProperties'
import LineItemFormProperties from './LineItemFormProperties'
import SublineFormProperties from './SublineFormProperties'
import SublineAllowanceFormProperties from './SublineAllowanceFormProperties'
import ScheduleFormProperties from './ScheduleFormProperties'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSelectField,
    },
  },
  table: {
    CngLocalModeDialogFormTable,
    useFetchCodeMaintenanceLookup
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  corpid: "",
  poNumber: "",
  poPurpose: "",
  poType: "",
  poDate: "",
  deptNumber: "",
  orderType: "",
  eventCode: "",
  zbNumber: "",
  ydNumber: "",
  acdNumber: "",
  fobNumber: "",
  buyerName: "",
  requestedShipDate: "",
  cancelAfterDate: "",
  shipNotBeforeDate: "",
  shipNoLaterDate: "",
  deliveryRequestedDate: "",
  zzzDate: "",
  totWeight: "",
  totWeightUom: "",
  totVolume: "",
  totVolumeUom: "",
  matchInd: "",
  invInd: "",
  status: "",
  lockedBy: "",
  lockedDate: "",
  responseDatetime: "",
  subFileName: "",
  routingInfo: [],
  tradeParty: [],
  lineItem: [],
  subline: [],
  sublineAllowance: [],
  schedule: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter:{EQUAL}
} = constants

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  function makeTranslatedTextsObject() {
    let docGenPurchaseOrder = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TITLE
    )
    let dateTimeRef = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TITLE_DATE_TIME_REF
    )
    let refId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TITLE_REF_ID
    )

    let corpid = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.CORPID
    )
    let poNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.PO_NUMBER
    )
    let poPurpose = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.PO_PURPOSE
    )
    let poType = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.PO_TYPE
    )
    let poDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.PO_DATE
    )
    let deptNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.DEPT_NUMBER
    )
    let orderType = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ORDER_TYPE
    )
    let eventCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.EVENT_CODE
    )
    let zbNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ZB_NUMBER
    )
    let ydNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.YD_NUMBER
    )
    let acdNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ACD_NUMBER
    )
    let fobNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.FOB_NUMBER
    )
    let buyerName = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.BUYER_NAME
    )
    let requestedShipDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.REQUESTED_SHIP_DATE
    )
    let cancelAfterDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.CANCEL_AFTER_DATE
    )
    let shipNotBeforeDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SHIP_NOT_BEFORE_DATE
    )
    let shipNoLaterDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SHIP_NO_LATER_DATE
    )
    let deliveryRequestedDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.DELIVERY_REQUESTED_DATE
    )
    let zzzDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ZZZ_DATE
    )
    let totWeight = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_WEIGHT
    )
    let totWeightUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_WEIGHT_UOM
    )
    let totVolume = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_VOLUME
    )
    let totVolumeUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_VOLUME_UOM
    )
    let matchInd = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.MATCH_IND
    )
    let invInd = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.INV_IND
    )
    let status = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.STATUS
    )
    let lockedBy = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LOCKED_BY
    )
    let lockedDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LOCKED_DATE
    )
    let responseDatetime = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RESPONSE_DATETIME
    )
    let subFileName = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SUB_FILE_NAME
    )
    let routingInfo = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.TITLE
    )
    let poId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.PO_ID
    )
    let transportMethod = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.TRANSPORT_METHOD
    )
    let locQualifier = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.LOC_QUALIFIER
    )
    let locIdentifier = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.LOC_IDENTIFIER
    )
    let serviceLevel = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.SERVICE_LEVEL
    )
    let countryCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.COUNTRY_CODE
    )
    let tradeParty = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.TITLE
    )
    let partyName = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.PARTY_NAME
    )
    let partyType = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.PARTY_TYPE
    )
    let identificationCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.IDENTIFICATION_CODE
    )
    let identifier = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.IDENTIFIER
    )
    let address = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.ADDRESS
    )
    let address1 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.ADDRESS1
    )
    let address2 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.ADDRESS2
    )
    let address3 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.ADDRESS3
    )
    let city = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.CITY
    )
    let state = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.STATE
    )
    let country = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.COUNTRY
    )
    let postalCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.POSTAL_CODE
    )
    let lineItem = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.TITLE
    )
    let poLineNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.PO_LINE_NUMBER
    )
    let quoteSheetNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.QUOTE_SHEET_NUMBER
    )
    let buyerItemNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.BUYER_ITEM_NUMBER
    )
    let upcConsumerPackageCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.UPC_CONSUMER_PACKAGE_CODE
    )
    let europeanArticleNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.EUROPEAN_ARTICLE_NUMBER
    )
    let vendorItemNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.VENDOR_ITEM_NUMBER
    )
    let preClassed = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.PRE_CLASSED
    )
    let caseId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.CASE_ID
    )
    let walmartInternalShipCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.WALMART_INTERNAL_SHIP_CODE
    )
    let quantity = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.QUANTITY
    )
    let uom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.UOM
    )
    let unitPrice = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.UNIT_PRICE
    )
    let unitPrice1 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.UNIT_PRICE1
    )
    let itemDescription = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.ITEM_DESCRIPTION
    )
    let packCount = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.PACK_COUNT
    )
    let grossWeight = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.GROSS_WEIGHT
    )
    let grossWeightUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.GROSS_WEIGHT_UOM
    )
    let volume = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.VOLUME
    )
    let volumeUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.VOLUME_UOM
    )
    let innerPackCount = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.INNER_PACK_COUNT
    )
    let subline = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.TITLE
    )
    let _lineItemId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.LINE_ITEM
    )
    let itemId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.ITEM_ID
    )
    let itemCorpid = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.ITEM_CORPID
    )
    let tariffNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.TARIFF_NUMBER
    )
    let countryOrigin = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.COUNTRY_ORIGIN
    )
    let commodityGroupingCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.COMMODITY_GROUPING_CODE
    )
    let usCustomsBindingRule = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.US_CUSTOMS_BINDING_RULE
    )
    let quotaCategory = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY
    )
    let quotaCategory1 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY1
    )
    let quotaCategory2 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY2
    )
    let quotaCategory3 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY3
    )
    let quotaCategory4 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY4
    )
    let quotaCategory5 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY5
    )
    let message = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.MESSAGE
    )
    let sublineAllowance = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.TITLE
    )
    let _sublineId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.SUBLINE
    )
    let sublineItemId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.SUBLINE_ITEM_ID
    )
    let sublineCorpid = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.SUBLINE_CORPID
    )
    let chargeCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_CODE
    )
    let chargePercent = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_PERCENT
    )
    let chargeCost = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_COST
    )
    let chargeUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_UOM
    )
    let chargeQuantity = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_QUANTITY
    )
    let chargeMethodDesc = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.CHARGE_METHOD_DESC
    )
    let schedule = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.TITLE
    )
    let scheduleItemId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_ITEM_ID
    )
    let scheduleCorpid = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_CORPID
    )
    let scheduleQuantity = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_QUANTITY
    )
    let scheduleQuantityUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_QUANTITY_UOM
    )
    let distributionPoNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.DISTRIBUTION_PO_NUMBER
    )
    let distributionCenter = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.DISTRIBUTION_CENTER
    )
    let scheduleDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_DATE
    )
    let id = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ID
    )

    return {
      docGenPurchaseOrder,
      dateTimeRef,
      refId,
      corpid,
      poNumber,
      poPurpose,
      poType,
      poDate,
      deptNumber,
      orderType,
      eventCode,
      zbNumber,
      ydNumber,
      acdNumber,
      fobNumber,
      buyerName,
      requestedShipDate,
      cancelAfterDate,
      shipNotBeforeDate,
      shipNoLaterDate,
      deliveryRequestedDate,
      zzzDate,
      totWeight,
      totWeightUom,
      totVolume,
      totVolumeUom,
      matchInd,
      invInd,
      status,
      lockedBy,
      lockedDate,
      responseDatetime,
      subFileName,
      routingInfo,
      poId,
      transportMethod,
      locQualifier,
      locIdentifier,
      serviceLevel,
      countryCode,
      tradeParty,
      partyName,
      partyType,
      identificationCode,
      identifier,
      address,
      address1,
      address2,
      address3,
      city,
      state,
      country,
      postalCode,
      lineItem,
      poLineNumber,
      quoteSheetNumber,
      buyerItemNumber,
      upcConsumerPackageCode,
      europeanArticleNumber,
      vendorItemNumber,
      preClassed,
      caseId,
      walmartInternalShipCode,
      quantity,
      uom,
      unitPrice,
      unitPrice1,
      itemDescription,
      packCount,
      grossWeight,
      grossWeightUom,
      volume,
      volumeUom,
      innerPackCount,
      subline,
      _lineItemId,
      itemId,
      itemCorpid,
      tariffNumber,
      countryOrigin,
      commodityGroupingCode,
      usCustomsBindingRule,
      quotaCategory,
      quotaCategory1,
      quotaCategory2,
      quotaCategory3,
      quotaCategory4,
      quotaCategory5,
      message,
      sublineAllowance,
      _sublineId,
      sublineItemId,
      sublineCorpid,
      chargeCode,
      chargePercent,
      chargeCost,
      chargeUom,
      chargeQuantity,
      chargeMethodDesc,
      schedule,
      scheduleItemId,
      scheduleCorpid,
      scheduleQuantity,
      scheduleQuantityUom,
      distributionPoNumber,
      distributionCenter,
      scheduleDate,
      id
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.docGenPurchaseOrder} />
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="corpid"
                  label={translatedTextsObject.corpid}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="poPurpose"
                  label={translatedTextsObject.poPurpose}
                  disabled={disabled}
                />
              </CngGridItem>

              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.id}>
                <CngTextField
                  name="id"
                  label={translatedTextsObject.id}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totWeight}>
                <CngTextField
                  name="totWeight"
                  label={translatedTextsObject.totWeight}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.poNumber}>
                <CngTextField
                  name="poNumber"
                  label={translatedTextsObject.poNumber}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totWeightUom}>
                <CngTextField
                  name="totWeightUom"
                  label={translatedTextsObject.totWeightUom}
                  disabled={disabled}
                />
              </CngGridItem>
             
              
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.poType}>
                  <CngSelectField
                    name="poType"
                    label={translatedTextsObject.poType} 
                    disabled={disabled}
                    items={[{text: "Blanket Order", value: "BE"}, 
                  ]}>
                </CngSelectField>
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totVolume}>
                <CngTextField
                  name="totVolume"
                  label={translatedTextsObject.totVolume}
                  disabled={disabled}
                />
              </CngGridItem>
             
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.poDate}>
                <CngDateField
                  name="poDate"
                  label={translatedTextsObject.poDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totVolumeUom}>
                <CngTextField
                  name="totVolumeUom"
                  label={translatedTextsObject.totVolumeUom}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.buyerName}>
                <CngTextField
                  name="buyerName"
                  label={translatedTextsObject.buyerName}
                  disabled={disabled}
                />
              </CngGridItem>
             
            </Grid>
            </CardContent>
              <CardContent>
              <CngSimpleCardHeader title={translatedTextsObject.dateTimeRef} /> 
              <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.requestedShipDate}>
                <CngDateField
                  name="requestedShipDate"
                  label={translatedTextsObject.requestedShipDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cancelAfterDate}>
                <CngDateField
                  name="cancelAfterDate"
                  label={translatedTextsObject.cancelAfterDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipNotBeforeDate}>
                <CngDateField
                  name="shipNotBeforeDate"
                  label={translatedTextsObject.shipNotBeforeDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipNoLaterDate}>
                <CngDateField
                  name="shipNoLaterDate"
                  label={translatedTextsObject.shipNoLaterDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.deliveryRequestedDate}>
                <CngDateField
                  name="deliveryRequestedDate"
                  label={translatedTextsObject.deliveryRequestedDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.zzzDate}>
                <CngDateField
                  name="zzzDate"
                  label={translatedTextsObject.zzzDate}
                  disabled={disabled}
                />
              </CngGridItem>
              

              </Grid>
            </CardContent>
              <CardContent>
              <CngSimpleCardHeader title={translatedTextsObject.refId} /> 
              <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.deptNumber}>
                <CngTextField
                  name="deptNumber"
                  label={translatedTextsObject.deptNumber}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.orderType}>
                <CngTextField
                  name="orderType"
                  label={translatedTextsObject.orderType}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eventCode}>
                <CngTextField
                  name="eventCode"
                  label={translatedTextsObject.eventCode}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.zbNumber}>
                <CngTextField
                  name="zbNumber"
                  label={translatedTextsObject.zbNumber}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ydNumber}>
                <CngTextField
                  name="ydNumber"
                  label={translatedTextsObject.ydNumber}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.acdNumber}>
                <CngTextField
                  name="acdNumber"
                  label={translatedTextsObject.acdNumber}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fobNumber}>
                <CngTextField
                  name="fobNumber"
                  label={translatedTextsObject.fobNumber}
                  disabled={disabled}
                />
              </CngGridItem>
              
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="matchInd"
                  label={translatedTextsObject.matchInd}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="invInd"
                  label={translatedTextsObject.invInd}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="status"
                  label={translatedTextsObject.status}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="lockedBy"
                  label={translatedTextsObject.lockedBy}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="lockedDate"
                  label={translatedTextsObject.lockedDate}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngDateField
                  name="responseDatetime"
                  label={translatedTextsObject.responseDatetime}
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={true}>
                <CngTextField
                  name="subFileName"
                  label={translatedTextsObject.subFileName}
                  disabled={disabled}
                />
              </CngGridItem>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.routingInfo} />
          <CardContent>
            <CngLocalModeDialogFormTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              disabled={disabled}
              showNotification={showNotification}
              propertyName="routingInfo"
              tableColumns={[
                /*{
                  title: translatedTextsObject.poId,
                  field: "poId",
                  type: "numeric",
                },
                {
                  title: translatedTextsObject.corpid,
                  field: "corpid",
                },*/
                {
                  title: translatedTextsObject.transportMethod,
                  field: "transportMethod",
                  customLookup: () => {
                    return fetchCodeMaintenanceLookup(
                      constants.CodeMaintenanceType.CODE_MASTER,
                    undefined,
                    [{field: 'codeType', operator: EQUAL, value: 'DOC_GEN_TRANSPORT_TYPE'}],
                    undefined,
                    'code'
                    )
                  }
                },
                {
                  title: translatedTextsObject.locQualifier,
                  field: "locQualifier",
                  customLookup: () => {
                    return fetchCodeMaintenanceLookup(
                      constants.CodeMaintenanceType.CODE_MASTER,
                    undefined,
                    [{field: 'codeType', operator: EQUAL, value: 'DOC_GEN_LOC_QUALIFIER'}],
                    undefined,
                    'code'
                    )
                  }
                },
                {
                  title: translatedTextsObject.locIdentifier,
                  field: "locIdentifier",
                },
                {
                  title: translatedTextsObject.serviceLevel,
                  field: "serviceLevel",
                  lookup: {
                    ZZ:"Mutually Defined"
                  }
                },
                {
                  title: translatedTextsObject.countryCode,
                  field: "countryCode",
                },
              ]}
              formProperties={RoutingInfoFormProperties}
              initialData={DEFAULT_INITIAL_VALUES.routingInfo}
              isViewOnly={disabled}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.tradeParty} />
          <CardContent>
            <CngLocalModeDialogFormTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              disabled={disabled}
              showNotification={showNotification}
              propertyName="tradeParty"
              tableColumns={[
                /*{
                  title: translatedTextsObject.poId,
                  field: "poId",
                  type: "numeric",
                },
                {
                  title: translatedTextsObject.corpid,
                  field: "corpid",
                },*/
                {
                  title: translatedTextsObject.partyType,
                  field: "partyType",
                  customLookup: () => {
                    return fetchCodeMaintenanceLookup(
                      constants.CodeMaintenanceType.CODE_MASTER,
                    undefined,
                    [{field: 'codeType', operator: EQUAL, value: 'DOC_GEN_PARTY_TYPE'}],
                    undefined,
                    'code'
                    )
                  }
                },
                {
                  title: translatedTextsObject.partyName,
                  field: "partyName",
                },
              
                /*{
                  title: translatedTextsObject.identificationCode,
                  field: "identificationCode",
                  customLookup: () => {
                    return fetchCodeMaintenanceLookup(
                      constants.CodeMaintenanceType.CODE_MASTER,
                    undefined,
                    [{field: 'codeType', operator: EQUAL, value: 'DOC_GEN_PARTY_ID_CODE'}],
                    undefined,
                    'code'
                    )
                  }
                },
                {
                  title: translatedTextsObject.identifier,
                  field: "identifier",
                },*/
                {
                  title: translatedTextsObject.address,
                  field: "address",
                },
               /* {
                  title: translatedTextsObject.address1,
                  field: "address1",
                },
                {
                  title: translatedTextsObject.address2,
                  field: "address2",
                },
                {
                  title: translatedTextsObject.address3,
                  field: "address3",
                },
                {
                  title: translatedTextsObject.city,
                  field: "city",
                },
                {
                  title: translatedTextsObject.state,
                  field: "state",
                },*/
                {
                  title: translatedTextsObject.country,
                  field: "country",
                  customLookup: () => {
                    return fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.COUNTRY)
                  }
                },
                /*{
                  title: translatedTextsObject.postalCode,
                  field: "postalCode",
                },*/
              ]}
              formProperties={TradePartyFormProperties}
              initialData={DEFAULT_INITIAL_VALUES.tradeParty}
              isViewOnly={disabled}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.lineItem} />
          <CardContent>
            <CngLocalModeDialogFormTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              disabled={disabled}
              showNotification={showNotification}
              propertyName="lineItem"
              tableColumns={[
                /*{
                  title: translatedTextsObject.poId,
                  field: "poId",
                  type: "numeric",
                },
                {
                  title: translatedTextsObject.corpid,
                  field: "corpid",
                },*/
                {
                  title: translatedTextsObject.poLineNumber,
                  field: "poLineNumber",
                },
                {
                  title: translatedTextsObject.quoteSheetNumber,
                  field: "quoteSheetNumber",
                },
                /*{
                  title: translatedTextsObject.buyerItemNumber,
                  field: "buyerItemNumber",
                },*/
                {
                  title: translatedTextsObject.itemDescription,
                  field: "itemDescription",
                },
                /*{
                  title: translatedTextsObject.upcConsumerPackageCode,
                  field: "upcConsumerPackageCode",
                },
                {
                  title: translatedTextsObject.europeanArticleNumber,
                  field: "europeanArticleNumber",
                },
                {
                  title: translatedTextsObject.vendorItemNumber,
                  field: "vendorItemNumber",
                },
                {
                  title: translatedTextsObject.preClassed,
                  field: "preClassed",
                },
                {
                  title: translatedTextsObject.caseId,
                  field: "caseId",
                },
                {
                  title: translatedTextsObject.walmartInternalShipCode,
                  field: "walmartInternalShipCode",
                },*/
                {
                  title: translatedTextsObject.quantity,
                  field: "quantity",
                },
                /*{
                  title: translatedTextsObject.uom,
                  field: "uom",
                },*/
                {
                  title: translatedTextsObject.unitPrice,
                  field: "unitPrice",
                },
                /*{
                  title: translatedTextsObject.unitPrice1,
                  field: "unitPrice1",
                },
               
                {
                  title: translatedTextsObject.packCount,
                  field: "packCount",
                },*/
                {
                  title: translatedTextsObject.grossWeight,
                  field: "grossWeight",
                },
                /*{
                  title: translatedTextsObject.grossWeightUom,
                  field: "grossWeightUom",
                },*/
                {
                  title: translatedTextsObject.volume,
                  field: "volume",
                },
                /*{
                  title: translatedTextsObject.volumeUom,
                  field: "volumeUom",
                },
                {
                  title: translatedTextsObject.innerPackCount,
                  field: "innerPackCount",
                },*/
              ]}
              formProperties={LineItemFormProperties}
              initialData={DEFAULT_INITIAL_VALUES.lineItem}
              isViewOnly={disabled}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              idGenerated={true}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.schedule} />
          <CardContent>
            <CngLocalModeDialogFormTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              disabled={disabled}
              showNotification={showNotification}
              propertyName="schedule"
              tableColumns={[
                {
                  title: translatedTextsObject._lineItemId,
                  field: "_lineItemId",
                  type: "numeric",
                },
                {
                  title: translatedTextsObject.scheduleQuantity,
                  field: "scheduleQuantity",
                },
                {
                  title: translatedTextsObject.scheduleQuantityUom,
                  field: "scheduleQuantityUom",
                },
                {
                  title: translatedTextsObject.distributionPoNumber,
                  field: "distributionPoNumber",
                },
                {
                  title: translatedTextsObject.distributionCenter,
                  field: "distributionCenter",
                },
                {
                  title: translatedTextsObject.scheduleDate,
                  field: "scheduleDate",
                },
              ]}
              formProperties={ScheduleFormProperties}
              initialData={DEFAULT_INITIAL_VALUES.schedule}
              isViewOnly={disabled}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              parent={{
                propertyName: "lineItem",
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.subline} />
          <CardContent>
            <CngLocalModeDialogFormTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              disabled={disabled}
              showNotification={showNotification}
              propertyName="subline"
              tableColumns={[
                {
                  title: translatedTextsObject._lineItemId,
                  field: "_lineItemId",
                  type: "numeric",
                },
                {
                  title: translatedTextsObject.tariffNumber,
                  field: "tariffNumber",
                },
                {
                  title: translatedTextsObject.countryOrigin,
                  field: "countryOrigin",
                  customLookup: () => {
                    return fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.COUNTRY)
                  }
                },
                {
                  title: translatedTextsObject.commodityGroupingCode,
                  field: "commodityGroupingCode",
                },
                {
                  title: translatedTextsObject.usCustomsBindingRule,
                  field: "usCustomsBindingRule",
                },
                {
                  title: translatedTextsObject.quotaCategory,
                  field: "quotaCategory",
                },
                {
                  title: translatedTextsObject.message,
                  field: "message",
                },
              ]}
              formProperties={SublineFormProperties}
              initialData={DEFAULT_INITIAL_VALUES.subline}
              isViewOnly={disabled}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              idGenerated={true}
              parent={{
                propertyName: "lineItem",
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.sublineAllowance} />
          <CardContent>
            <CngLocalModeDialogFormTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              disabled={disabled}
              showNotification={showNotification}
              propertyName="sublineAllowance"
              tableColumns={[
                {
                  title: translatedTextsObject._sublineId,
                  field: "_sublineId",
                  type: "numeric",
                },
                {
                  title: translatedTextsObject.chargeCode,
                  field: "chargeCode",
                },
                {
                  title: translatedTextsObject.chargePercent,
                  field: "chargePercent",
                },
                {
                  title: translatedTextsObject.chargeCost,
                  field: "chargeCost",
                },
                {
                  title: translatedTextsObject.chargeUom,
                  field: "chargeUom",
                },
                {
                  title: translatedTextsObject.chargeQuantity,
                  field: "chargeQuantity",
                },
                {
                  title: translatedTextsObject.chargeMethodDesc,
                  field: "chargeMethodDesc",
                },
              ]}
              formProperties={SublineAllowanceFormProperties}
              initialData={DEFAULT_INITIAL_VALUES.sublineAllowance}
              isViewOnly={disabled}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              parent={{
                propertyName: "subline",
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.poDate = DateTimeFormatter.toClientDate(
    localData.poDate
  );
  localData.requestedShipDate = DateTimeFormatter.toClientDate(
    localData.requestedShipDate
  );
  localData.cancelAfterDate = DateTimeFormatter.toClientDate(
    localData.cancelAfterDate
  );
  localData.shipNotBeforeDate = DateTimeFormatter.toClientDate(
    localData.shipNotBeforeDate
  );
  localData.shipNoLaterDate = DateTimeFormatter.toClientDate(
    localData.shipNoLaterDate
  );
  localData.deliveryRequestedDate = DateTimeFormatter.toClientDate(
    localData.deliveryRequestedDate
  );
  localData.zzzDate = DateTimeFormatter.toClientDate(
    localData.zzzDate
  );
  localData.lockedDate = DateTimeFormatter.toClientDate(
    localData.lockedDate
  );
  localData.responseDatetime = DateTimeFormatter.toClientDate(
    localData.responseDatetime
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.poDate = DateTimeFormatter.toServerDate(
    localData.poDate
  );
  localData.requestedShipDate = DateTimeFormatter.toServerDate(
    localData.requestedShipDate
  );
  localData.cancelAfterDate = DateTimeFormatter.toServerDate(
    localData.cancelAfterDate
  );
  localData.shipNotBeforeDate = DateTimeFormatter.toServerDate(
    localData.shipNotBeforeDate
  );
  localData.shipNoLaterDate = DateTimeFormatter.toServerDate(
    localData.shipNoLaterDate
  );
  localData.deliveryRequestedDate = DateTimeFormatter.toServerDate(
    localData.deliveryRequestedDate
  );
  localData.zzzDate = DateTimeFormatter.toServerDate(
    localData.zzzDate
  );
  localData.lockedDate = DateTimeFormatter.toServerDate(
    localData.lockedDate
  );
  localData.responseDatetime = DateTimeFormatter.toServerDate(
    localData.responseDatetime
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
