import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    aciairFromType: Yup.string().required(requiredMessage).nullable(),
    userId: Yup.string().required(requiredMessage).nullable(),
    ccn: Yup.string().required(requiredMessage).nullable(),
    parn: Yup.string().nullable(),
    newStatus: Yup.string().nullable(),
    currentStatus: Yup.string().nullable(),
    arrivalFromType: Yup.string().nullable(),
    module: Yup.string().required(requiredMessage).nullable()
  })
}

export default makeValidationSchema
