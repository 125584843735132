import makeValidationSchema from './RoutingInfoMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  poId: 0,
  corpid: "",
  transportMethod: "",
  locQualifier: "",
  locIdentifier: "",
  serviceLevel: "",
  countryCode: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let routingInfo = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.TITLE
    )
    let poId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.PO_ID
    )
    let corpid = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.CORPID
    )
    let transportMethod = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.TRANSPORT_METHOD
    )
    let locQualifier = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.LOC_QUALIFIER
    )
    let locIdentifier = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.LOC_IDENTIFIER
    )
    let serviceLevel = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.SERVICE_LEVEL
    )
    let countryCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.COUNTRY_CODE
    )

    return {
      routingInfo,
      poId,
      corpid,
      transportMethod,
      locQualifier,
      locIdentifier,
      serviceLevel,
      countryCode
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.routingInfo} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="poId"
              type="number"
              label={translatedTextsObject.poId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="corpid"
              label={translatedTextsObject.corpid}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.transportMethod}>
            <CngCodeMasterAutocompleteField 
                  name="transportMethod" 
                  label={translatedTextsObject.transportMethod} 
                  disabled={disabled}
                  codeType='DOC_GEN_TRANSPORT_TYPE'  
              />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.locQualifier}>
            <CngCodeMasterAutocompleteField 
                  name="locQualifier" 
                  label={translatedTextsObject.locQualifier} 
                  disabled={disabled}
                  codeType='DOC_GEN_LOC_QUALIFIER'  
              />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.locIdentifier}>
            <CngTextField
              name="locIdentifier"
              label={translatedTextsObject.locIdentifier}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.serviceLevel}>
              <CngSelectField
                  name="serviceLevel"
                  label={translatedTextsObject.serviceLevel} 
                  disabled={disabled}
                  items={[{text: "Mutually Defined", value: "ZZ"}, 
                ]} />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.countryCode}>
            <CngTextField
              name="countryCode"
              label={translatedTextsObject.countryCode}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const RoutingInfoFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default RoutingInfoFormProperties
