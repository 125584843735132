import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import SummaryPage from './SummaryPage.js'
import useBreadcrumbNameMap from 'src/useBreadcrumbNameMap'

function WrappedSummaryPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <SummaryPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedSummaryPage as SummaryPage
}
