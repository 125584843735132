import makeValidationSchema from './SublineMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      FormikDropdownField: CngDropdownField,
      CngTextField,
      CngSelectField,
      CngCountryAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _lineItemId: 0,
  itemId: 0,
  itemCorpid: "",
  tariffNumber: "",
  countryOrigin: "",
  commodityGroupingCode: "",
  usCustomsBindingRule: "",
  quotaCategory: "",
  quotaCategory1: "",
  quotaCategory2: "",
  quotaCategory3: "",
  quotaCategory4: "",
  quotaCategory5: "",
  message: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let subline = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.TITLE
    )
    let _lineItemId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.LINE_ITEM
    )
    let itemId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.ITEM_ID
    )
    let itemCorpid = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.ITEM_CORPID
    )
    let tariffNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.TARIFF_NUMBER
    )
    let countryOrigin = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.COUNTRY_ORIGIN
    )
    let commodityGroupingCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.COMMODITY_GROUPING_CODE
    )
    let usCustomsBindingRule = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.US_CUSTOMS_BINDING_RULE
    )
    let quotaCategory = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY
    )
    let quotaCategory1 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY1
    )
    let quotaCategory2 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY2
    )
    let quotaCategory3 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY3
    )
    let quotaCategory4 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY4
    )
    let quotaCategory5 = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.QUOTA_CATEGORY5
    )
    let message = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.MESSAGE
    )

    return {
      subline,
      _lineItemId,
      itemId,
      itemCorpid,
      tariffNumber,
      countryOrigin,
      commodityGroupingCode,
      usCustomsBindingRule,
      quotaCategory,
      quotaCategory1,
      quotaCategory2,
      quotaCategory3,
      quotaCategory4,
      quotaCategory5,
      message
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.subline} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap._lineItemId}>
            <CngSelectField
              name="_lineItemId"
              label={translatedTextsObject._lineItemId}
              items={parentIdDropdownItems}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="itemId"
              type="number"
              label={translatedTextsObject.itemId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="itemCorpid"
              label={translatedTextsObject.itemCorpid}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tariffNumber}>
            <CngTextField
              name="tariffNumber"
              label={translatedTextsObject.tariffNumber}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.countryOrigin}>
            <CngCountryAutocompleteField
              name="countryOrigin"
              label={translatedTextsObject.countryOrigin}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.commodityGroupingCode}>
            <CngTextField
              name="commodityGroupingCode"
              label={translatedTextsObject.commodityGroupingCode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.usCustomsBindingRule}>
            <CngTextField
              name="usCustomsBindingRule"
              label={translatedTextsObject.usCustomsBindingRule}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.quotaCategory}>
            <CngTextField
              name="quotaCategory"
              label={translatedTextsObject.quotaCategory}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.quotaCategory1}>
            <CngTextField
              name="quotaCategory1"
              label={translatedTextsObject.quotaCategory1}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.quotaCategory2}>
            <CngTextField
              name="quotaCategory2"
              label={translatedTextsObject.quotaCategory2}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.quotaCategory3}>
            <CngTextField
              name="quotaCategory3"
              label={translatedTextsObject.quotaCategory3}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.quotaCategory4}>
            <CngTextField
              name="quotaCategory4"
              label={translatedTextsObject.quotaCategory4}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.quotaCategory5}>
            <CngTextField
              name="quotaCategory5"
              label={translatedTextsObject.quotaCategory5}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.message}>
            <CngTextField
              name="message"
              label={translatedTextsObject.message}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const SublineFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default SublineFormProperties
