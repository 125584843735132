import DocGenPurchaseOrderApiUrls from 'src/apiUrls/DocGenPurchaseOrderApiUrls'
import FormProperties from './FormProperties'
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import { useParams } from 'react-router-dom'
import StatusBar from 'src/components/aciacehighway/StatusBar'
import pathMap from 'src/paths/pathMap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CngField from 'src/components/cngcomponents/CngField'
import moment from 'moment'
import PurchaseOrderDetailsTables from './PurchaseOrderDetailsTables'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function ViewPage({ history, showNotification }) {
  const { id } = useParams()
  const { createRecord, fetchRecord, securedSendRequest } = useServices()
  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(
        DocGenPurchaseOrderApiUrls.GET,
        id,
        (res) => res.content[0]
      ),
      // Transport type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'DOC_GEN_TRANSPORT_TYPE'
          }
        ],
        undefined,
        'code'
      ),
      // Location qualifier
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          { field: 'codeType', operator: EQUAL, value: 'DOC_GEN_LOC_QUALIFIER' }
        ],
        undefined,
        'code'
      ),
      // Party ID code
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          { field: 'codeType', operator: EQUAL, value: 'DOC_GEN_PARTY_ID_CODE' }
        ],
        undefined,
        'code'
      ),
      // Party type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'DOC_GEN_PARTY_TYPE' }],
        undefined,
        'code'
      ),
      // Country
      fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.COUNTRY)
    ]).then(
      ([
        data,
        transportMethod,
        locQualifier,
        partyIdCode,
        partyType,
        country
      ]) => {
        if (data === undefined) {
          showNotification('error', 'Data not found.')
          history.push(pathMap.DOC_GEN_PURCHASE_ORDER_LIST_VIEW)
        }

        var clientData = FormProperties.toClientDataFormat(data)
        setData(clientData)
        setLookups({
          transportMethod,
          locQualifier,
          partyIdCode,
          partyType,
          country
        })
      }
    )
  }, [])

  if (!data) {
    return <CircularProgress />
  }

  function makeTranslatedTextsObject() {
    let docGenPurchaseOrder = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TITLE
    )
    let dateTimeRef = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TITLE_DATE_TIME_REF
    )
    let refId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TITLE_REF_ID
    )
    let id = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ID
    )
    let poNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.PO_NUMBER
    )
    let poType = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.PO_TYPE
    )
    let poDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.PO_DATE
    )
    let totWeight = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_WEIGHT
    )
    let totWeightUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_WEIGHT_UOM
    )
    let totVolume = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_VOLUME
    )
    let totVolumeUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TOT_VOLUME_UOM
    )
    let buyerName = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.BUYER_NAME
    )
    let requestedShipDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.REQUESTED_SHIP_DATE
    )
    let cancelAfterDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.CANCEL_AFTER_DATE
    )
    let shipNotBeforeDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SHIP_NOT_BEFORE_DATE
    )
    let shipNoLaterDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SHIP_NO_LATER_DATE
    )
    let deliveryRequestedDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.DELIVERY_REQUESTED_DATE
    )
    let zzzDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ZZZ_DATE
    )
    let deptNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.DEPT_NUMBER
    )
    let orderType = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ORDER_TYPE
    )
    let eventCode = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.EVENT_CODE
    )
    let fobNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.FOB_NUMBER
    )
    let zbNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ZB_NUMBER
    )
    let ydNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.YD_NUMBER
    )
    let acdNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.ACD_NUMBER
    )

    return {
      docGenPurchaseOrder,
      dateTimeRef,
      refId,
      id,
      poNumber,
      poType,
      poDate,
      totWeight,
      totWeightUom,
      totVolume,
      totVolumeUom,
      buyerName,
      requestedShipDate,
      cancelAfterDate,
      shipNotBeforeDate,
      shipNoLaterDate,
      deliveryRequestedDate,
      zzzDate,
      deptNumber,
      orderType,
      eventCode,
      fobNumber,
      zbNumber,
      ydNumber,
      acdNumber
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function handleCreateInvoice(data) {
    createRecord.execute(
      DocGenPurchaseOrderApiUrls.CREATE_INVOICE,
      data,
      (res) => history.push(pathMap.INVOICE_ADD_VIEW, res),
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDownloadFile(fname) {
    securedSendRequest.execute(
      'POST',
      DocGenPurchaseOrderApiUrls.DOWNLOAD + '?filename=' + fname,
      { responseType: 'blob' },
      (response) => {
        let blob = new Blob([response.data]),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = fname,
          disposition = response.headers['content-disposition']

        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
            matches = filenameRegex.exec(disposition)

          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        let a = document.createElement('a')
        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl
        } else {
          a.href = downloadUrl
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
      },
      (error) => showNotification('error', 'File not found.')
    )
  }

  return (
    <Paper variant='outlined'>
      <Box padding={1}>
        <StatusBar status={data.status} />
      </Box>
      <Box padding={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  {translatedTextsObject.docGenPurchaseOrder}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.poNumber}>
                      {data.poNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.poType}>
                      {data.poType}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.poDate}>
                      {data.poDate && moment(data.poDate).format('D MMM YYYY')}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.id}>
                      {data.id}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField
                      label={[
                        translatedTextsObject.totWeight,
                        translatedTextsObject.totWeightUom
                      ].join(' & ')}
                    >
                      {[
                        parseFloat(data.totWeight).toFixed(2),
                        data.totWeightUom
                      ].join(', ')}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField
                      label={[
                        translatedTextsObject.totVolume,
                        translatedTextsObject.totVolumeUom
                      ].join(' & ')}
                    >
                      {[
                        parseFloat(data.totVolume).toFixed(2),
                        data.totVolumeUom
                      ].join(', ')}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.buyerName}>
                      {data.buyerName}
                    </CngField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  {translatedTextsObject.dateTimeRef}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.requestedShipDate}>
                      {data.requestedShipDate &&
                        moment(data.requestedShipDate).format(
                          'D MMM YYYY, HH:mm'
                        )}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.cancelAfterDate}>
                      {data.cancelAfterDate &&
                        moment(data.cancelAfterDate).format(
                          'D MMM YYYY, HH:mm'
                        )}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.shipNotBeforeDate}>
                      {data.shipNotBeforeDate &&
                        moment(data.shipNotBeforeDate).format(
                          'D MMM YYYY, HH:mm'
                        )}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.shipNoLaterDate}>
                      {data.shipNoLaterDate &&
                        moment(data.shipNoLaterDate).format(
                          'D MMM YYYY, HH:mm'
                        )}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField
                      label={translatedTextsObject.deliveryRequestedDate}
                    >
                      {data.deliveryRequestedDate &&
                        moment(data.deliveryRequestedDate).format(
                          'D MMM YYYY, HH:mm'
                        )}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.zzzDate}>
                      {data.zzzDate &&
                        moment(data.zzzDate).format('D MMM YYYY, HH:mm')}
                    </CngField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  {translatedTextsObject.refId}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.deptNumber}>
                      {data.deptNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.orderType}>
                      {data.orderType}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.eventCode}>
                      {data.eventCode}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.fobNumber}>
                      {data.fobNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.zbNumber}>
                      {data.zbNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.ydNumber}>
                      {data.ydNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.acdNumber}>
                      {data.acdNumber}
                    </CngField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PurchaseOrderDetailsTables
              purchaseOrder={data}
              lookups={lookups}
              getLookupValue={getLookupValue}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box padding={2}>
        <Grid container justify='space-between' spacing={2}>
          <Grid item xs='auto'>
            <CngButton
              color='secondary'
              size='medium'
              onClick={() =>
                history.push(pathMap.DOC_GEN_PURCHASE_ORDER_LIST_VIEW)
              }
            >
              Back to purchase order report list
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  size='medium'
                  variant='outlined'
                  onClick={() => handleDownloadFile(data.subFileName)}
                  startIcon={
                    <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />
                  }
                >
                  Export
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  color='primary'
                  size='medium'
                  variant='outlined'
                  onClick={() => handleCreateInvoice(data)}
                  startIcon={
                    <FontAwesomeIcon icon={['fal', 'file-invoice-dollar']} />
                  }
                >
                  Create invoice
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default ViewPage
