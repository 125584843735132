import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'

const {
    locale: { key: { CommonValidationMessageKeys, } }
} = constants

function MakeValidationSchema(translate) {
    let requiredMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.REQUIRED
    )

    const errMsgAlphaNumericWithSpecialChar = translate(
        Namespace.EHBL_CLOSE_MSG,
        EhblCloseMsgKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR
    )
     
    const errMsgAlphaNumericWithHypen = translate(
        Namespace.EHBL_CLOSE_MSG,
        EhblCloseMsgKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_HYPEN
    )

    const errMsgMaxLength = translate(
        Namespace.EHBL_CLOSE_MSG,
        EhblCloseMsgKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
    )

    const regexAlphaNumericWithHypen = '^[a-zA-Z0-9-]*$'
    const regexAlphaNumericWithSpecialChar = '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'

    return Yup.object({
        prevCcn: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar,errMsgAlphaNumericWithSpecialChar).max(25, errMsgMaxLength + " 25"),
        primaryCcn: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar,errMsgAlphaNumericWithSpecialChar).max(25, errMsgMaxLength + " 25"),
        carrierCode: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithHypen,errMsgAlphaNumericWithHypen).max(4, errMsgMaxLength + " 4"),
        msgFuncCode: Yup.string().nullable().required(requiredMessage),
        businessName: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar,errMsgAlphaNumericWithSpecialChar).max(25, errMsgMaxLength + " 25")
    })
}

export default MakeValidationSchema