import InvoiceApiUrls from 'src/apiUrls/InvoiceApiUrls'
import React from "react";
import {
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'
import InvoiceKeys from 'src/constants/locale/key/Invoice'
import Namespace from 'src/constants/locale/Namespace';
import moment from 'moment'
import sweetalert from 'sweetalert'
import StatusMessageApiUrls from 'src/apiUrls/StatusMessageApiUrls'
import DocGenPurchaseOrderApiUrls from 'src/apiUrls/DocGenPurchaseOrderApiUrls'
import Table from '../../../components/aciacehighway/Table'
import { Box, Link, Tooltip } from '@material-ui/core';

const {
  filter: { EQUAL }
} = constants

function AcitivityLogs(props){
  const { id, showNotification } = props

  const { translate } = useTranslation([Namespace.INVOICE])
  const translatedTextsObject = makeTranslatedTextsObject()
  const {securedSendRequest } = useServices();
  
  function onDownloadFile(fname, source) {

    let url = DocGenPurchaseOrderApiUrls.DOWNLOAD;
    if(source == "PO Created"||source == "PO Updated"){
      url = DocGenPurchaseOrderApiUrls.DOWNLOAD;
    }else if(source == "315 Created"||source == "315 Updated"){
      url = StatusMessageApiUrls.DOWNLOAD;
    }

    function onSuccess(response) {
      let blob = new Blob([response.data]),
        downloadUrl = window.URL.createObjectURL(blob),
        filename = fname,
        disposition = response.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      let a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    }

    function onError(error) {
      sweetalert("File not found.", "", "error");
    }

    function onComplete() {
    }
    var data = {
      responseType: "blob"
    }
    const config = {}
    securedSendRequest.execute('POST', url + "?filename=" + fname, data, onSuccess, onError, onComplete, config, null);
  }

  function makeTranslatedTextsObject() {
    let title = translate(
      Namespace.INVOICE,
      InvoiceKeys.ACTIVITY_LOGS
    )

    let createdDate = translate(
      Namespace.INVOICE,
      InvoiceKeys.DATE_TIME
    )
  
    let loginUserId = translate(
      Namespace.INVOICE,
      InvoiceKeys.LOGIN_USER_ID
    )

    let actionUserId = translate(
      Namespace.INVOICE,
      InvoiceKeys.ACTION_USER_ID
    )
    
    let functionCode = translate(
      Namespace.INVOICE,
      InvoiceKeys.FUNCTION_CODE
    )

    let auditDescription = translate(
      Namespace.INVOICE,
      InvoiceKeys.AUDIT_DESC
    )

    let mode = translate(
      Namespace.INVOICE,
      InvoiceKeys.MODE
    )

    let fileName = translate(
      Namespace.INVOICE,
      InvoiceKeys.FILE_NAME
    )

    return {
      title,
      createdDate,
      loginUserId,
      actionUserId,
      functionCode,
      auditDescription,
      mode,
      fileName
    }
  }

  const columns = [
    {
      field: 'functionCode',
      title: translatedTextsObject.functionCode
    },
    {
      field: 'fileName',
      title: translatedTextsObject.fileName,
      render: (rowData) => (
        <Tooltip title={rowData.receivedFileName}>
          <Box maxWidth={150}>
            <Link
              component='div'
              noWrap
              onClick={() =>
                onDownloadFile(
                  rowData.auditDescription.split('||')[1],
                  rowData.functionCode
                )
              }
              style={{ cursor: 'pointer', width: '100%' }}
            >
              {rowData.auditDescription.split('||')[1]}
            </Link>
          </Box>
        </Tooltip>
      )
    },
    {
      field: 'auditDescription',
      title: translatedTextsObject.auditDescription,
      render: (rowData) => rowData.auditDescription.split('||')[0]
    },
    {
      field: 'userId',
      title: translatedTextsObject.loginUserId
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate &&
        moment(rowData.createdDate).format('D MMM YYYY, HH:mm')
    }
  ]

  return (
    <Table
      columns={columns}
      compact
      fetch={{ url: InvoiceApiUrls.GET_AUDIT_LOGS }}
      fetchFilters={[
        {
          field: 'keyReferenceNo',
          operator: EQUAL,
          value: 'DOC_GEN_INV_' + id
        }
      ]}
      showNotification={showNotification}
    />
  )
}
export default AcitivityLogs;
