import React, { useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import { Box, Card, Grid } from '@material-ui/core'
import pathMap from '../../../paths/pathMap'
import EhblCloseMsgApiUrls from '../../../apiUrls/EhblCloseMsgApiUrls'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import FormProperties from './FormProperties'

const { initialValues } = FormProperties.formikProps

const { button: { CngButton }, form: { CngAddForm } } = components

function AddPage(props) {
    const { history, showNotification } = props

    const onSuccessCallback = useRef(() =>
        history.push(pathMap.EHBL_CLOSE_MSG_LIST_VIEW)
    )

    function handleSaveAndContinue() {
        onSuccessCallback.current = (e) => {
            history.push(`${pathMap.EHBL_CLOSE_MSG_PATH}/edit/${e.id}`)
        }
    }

    return (
        <Card>
            <Box padding={2}>
                <CngAddForm
                    fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                    history={history}
                    showNotification={showNotification}
                    renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
                        <FormProperties.Fields
                            showNotification={showNotification}
                            shouldHideMap={shouldHideMap}
                            autoPopulateBusinessName
                        />
                    )}
                    formikProps={{
                        ...FormProperties.formikProps,
                        initialValues: history.location.state ?? initialValues
                    }}
                    toClientDataFormat={FormProperties.toClientDataFormat}
                    toServerDataFormat={FormProperties.toServerDataFormat}
                    create={{
                        url: EhblCloseMsgApiUrls.POST,
                        onSuccess: (e) => {
                            showNotification('success', 'Close Message created.')
                            onSuccessCallback.current(e)
                        }
                    }}
                    renderButtonSection={(loading) => (
                        <ButtonSection
                            history={history}
                            loading={loading}
                            onSaveAndContinue={handleSaveAndContinue}
                        />
                    )}
                />
            </Box>
        </Card>
    )
}

function ButtonSection(props) {
    const { history, loading, onSaveAndContinue } = props
    const [confirmDialog, setConfirmDialog] = useState(false)

    return (
        <>
            <Grid container justify='space-between' spacing={3}>
                <Grid item xs='auto'>
                    <CngButton color='secondary' disabled={loading} onClick={() => setConfirmDialog(true)} size='medium'>Discard</CngButton>
                </Grid>
                <Grid item xs='auto'>
                    <Grid container spacing={2}>
                        <Grid item xs='auto'>
                            <CngButton color='primary' disabled={loading} type='submit' onClick={onSaveAndContinue} size='medium'>Save</CngButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AlertDialog
                cancelLabel='Continue editing'
                confirmLabel='Yes, discard'
                open={confirmDialog}
                onClose={() => setConfirmDialog(false)}
                onCancel={() => setConfirmDialog(false)}
                onConfirm={() => history.push(pathMap.EHBL_CLOSE_MSG_LIST_VIEW)}
                title='Discard'
            >
                All progress in this session will be lost and cant't be restored. Are
                you sure about this?
            </AlertDialog>
        </>
    )
}

export default AddPage