import InvoiceApiUrls from 'src/apiUrls/InvoiceApiUrls'
import NaCodeMaintenanceApiUrls from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import FormProperties from './FormProperties'
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import InvoiceKeys from 'src/constants/locale/key/Invoice'
import Namespace from 'src/constants/locale/Namespace'
import ActivityLogs from './InvoiceActivityLogs'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import CngField from '../../../components/cngcomponents/CngField'
import CngSection from '../../../components/cngcomponents/CngSection'
import pathMap from '../../../paths/pathMap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton }
} = components

const {
  filter: { EQUAL }
} = constants

function OverviewDetail(props) {
  const { label, value } = props

  return (
    <Box alignItems='center' display='flex' style={{ gap: 8 }}>
      <Typography component='div' variant='body2' style={{ fontWeight: 700 }}>
        {label}
      </Typography>
      <Typography
        color='primary'
        component='div'
        variant='body2'
        style={{ fontWeight: 700 }}
      >
        {value}
      </Typography>
    </Box>
  )
}

function ViewPage({ history,showNotification }) {
  const { id } = useParams()
  const {
    createRecord,
    deleteRecord,
    fetchRecord,
    fetchRecords,
    securedSendRequest
  } = useServices()
  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { translate } = useTranslation(Namespace.INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(InvoiceApiUrls.GET, id, (res) => res.content[0]),
      // Port of loading
      fetchRecords.execute(
        NaCodeMaintenanceApiUrls.GET,
        {
          filters: [
            { field: 'active', operator: EQUAL, value: 'true' },
            { field: 'set_indicator', operator: EQUAL, value: 'DOC' }
          ],
          customData: { codeMType: 'DocPortCode' },
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      )
    ]).then(([data, portOfLoading]) => {
      if (data === undefined) {
        showNotification('error', 'Data not found.')
        history.push(pathMap.INVOICE_LIST_VIEW)
      }

      var clientData = FormProperties.toClientDataFormat(data)
      setData(clientData)
      setLookups({ portOfLoading })
    })
  }, [])

  const getTotalPackageQuantity = useCallback(() => {
    if (!data) return 0

    return data.invoiceContainer.reduce((acc, invoice) => {
      const quantity = parseInt(invoice.quantity)

      if (!quantity) return acc

      return acc + quantity
    }, 0)
  }, [data?.invoiceContainer])

  const getTotalVolume = useCallback(() => {
    if (!data) return 0

    const totalVolume = data.invoiceContainer.reduce((acc, invoice) => {
      const volume = parseFloat(invoice.volume)

      if (!volume) return acc

      return acc + volume
    }, 0)

    return totalVolume.toFixed(2)
  }, [data?.invoiceContainer])

  function makeTranslatedTextsObject() {
    let invoice = translate(
      Namespace.INVOICE,
      InvoiceKeys.TITLE
    )
    let id = translate(
      Namespace.INVOICE,
      InvoiceKeys.ID
    )
    let corpid = translate(
      Namespace.INVOICE,
      InvoiceKeys.CORPID
    )
    let invoiceNumber = translate(
      Namespace.INVOICE,
      InvoiceKeys.INVOICE_NUMBER
    )
    let invoiceDate = translate(
      Namespace.INVOICE,
      InvoiceKeys.INVOICE_DATE
    )
    let custRefNum = translate(
      Namespace.INVOICE,
      InvoiceKeys.CUST_REF_NUM
    )
    let noOfPkgs = translate(
      Namespace.INVOICE,
      InvoiceKeys.NO_OF_PKGS
    )
    let carrierCode = translate(
      Namespace.INVOICE,
      InvoiceKeys.CARRIER_CODE
    )
    let noOfContainers = translate(
      Namespace.INVOICE,
      InvoiceKeys.NO_OF_CONTAINERS
    )
    let cube = translate(
      Namespace.INVOICE,
      InvoiceKeys.CUBE
    )
    let eta = translate(
      Namespace.INVOICE,
      InvoiceKeys.ETA
    )
    let portOfLoading = translate(
      Namespace.INVOICE,
      InvoiceKeys.PORT_OF_LOADING
    )
    let billOfLading = translate(
      Namespace.INVOICE,
      InvoiceKeys.BILL_OF_LADING
    )
    let status = translate(
      Namespace.INVOICE,
      InvoiceKeys.STATUS
    )
    let lockedBy = translate(
      Namespace.INVOICE,
      InvoiceKeys.LOCKED_BY
    )
    let lockedDate = translate(
      Namespace.INVOICE,
      InvoiceKeys.LOCKED_DATE
    )
    let responseDatetime = translate(
      Namespace.INVOICE,
      InvoiceKeys.RESPONSE_DATETIME
    )
    let rcvdPo = translate(
      Namespace.INVOICE,
      InvoiceKeys.RCVD_PO
    )
    let createdBy = translate(
      Namespace.INVOICE,
      InvoiceKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.INVOICE,
      InvoiceKeys.CREATED_DATE
    )
    let submittedDate = translate(
      Namespace.INVOICE,
      InvoiceKeys.SUBMITTED_DATE
    )
    let invoiceContainer = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.TITLE
    )
    let invId = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.INV_ID
    )
    let poNumber = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.PO_NUMBER
    )
    let wallMartItem = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.WALL_MART_ITEM
    )
    let containerNo = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.CONTAINER_NO
    )
    let sizeAndType = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.SIZE_AND_TYPE
    )
    let quantity = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.QUANTITY
    )
    let uom = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.UOM
    )
    let volume = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.VOLUME
    )
    let unitPrice = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.UNIT_PRICE
    )
    let entValue = translate(
      Namespace.INVOICE,
      InvoiceKeys.InvoiceContainer.ENT_VALUE
    )
    let transaction = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.TITLE
    )
    let userid = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.USERID
    )
    let transTime = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.TRANS_TIME
    )
    let transType = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.TRANS_TYPE
    )
    let additionalinfo = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.ADDITIONALINFO
    )
    let additionalinfo1 = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.ADDITIONALINFO1
    )
    let subEdiPath = translate(
      Namespace.INVOICE,
      InvoiceKeys.Transaction.SUB_EDI_PATH
    )
    let activityLogs = translate(
      Namespace.INVOICE,
      InvoiceKeys.ACTIVITY_LOGS
    )
    let submitRecordMsg = translate(
      Namespace.INVOICE,
      InvoiceKeys.SUBMIT_SUCCESS_MSG
    )
    let submitSuccessMsg = translate(
      Namespace.INVOICE,
      InvoiceKeys.SUBMIT_SUCCESS_MSG
    )

    return {
      invoice,
      id,
      corpid,
      invoiceNumber,
      invoiceDate,
      custRefNum,
      noOfPkgs,
      carrierCode,
      noOfContainers,
      cube,
      eta,
      portOfLoading,
      billOfLading,
      status,
      lockedBy,
      lockedDate,
      responseDatetime,
      rcvdPo,
      createdBy,
      createdDate,
      submittedDate,
      invoiceContainer,
      invId,
      poNumber,
      wallMartItem,
      containerNo,
      sizeAndType,
      quantity,
      uom,
      volume,
      unitPrice,
      entValue,
      transaction,
      userid,
      transTime,
      transType,
      additionalinfo,
      additionalinfo1,
      subEdiPath,
      activityLogs,
      submitRecordMsg,
      submitSuccessMsg
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  if (!data) {
    return <CircularProgress />
  }

  const columns = [
    {
      title: translatedTextsObject.poNumber,
      sortKey: 'poNumber',
      field: 'poNumber',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.wallMartItem,
      sortKey: 'wallMartItem',
      field: 'wallMartItem',
      filterConfig: { type: 'textfield' }
    },
    {
      title: [
        translatedTextsObject.containerNo,
        translatedTextsObject.sizeAndType
      ].join(' / '),
      sortKey: 'containerNo',
      field: 'containerNo',
      filterConfig: { type: 'textfield' },
      render: (rowData) => (
        <Box>
          <Typography component='div' variant='inherit'>
            {rowData.containerNo || '-'}
          </Typography>
          <Typography color='textSecondary' variant='caption'>
            {rowData.sizeAndType}
          </Typography>
        </Box>
      )
    },
    {
      title: [translatedTextsObject.quantity, translatedTextsObject.uom].join(' & '),
      field: 'quantity',
      filterConfig: { type: 'textfield' },
      render: (rowData) => (
        <Typography component='span' variant='inherit'>
          {rowData.quantity || '-'}
          <TinyChip label={rowData.uom} variant='outlined' />
        </Typography>
      )
    },
    {
      title: translatedTextsObject.volume,
      sortKey: 'volume',
      field: 'volume',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.unitPrice,
      sortKey: 'unitPrice',
      field: 'unitPrice',
      filterConfig: { type: 'textfield' },
      render: (rowData) => {
        if (!rowData.unitPrice) return

        const unitPrice = parseFloat(rowData.unitPrice)
        return unitPrice.toFixed(2)
      }
    },
    {
      title: translatedTextsObject.entValue,
      sortKey: 'entValue',
      field: 'entValue',
      filterConfig: { type: 'textfield' }
    }
  ]

  function handleCloneInvoice() {}

  function handleDeleteInvoice() {
    if (!data) return

    deleteRecord.execute(
      InvoiceApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.replace(pathMap.INVOICE_LIST_VIEW)
      },
      (error) => {
        console.error(error)
        showNotification('success', 'Something went wrong when deleting invoice.')
      }
    )
  }

  function handleExportInvoice() {}

  function handleSubmitInvoice() {
    if (!data) return

    let fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      data['fileForUserId'] = fileForUserDetails.fileForUserId
      data['fileForUserPartyId'] = fileForUserDetails.fileForUserPartyId
      data['fileForUserLoginId'] = fileForUserDetails.fileForUserLoginId
    }

    setIsSubmitting(true)

    securedSendRequest.execute(
      'POST',
      InvoiceApiUrls.SUBMIT,
      data,
      (response) => {
        var data = response.data
        var errorMessages = data.errorMessages
        if (
          response.status == 200 &&
          data != null &&
          errorMessages == null
        ) {
          showNotification(
            'success',
            translatedTextsObject.submitSuccessMsg
          )
          history.push(pathMap.INVOICE_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      },
      () => showNotification('error', 'Fail to submit'),
      () => setIsSubmitting(false)
    )
  }
  
  return (
    <Paper variant='outlined'>
      <Box padding={1}>
        <StatusBar status={data.status} />
      </Box>
      <Box padding={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 600 }}>
                  {translatedTextsObject.invoice}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.custRefNum}>
                      {data.custRefNum}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.invoiceNumber}>
                      {data.invoiceNumber}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.invoiceDate}>
                      {data.invoiceDate && moment(data.invoiceDate).format('D MMM YYYY')}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.billOfLading}>
                      {data.billOfLading}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.portOfLoading}>
                      <Typography component='span' variant='inherit'>
                        {getLookupValue('portOfLoading', data.portOfLoading)}
                        <TinyChip label={data.portOfLoading} variant='outlined' />
                      </Typography>
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.carrierCode}>
                      {data.carrierCode}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.eta}>
                      {data.eta && moment(data.eta).format('D MMM YYYY')}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <CngField label={translatedTextsObject.rcvdPo}>
                      {data.rcvdPo}
                    </CngField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper variant='outlined'>
              <LocalTable
                columns={columns}
                data={data.invoiceContainer}
                header={
                  <Grid alignItems='stretch' container spacing={2}>
                    <Grid item xs='auto'>
                      <OverviewDetail
                        label='Total Line Item'
                        value={data.invoiceContainer.length}
                      />
                    </Grid>
                    <Grid item xs='auto'>
                      <Divider orientation='vertical' />
                    </Grid>
                    <Grid item xs='auto'>
                      <OverviewDetail
                        label='Total Package Quantity'
                        value={getTotalPackageQuantity()}
                      />
                    </Grid>
                    <Grid item xs='auto'>
                      <Divider orientation='vertical' />
                    </Grid>
                    <Grid item xs='auto'>
                      <OverviewDetail label='Total Volume' value={getTotalVolume()} />
                    </Grid>
                  </Grid>
                }
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <CngSection title={translatedTextsObject.activityLogs}>
              <ActivityLogs
                id={data.id}
                showNotification={showNotification}
              />
            </CngSection>
          </Grid>
        </Grid>
      </Box>
      <Box padding={2}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs='auto'>
            <CngButton
              disabled={isSubmitting}
              color='secondary'
              onClick={() => history.push(pathMap.INVOICE_LIST_VIEW)}
              size='medium'
            >
              Back to invoice report list
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  disabled={isSubmitting}
                  onClick={handleDeleteInvoice}
                  size='medium'
                  startIcon={<FontAwesomeIcon icon={['fal', 'trash']} />}
                  style={{ minWidth: 'auto' }}
                  variant='outlined'
                >
                  Delete
                </CngButton>
              </Grid>
             {/* <Grid item xs='auto'>
                <CngButton
                  disabled={isSubmitting}
                  onClick={handleExportInvoice}
                  size='medium'
                  startIcon={<FontAwesomeIcon icon={['fal', 'arrow-down-to-line']} />}
                  style={{ minWidth: 'auto' }}
                  variant='outlined'
                >
                  Export
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  disabled={isSubmitting}
                  onClick={handleCloneInvoice}
                  size='medium'
                  startIcon={<FontAwesomeIcon icon={['fal', 'copy']} />}
                  style={{ minWidth: 'auto' }}
                  variant='outlined'
                >
                  Clone
                </CngButton>
              </Grid>*/}
              <Grid item xs='auto'>
                <CngButton
                  disabled={isSubmitting}
                  onClick={() =>
                    history.push(`${pathMap.INVOICE}/edit/${id}`)
                  }
                  size='medium'
                  startIcon={<FontAwesomeIcon icon={['fal', 'pen-square']} />}
                >
                  Edit
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton
                  disabled={isSubmitting}
                  onClick={handleSubmitInvoice}
                  size='medium'
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size={16} />
                    ) : (
                      <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />
                    )
                  }
                >
                  Submit
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default ViewPage
