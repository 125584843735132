import React, { useState } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import RoutingInfoTable from './tables/RoutingInfoTable'
import TradePartyTable from './tables/TradePartyTable'
import LineItemTable from './tables/LineItemTable'
import ScheduleTable from './tables/ScheduleTable'
import SublineTable from './tables/SublineTable'
import SublineAllowanceTable from './tables/SublineAllowanceTable'
import { Box, makeStyles, Paper, Tab, Tabs } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTabs-flexContainer': {
      gap: 16,
      '& .MuiTab-root': {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        minWidth: 120,
        padding: '8px 16px',
        '&.Mui-selected': {
          '& .MuiTab-wrapper': {
            color: theme.palette.text.primary
          }
        },
        '& .MuiTab-wrapper': {
          color: theme.palette.text.secondary,
          fontWeight: 600,
          lineHeight: 1.2,
          textTransform: 'none',
          transition: theme.transitions.create(['color'])
        }
      }
    }
  }
}))

function PurchaseOrderDetailsTables(props) {
  const { purchaseOrder, ...otherProps } = props
  const {
    routingInfo,
    tradeParty,
    lineItem,
    schedule,
    subline,
    sublineAllowance
  } = purchaseOrder

  const [tab, setTab] = useState(0)
  const classes = useStyles()
  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let routingInfo = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.RoutingInfo.TITLE
    )
    let tradeParty = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.TradeParty.TITLE
    )
    let lineItem = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.TITLE
    )
    let schedule = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.TITLE
    )
    let subline = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Subline.TITLE
    )
    let sublineAllowance = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.SublineAllowance.TITLE
    )

    return {
      routingInfo,
      tradeParty,
      lineItem,
      schedule,
      subline,
      sublineAllowance
    }
  }

  return (
    <Paper>
      <Box py={2}>
        <Tabs
          className={classes.root}
          onChange={(_, value) => setTab(value)}
          variant='scrollable'
          scrollButtons='auto'
          TabIndicatorProps={{ style: { display: 'none' } }}
          value={tab}
        >
          <Tab disableRipple label={translatedTextsObject.routingInfo} />
          <Tab disableRipple label={translatedTextsObject.tradeParty} />
          <Tab disableRipple label={translatedTextsObject.lineItem} />
          <Tab disableRipple label={translatedTextsObject.schedule} />
          <Tab disableRipple label={translatedTextsObject.subline} />
          <Tab disableRipple label={translatedTextsObject.sublineAllowance} />
        </Tabs>
      </Box>
      <TabPanel index={0} value={tab}>
        <RoutingInfoTable data={routingInfo} {...otherProps} />
      </TabPanel>
      <TabPanel index={1} value={tab}>
        <TradePartyTable data={tradeParty} {...otherProps} />
      </TabPanel>
      <TabPanel index={2} value={tab}>
        <LineItemTable data={lineItem} {...otherProps} />
      </TabPanel>
      <TabPanel index={3} value={tab}>
        <ScheduleTable data={schedule} {...otherProps} />
      </TabPanel>
      <TabPanel index={4} value={tab}>
        <SublineTable data={subline} {...otherProps} />
      </TabPanel>
      <TabPanel index={5} value={tab}>
        <SublineAllowanceTable data={sublineAllowance} {...otherProps} />
      </TabPanel>
    </Paper>
  )
}

export default PurchaseOrderDetailsTables

function TabPanel(props) {
  const { children, value, index, ...otherProps } = props

  return (
    <div role='tabpanel' hidden={value !== index} {...otherProps}>
      {value === index && children}
    </div>
  )
}
