import { Chip, CircularProgress, useTheme } from '@material-ui/core'
import {
  constants,
  useServices,
  useTranslation
} from 'cng-web-lib'

import InvoiceApiUrls from 'src/apiUrls/InvoiceApiUrls'
import React, { useEffect, useRef, useState } from 'react'
import InvoiceKeys from 'src/constants/locale/key/Invoice'
import Namespace from 'src/constants/locale/Namespace'
import {FileForUserGetPartyId,FileForUserGetUserDetails} from 'src/common/FileForUserCommon'
import Table from '../../../components/aciacehighway/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import pathMap from 'src/paths/pathMap'
import { getStatusMetadata } from 'src/common/NACommon'

const {
  filter:{ BETWEEN, EQUAL, LIKE }
} = constants

function TablePage(props) {
  const {
    history,
    location: { pathname },
    showNotification
  } = props

  const { translate } = useTranslation(Namespace.INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { deleteRecord, securedSendRequest } = useServices()
  const tableRef = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    let invoice = translate(
      Namespace.INVOICE,
      InvoiceKeys.TITLE
    )
    let id = translate(
      Namespace.INVOICE,
      InvoiceKeys.ID
    )
    let custRefNum = translate(
      Namespace.INVOICE,
      InvoiceKeys.CUST_REF_NUM
    )
    let invoiceNumber = translate(
      Namespace.INVOICE,
      InvoiceKeys.INVOICE_NUMBER
    )
    let billOfLading = translate(
      Namespace.INVOICE,
      InvoiceKeys.BILL_OF_LADING
    )
    let rcvdPo = translate(
      Namespace.INVOICE,
      InvoiceKeys.RCVD_PO
    )
    let eta = translate(
      Namespace.INVOICE,
      InvoiceKeys.ETA
    )
    let status = translate(
      Namespace.INVOICE,
      InvoiceKeys.STATUS
    )
    let createdBy = translate(
      Namespace.INVOICE,
      InvoiceKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.INVOICE,
      InvoiceKeys.CREATED_DATE
    )
    let submittedDate = translate(
      Namespace.INVOICE,
      InvoiceKeys.SUBMITTED_DATE
    )
    let submitSentRecord = translate(
      Namespace.INVOICE,
      InvoiceKeys.SUBMIT_SENT_RECORD
    )
    let submitButton = translate(
      Namespace.INVOICE,
      InvoiceKeys.SUBMIT_BUTTON
    )
    let editButton = translate(
      Namespace.INVOICE,
      InvoiceKeys.EDIT_BUTTON
    )
    let editSentRecord = translate(
      Namespace.INVOICE,
      InvoiceKeys.EDIT_SENT_RECORD
    )
    let editMultipleRecords = translate(
      Namespace.INVOICE,
      InvoiceKeys.EDIT_MULTIPLE_RECORD
    )

    return {
      invoice,
      id,
      invoiceNumber,
      custRefNum,
      eta,
      billOfLading,
      status,
      rcvdPo,
      createdBy,
      createdDate,
      submittedDate,
      submitSentRecord,
      editSentRecord,
      submitButton,
      editButton,
      editMultipleRecords
    }
  }

  const columns = [
    {
      field: "custRefNum",
      title: translatedTextsObject.custRefNum,
    },
    {
      field: "invoiceNumber",
      title: translatedTextsObject.invoiceNumber,
    },
    {
      field: "billOfLading",
      title: translatedTextsObject.billOfLading,
    },
    {
      field: 'eta',
      title: translatedTextsObject.eta,
      render: (rowData) =>
        rowData.eta && moment(rowData.eta).format('D MMM YYYY')
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate &&
        moment(rowData.createdDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      title: translatedTextsObject.submittedDate,
      render: (rowData) =>
        rowData.submittedDate &&
        moment(rowData.submittedDate).format('D MMM YYYY, HH:mm')
    },
    {
      field: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor
            }}
          />
        )
      }
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.custRefNum,
      type: 'textfield',
      name: 'custRefNum',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.invoiceNumber,
      type: 'textfield',
      name: 'invoiceNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.billOfLading,
      type: 'textfield',
      name: 'billOfLading',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.eta,
      type: 'daterange',
      name: 'eta',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.status,
      type: 'textfield',
      name: 'status',
      operatorType: LIKE
    }
  ]

  function handleSubmitInvoice(data) {
    let invoices = [...data]
    let fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      invoices.forEach((invoice) => {
        invoice['fileForUserId'] = fileForUserDetails.fileForUserId
        invoice['fileForUserPartyId'] = fileForUserDetails.fileForUserPartyId
        invoice['fileForUserLoginId'] = fileForUserDetails.fileForUserLoginId
      })
    }

    setIsSubmitting(true)

    securedSendRequest.execute(
      'POST',
      InvoiceApiUrls.SUBMIT_ALL,
      invoices,
      (response) => {
        if (response.status == 200) {
          const data = response.data
          var errorMessage = null
          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessage = obj.errorMessages
              showNotification('error', errorMessage)
            }
          }

          if (errorMessage == null || errorMessage.length == 0) {
            showNotification('success', 'Submit record has succeeded.')

            if (tableRef.current.performRefresh) {
              tableRef.current.performRefresh()
            }
          }
        } else {
          showNotification('error', 'Fail to submit')
        }
      },
      () => showNotification('error', 'Fail to submit'),
      () => setIsSubmitting(false)
    )
  }

  function handleDeleteInvoice(data) {
    deleteRecord.execute(
      InvoiceApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')

        if (tableRef.current.performRefresh) {
          tableRef.current.performRefresh()
        }
      },
      (error) => {
        console.error(error)
        showNotification('success', 'Something went wrong when deleting invoice.')
      }
    )
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.INVOICE_ADD_VIEW)
            },
            label: 'Create invoice'
          }
        ]}
        checkboxSelection
        columns={columns}
        compact
        customRowActions={[
          {
            iconButtonProps: {
              icon: ['fal', 'arrow-alt-right'],
              style: { color: theme.palette.primary.main }
            },
            onClick: (rowData) => handleSubmitInvoice([rowData]),
            tooltip: () => translatedTextsObject.submitButton
          }
        ]}
        exportData={{ url: InvoiceApiUrls.EXPORT }}
        fetch={{ url: InvoiceApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => history.push(`${pathname}/view/${rowData.id}`)}
        rowActions={[
          {
            //disabled: (rowData) => rowData.status === 'ST',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) =>
              history.push(`${pathname}/edit/${rowData.id}`),
            //tooltip: (rowData) =>
              //rowData.status === 'ST'
                //? translatedTextsObject.editSentRecord
                //: null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: handleDeleteInvoice
          }
        ]}
        selectActions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              variant: 'outlined',
              style: {
                color: theme.palette.primary.main,
                minWidth: 'auto'
              }
            },
            disabled: () => isSubmitting,
            icon: isSubmitting ? (
              <CircularProgress size={16} />
            ) : (
              <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />
            ),
            label: translatedTextsObject.submitButton,
            onClick: (rows) => handleSubmitInvoice(rows)
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
    </>
  )
}

export default TablePage
