import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    poId: Yup.number(),
    corpid: Yup.string().nullable(),
    transportMethod: Yup.string().nullable(),
    locQualifier: Yup.string().nullable(),
    locIdentifier: Yup.string().max(30, "Max length allowed is 30.").nullable(),
    serviceLevel: Yup.string().nullable(),
    countryCode: Yup.string().max(3, "Max length allowed is 3.").nullable(),
  })
}

export default makeValidationSchema
