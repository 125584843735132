import makeValidationSchema from './ScheduleMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      FormikDropdownField: CngDropdownField,
      CngTextField,
      CngDateField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _lineItemId: 0,
  scheduleItemId: 0,
  scheduleCorpid: "",
  scheduleQuantity: "",
  scheduleQuantityUom: "",
  distributionPoNumber: "",
  distributionCenter: "",
  scheduleDate: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let schedule = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.TITLE
    )
    let _lineItemId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.LINE_ITEM
    )
    let scheduleItemId = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_ITEM_ID
    )
    let scheduleCorpid = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_CORPID
    )
    let scheduleQuantity = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_QUANTITY
    )
    let scheduleQuantityUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_QUANTITY_UOM
    )
    let distributionPoNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.DISTRIBUTION_PO_NUMBER
    )
    let distributionCenter = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.DISTRIBUTION_CENTER
    )
    let scheduleDate = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.Schedule.SCHEDULE_DATE
    )

    return {
      schedule,
      _lineItemId,
      scheduleItemId,
      scheduleCorpid,
      scheduleQuantity,
      scheduleQuantityUom,
      distributionPoNumber,
      distributionCenter,
      scheduleDate
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.schedule} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap._lineItemId}>
            <CngSelectField
              name="_lineItemId"
              label={translatedTextsObject._lineItemId}
              items={parentIdDropdownItems}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="scheduleItemId"
              type="number"
              label={translatedTextsObject.scheduleItemId}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={true}>
            <CngTextField
              name="scheduleCorpid"
              label={translatedTextsObject.scheduleCorpid}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleQuantity}>
            <CngTextField
              name="scheduleQuantity"
              label={translatedTextsObject.scheduleQuantity}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleQuantityUom}>
            <CngTextField
              name="scheduleQuantityUom"
              label={translatedTextsObject.scheduleQuantityUom}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.distributionPoNumber}>
            <CngTextField
              name="distributionPoNumber"
              label={translatedTextsObject.distributionPoNumber}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.distributionCenter}>
            <CngTextField
              name="distributionCenter"
              label={translatedTextsObject.distributionCenter}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleDate}>
            <CngDateField
              name="scheduleDate"
              label={translatedTextsObject.scheduleDate}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

const ScheduleFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ScheduleFormProperties
