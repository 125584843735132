import React from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import DocGenPurchaseOrderKeys from 'src/constants/locale/key/DocGenPurchaseOrder'
import LocalTable from '../../../../components/aciacehighway/LocalTable'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import Typography from '@material-ui/core/Typography'

function LineItemTable(props) {
  const { data } = props

  const { translate } = useTranslation(Namespace.DOC_GEN_PURCHASE_ORDER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let poLineNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.PO_LINE_NUMBER
    )
    let quoteSheetNumber = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.QUOTE_SHEET_NUMBER
    )
    let itemDescription = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.ITEM_DESCRIPTION
    )
    let quantity = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.QUANTITY
    )
    let uom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.UOM
    )
    let unitPrice = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.UNIT_PRICE
    )
    let grossWeight = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.GROSS_WEIGHT
    )
    let grossWeightUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.GROSS_WEIGHT_UOM
    )
    let volume = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.VOLUME
    )
    let volumeUom = translate(
      Namespace.DOC_GEN_PURCHASE_ORDER,
      DocGenPurchaseOrderKeys.LineItem.VOLUME_UOM
    )

    return {
      poLineNumber,
      quoteSheetNumber,
      itemDescription,
      quantity,
      uom,
      unitPrice,
      grossWeight,
      grossWeightUom,
      volume,
      volumeUom
    }
  }

  const columns = [
    {
      title: translatedTextsObject.poLineNumber,
      field: 'poLineNumber',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.quoteSheetNumber,
      field: 'quoteSheetNumber',
      filterConfig: { type: 'textfield' }
    },
    {
      title: translatedTextsObject.itemDescription,
      field: 'itemDescription',
      filterConfig: { type: 'textfield' }
    },
    {
      title: [
        translatedTextsObject.quantity,
        translatedTextsObject.uom
      ].join(' & '),
      field: 'quantity',
      filterConfig: { type: 'textfield' },
      render: (rowData) => (
        <Typography component='span' variant='inherit'>
          {rowData.quantity}
          <TinyChip label={rowData.uom} variant='outlined' />
        </Typography>
      )
    },
    {
      title: translatedTextsObject.unitPrice,
      field: 'unitPrice',
      filterConfig: { type: 'textfield' },
      render: (rowData) => parseFloat(rowData.unitPrice).toFixed(2)
    },
    {
      title: [
        translatedTextsObject.grossWeight,
        translatedTextsObject.grossWeightUom
      ].join(' & '),
      field: 'grossWeight',
      filterConfig: { type: 'textfield' },
      render: (rowData) => (
        <Typography component='span' variant='inherit'>
          {rowData.grossWeight}
          <TinyChip label={rowData.grossWeightUom} variant='outlined' />
        </Typography>
      )
    },
    {
      title: [
        translatedTextsObject.volume,
        translatedTextsObject.volumeUom
      ].join(' & '),
      field: 'volume',
      filterConfig: { type: 'textfield' },
      render: (rowData) => (
        <Typography component='span' variant='inherit'>
          {rowData.volume}
          <TinyChip label={rowData.volumeUom} variant='outlined' />
        </Typography>
      )
    }
  ]

  return <LocalTable columns={columns} data={data} />
}

export default LineItemTable
